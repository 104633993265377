<main-nav>
  <div
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50"
    [fromRoot]="true"
    (scrolled)="onScroll()"
    *ngIf="!refreshing"
  >
    <!-- <div class="btnSpot" *ngIf="isAmorSaude">
      <button 
        mat-button 
        class="btn-secondary"
        (click)="getSchedules(true)"
      >
        Consultar status de pagamentos
      </button>
    </div> -->
    <app-list
      [content]="content"
      (onDelete)="onDeleteHandler($event)"
      (onRefresh)="getSchedules(true)"
      (timesOver)="onTimesOver($event)"
      [title]="title"
      [isDeleting]="isDeleting"
    ></app-list>
    <div
      *ngIf="isNotFound && !isGettingApiResult && !content.length"
      class="empty-response-message"
    >
      Nenhum resultado encontrado.
    </div>
  </div>
  <app-loading
    *ngIf="isGettingApiResult"
    class="p-margin-top-1 p-display-block"
  ></app-loading>
</main-nav>
