<span > 
    <h2 mat-dialog-title>Completar cadastro</h2>
    <mat-divider></mat-divider>
    <mat-dialog-content>
        <p>Por favor, para continuar com o pagamento é necessário que os dados do paciente abaixo estejam completos.</p>
        <form [formGroup]="form">
          <div class="col-12">
              <h2>Documentos</h2>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>CPF</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="cpf"
                      placeholder="CPF"
                      [specialCharacters]="['/', '.', '-']"
                      [mask]="'000.000.000-00'"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input
                      matInput
                      type="email"
                      formControlName="email"
                      placeholder="Email"
                    />
                  </mat-form-field>
                </div>
              </div>

              <h2>Endereço</h2>
              <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>CEP</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="zipcode"
                      placeholder="CEP"
                      (input)="onChangeCEP()"
                      [specialCharacters]="['-']"
                      mask="00000-000"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Endereço</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="address"
                      placeholder="Endereço"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Número</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="number"
                      placeholder="Número"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Complemento</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="complement"
                      placeholder="Complemento"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Bairro</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="neighborhood"
                      placeholder="Bairro"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Cidade</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="city"
                      placeholder="Cidade"
                    />
                  </mat-form-field>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                  <mat-form-field appearance="outline">
                    <mat-label>Estado</mat-label>
                    <mat-select formControlName="state">
                      <mat-option *ngFor="let t of estados" [value]="t">
                        {{ t }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
        </form>
    </mat-dialog-content>

    
    <mat-dialog-actions class="actions">
        <button 
            mat-button 
            class="btn-secondary cancel"
            (click)="cancel()"
            [disabled]="loading"
        > 
            Cancelar
        </button>
        <button 
            mat-button 
            class="btn-secondary"
            (click)="save()"
            [disabled]="loading"
        > 
            Salvar
        </button>
    </mat-dialog-actions>
</span>
