import {
  ILoginRequest,
  ILoginResponse,
} from './../pages/login/login.interface';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { Router } from '@angular/router';
import { LocalStorageUtil } from '../util/local-storage-util';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private apiService: ApiServiceService, private router: Router) { }

  isSentryLoaded = false;

  public login(
    params: ILoginRequest,
    accountId: number,
    isSchedulersView: boolean,
    isModal: boolean = false
  ): Observable<ILoginResponse> {
    return this.apiService.post(
      '/auth/login' +
        '?id=' +
        params.id +
        '&password=' +
        encodeURIComponent(params.password) +
        '&accountId=' +
        accountId +
        '&isSchedulersView=' +
        isSchedulersView +
        '&isModal=' +
        isModal,
    );
  }

  public logout(isSchedulersView: boolean = false) {
    this.apiService
      .post('/auth/logout')
      .toPromise()
      .then((resp) => {
        // Reseta as variáveis de login, inicialmente só o token de acesso
        LocalStorageUtil.logout();

        this.router.navigate([(isSchedulersView? '/agendadores' : '') + '/login'], { queryParamsHandling: 'preserve' });
      });
  }

  public isAmorSaude(){
    const amorSaudeURL: string[] = ['amorsaude', 'testeamorsaude'];
    const hasURL: boolean = amorSaudeURL.some(url => location.href.indexOf(url) > -1);
    
    return hasURL;
  }

  public noRegister(){
    const noRegisterPatientDomains: string[] = ['prosperasaude', 'previdas'];
    const hasURL: boolean = noRegisterPatientDomains.some(url => location.href.indexOf(url) > -1);
    
    return hasURL;
  }

  public isPrevidas(): boolean{
    const previdsaURLs: string[] = ['previdas'];
    const hasURL: boolean = previdsaURLs.some(url => location.href.indexOf(url) > -1);
    
    return hasURL;
  }

  public isOmint(): boolean{
    const url: string[] = ['disneyhealthylife'];
    const hasURL: boolean = url.some(url => location.href.indexOf(url) > -1);
    
    return hasURL;
  }

  public isSchedulersView(): boolean{
    const url = this.router.url;
    return !! url.match('agendadores')
  }

  public isStaging(){
    const stagingURL: string[] = ['staging', 'testeamorsaude'];
    const hasURL: boolean = stagingURL.some(url => location.href.indexOf(url) > -1);
    
    return hasURL;
  }

  public connectSentry() {
    if(environment.sentry == null) {
      return;
    }
    
    const patientInfo = LocalStorageUtil.getPatientInfo();
    const schedulerInfo = LocalStorageUtil.getSchedulerInfo();

    let userInfo: {
      email: string,
      name: string,
      id: string,
    } = null
    
    if(patientInfo) {
      userInfo = {
        email: patientInfo.email,
        name: patientInfo.name,
        id: 'pat-'+patientInfo.id
      }
    }

    if(schedulerInfo) {
      userInfo = {
        email: schedulerInfo.email,
        name: schedulerInfo.name,
        id: 'age-'+schedulerInfo.id
      }
    }

    if (environment.sentry && !this.isSentryLoaded && userInfo) {
      Sentry.init({
        dsn: environment.sentry,
        initialScope: {
          user: userInfo
        },
        integrations: [
          // Registers and configures the Tracing integration,
          // which automatically instruments your application to monitor its
          // performance, including custom Angular routing instrumentation
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.routingInstrumentation,
            tracingOrigins: [
              environment.api_message
            ]
          }),

          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false
          })
        ],
        environment: environment.production ? 'production' : 'staging',

        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
      });

      this.isSentryLoaded = true;
    }
  }

  public checkUser(cpf: number){
    return this.apiService.post(
      '/auth/check', { id: cpf, accountId: LocalStorageUtil.getAccountInfo().accountId }
    );
  }

  public loginSSO(
    params: {
      sso: string,
      isSchedulersView: string,
      accountId: number
    },
  ): Observable<ILoginResponse> {
    return this.apiService.post(
      '/auth/login-sso' +
        '?sso=' +
        params.sso +
        '&accountId=' +
        params.accountId +
        '&isSchedulersView=' +
        params.isSchedulersView
    );
  }
}
