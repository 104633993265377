import { MatSnackBar } from '@angular/material/snack-bar';
import { CadastroService } from 'src/app/services/cadastro.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Views } from '../views';
import { LoginService } from 'src/app/services/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-esqueci-minha-senha',
  templateUrl: './esqueci-minha-senha.component.html',
  styleUrls: ['./esqueci-minha-senha.component.scss'],
})
export class EsqueciMinhaSenhaComponent extends Views {
  @Input() data: any = null;
  @Output() onCloseComponent: EventEmitter<any> = new EventEmitter();

  cpf: string;
  className: string = '';
  isAmorSaude = false
  public isSchedulersView = false;
  constructor(
    private cadastroService: CadastroService,
    private loginService: LoginService,
    private snack: MatSnackBar,
    private router: Router,
  ) {
    super();
    this.isAmorSaude = this.loginService.isAmorSaude();
    this.isSchedulersView = this.loginService.isSchedulersView();
    this.className = this.isAmorSaude? 'amorsaude' : ''
  }

  sendEmail() {
    this.cadastroService
      .forgotPassword({
        accountId: this.account.accountId,
        cpf: this.cpf,
        isSchedulersView: this.isSchedulersView
      })
      .subscribe(
        (resp: {email: string}) => {
          let msg = `E-mail de mudança de senha enviado para ${resp.email}.`;

          if(this.loginService.isAmorSaude()) msg += ` \n Caso não tenha acesso a este e-mail cadastrado, entrar em contato com telemedicina@amorsaude.com`;

          if(this.data)
            this.onCloseComponent.emit(true);

          this.snack.open(msg, 'Ok', 
            {
              duration: 20000,
              panelClass: this.className
            }
          );
        },
        (err: HttpErrorResponse) => {
          if(err.error.statusCode == 404)
            this.snack.open(err.error.message, 'Ok', {
              duration: 3000,
              panelClass: this.className
            });
          else
            this.snack.open('Erro ao enviar e-mail de mudança de senha!', 'Ok', {
              duration: 3000,
              panelClass: this.className
            });
        },
      );
  }

  goBack(){
    if(this.data)
      this.onCloseComponent.emit(true);
    else{
      const uri = this.isSchedulersView? '/agendadores/login' : '/login';
      this.router.navigate([uri], { queryParamsHandling: 'preserve' });
    }
  }
}
