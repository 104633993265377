import { AgendamentosService } from 'src/app/services/agendamentos.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';

@Component({
  selector: 'app-confirmar-consulta',
  templateUrl: './confirmar-consulta.component.html',
  styleUrls: ['./confirmar-consulta.component.scss'],
})
export class ConfirmarConsultaComponent implements OnInit {
  loading = true;
  token: string;
  waitLocalstorage: any;
  accountId: number;
  msg = 'Confirmando consulta';
  success = false;

  constructor(
    private route: ActivatedRoute,
    private agendamentosService: AgendamentosService,
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.params['token'];

    this.waitLocalstorage = setInterval(() => {
      this.accountId = LocalStorageUtil.getAccountInfo().accountId;

      if (this.accountId != null) {
        this.agendamentosService
          .confirmAgenda({ token: this.token, accountId: this.accountId })
          .subscribe(
            (resp) => {
              this.msg = 'Consulta confirmada com sucesso!';
              this.loading = false;
              this.success = true;
            },
            () => {
              this.msg = 'Link inválido ou já utilizado!';
              this.loading = false;
            },
          );

        clearInterval(this.waitLocalstorage);
      }
    }, 1000);
  }
}
