export interface AmorSaudeLinkResponse{
	url: string,
	amorSaudeId: number
}

export interface GenerateLinkBody{
    patientId: number,
    scheduleId?: number,
	amount?: number,
	voucherId?: number,
	campaignId?: number
}

export interface SchedulePaymentLinkValidation{
    isValid: boolean,
	search: any,
	payed: boolean
}

export enum FinancialSituationStatus{
	COMPLIANT = 'Adimplente',
	DEFAULER = 'Inadimplente'
}

export enum AffiliateStatus{
	ACTIVE = 'Ativo',
	INACTIVE= 'Inativo'
}

export const HealthInsurance: string = 'Cartão de Todos';

export type GeneratePaymentParams = {
	patientId?:number;
	scheduleId?: number, 
	voucherId?: number,
	campaignId?: number, 
	amount?: string
}