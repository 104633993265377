<span class="card-horario" *ngIf="professional">
    <div class="card-horario-item-avatar">
        <img *ngIf="professional.photo" width="90" height="90" [src]="professional.photo" [alt]="professional.name"
        (error)="photoError(professional)" />
        <app-avatar  *ngIf="!professional.photo" [info]="professional.name" [size]="'large'" [ngClass]="isAmorSaude? 'amorsaude-bg-red' : 'bg-purple'"></app-avatar>
    </div>

    <div class="card-horario-item-description">
        <div class="card-horario-item-description-name">
            {{ professional.title }} {{ professional.name }}
        </div>
        <div>
            {{ professional.occupation }}
            <span *ngIf="professional.expertise">({{ professional.expertise }})</span>
        </div>
        <div [ngClass]="isAmorSaude? 'amorsaudeColor' : 'purple'" *ngIf="professional.document">
            {{ professional.documentType }}{{ professional.documentUf? '/'+professional.documentUf: ''}}
            {{ professional.document }}
            <span *ngIf="professional.rqe"> RQE {{ professional.rqe }}</span>
        </div>
        <div *ngIf="professional.bio && professional.bio.length > 0">
            
            <span *ngIf="completeBioOnly"> {{ professional.bio }} </span>
            <span *ngIf="!completeBioOnly">
                <span *ngIf="partialBio"> {{ professional.bio | partial: charactersToShow }} </span>
                <span *ngIf="!partialBio"> {{ professional.bio }} </span>
                <div *ngIf="professional.bio.length > charactersToShow" (click)="bioView()" class="showMoreOrLess"> 
                    <fa-icon icon="{{iconDescription}}"></fa-icon>
                    <span>{{expandBioTitle}} </span>
                </div>
            </span>
        </div>
    </div>
</span>