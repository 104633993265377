import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';

@Component({
  selector: 'app-usage-rules',
  templateUrl: './usage-rules.component.html',
  styleUrls: ['./usage-rules.component.scss']
})
export class UsageRulesComponent implements OnInit {

  usageRules: string = '';
  loading = true;
  canClose = false;
  account

  constructor(
    private paymentService: PaymentService,
    public dialogRef: MatDialogRef<UsageRulesComponent>,
    private snack: MatSnackBar,
  ) {
    this.account = LocalStorageUtil.getAccountInfo();
   }

  async ngOnInit() {
    await this.loadUsageRules()
  }

  async loadUsageRules(){
    this.paymentService.loadUsageRules(this.account.accountId).subscribe(
      res =>{
        this.usageRules = res.usageRules;
        this.loading = false;
      },
      err =>{
        this.snack.open('Erro ao carregar regras de utilização', 'ok', {
          duration: 3000
        });
        this.dialogRef.close();
      }
    )
  }

}
