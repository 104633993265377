<div class="loginCard" align="center">
  <mat-card style="padding: 10px;">
    <div class="row">
      <div class="col-6">
        <div class="ops">Ops!</div>
      </div>

      <div class="col-6">
        <h2>Agenda não encontrada</h2>
      </div>
    </div>

    <p>Por favor, confira o endereço da agenda a qual está tentando acessar.</p>
  </mat-card>
</div>