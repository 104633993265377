<div class="defaultCard" align="center">
  <mat-card>
    <div class="title purple">
      {{ msg }}
    </div>

    <mat-spinner [diameter]="30" color="primary" *ngIf="loading">
    </mat-spinner>

    <p *ngIf="!loading && success">
      <fa-icon [icon]="['far', 'check-circle']" size="9x" class="green"></fa-icon>
    </p>

  </mat-card>
</div>
