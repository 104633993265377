<div class="wrapper">
  <div class="loginCard" align="center">
    <mat-card style="padding: 30px;">
      <form [formGroup]="fg">
        <div class="row">
          <div class="col-12">
            <!-- TODO: Na primeira vez que entra não encontra o logo no localstorage,
              precisa inscrever em um evento para carregar o logo depois disso -->
              <div class="loader" *ngIf="loading">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
            <img [src]="logo" class="imgLogo"/>
          </div>
  
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label style="font-size: 17px;">CPF</mat-label>
              <input
                matInput
                type="tel"
                formControlName="id"
                [specialCharacters]="['/', '.', '-']"
                [mask]="'000.000.000-00'"
                required
              />
            </mat-form-field>
          </div>
  
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label style="font-size: 17px;">Senha</mat-label>
              <input
                matInput
                type="password"
                formControlName="password"
                required
              />
            </mat-form-field>
          </div>
  
          <div class="col-12" align="left">
            <a *ngIf="!isOnIframe"
              routerLink="{{isSchedulersView? '/agendadores/esqueci-minha-senha' : '/esqueci-minha-senha'}}"
              style="font-weight: bold; text-decoration: none; font-size: 16px;"
              [ngClass]="isAmorSaude? 'amorsaudeColor' : 'purple'"
              ><u>Esqueci minha senha</u></a
            >
          </div>
  
          <div class="col-12 p-margin-top-3">
            <button *ngIf="!isOnIframe" mat-button [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary btn-large' : 'btn-secondary btn-large'" 
              (click)="login()" 
              [disabled]="loging">
              <span *ngIf="!loging; else loadingLogin">
                Entrar
              </span>
  
              <ng-template #loadingLogin>
                <mat-spinner [diameter]="30" color="primary"> </mat-spinner>
              </ng-template>
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  
    <mat-card *ngIf="!isOnIframe && !isSchedulersView && isAllowedToRegister" align="center" style="margin-top: 15px;">
      Ainda não é cadastrado?
      <a 
        routerLink="/cadastro" 
        queryParamsHandling="merge" 
        [ngClass]="isAmorSaude? 'amorsaudeColor' : 'purple'"
        style="white-space: nowrap;"
      >
        <b><u>Clique aqui</u></b>
      </a>
    </mat-card>
  </div>
</div>
