<h2 class="purple" mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <div style="margin-bottom: 15px; font-family: QuickSand;">{{ data.msg }}</div>

  <mat-form-field *ngIf="data.field" appearance="outline">
    <mat-label>{{ data.field.label }}</mat-label>
    <textarea
      #field
      matInput
      type="text"
      [(ngModel)]="data.field.value"
    ></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions style="margin-top: 10px; justify-content: space-between;">
  <button
    mat-button
    mat-dialog-close
    [ngClass]="isAmorSaude? 
    'btn-amorSaudeSecondary btn-light p-padding-horizontal-1 p-padding-vertical-0' 
    : 'btn-secondary btn-light p-padding-horizontal-1 p-padding-vertical-0'"
  >
    <fa-icon icon="times"></fa-icon> {{ data.cancel }}
  </button>
  <button
    mat-button
    mat-dialog-close
    [ngClass]="isAmorSaude? 
    'btn-amorSaudeSecondary p-padding-vertical-0 p-padding-horizontal-1' 
    : 'btn-secondary p-padding-vertical-0 p-padding-horizontal-1'"
    (click)="onConfirm()"
  >
    <fa-icon icon="check"></fa-icon> {{ data.confirm }}
  </button>
</mat-dialog-actions>
