<span *ngIf="isSchedulersView">
  <div *ngIf="!professionalHasOnlineConfiguration" style="text-align: center;">
    <p style="margin: 20px 0 24px;">Esse profissional está sem agenda disponível no momento<span *ngIf="patientInfo">, clique em voltar para mais opções</span></p>
  </div>
  
  <div [formGroup]="form" *ngIf="professionalHasOnlineConfiguration">
    <mat-form-field *ngIf="!!form.controls.crp.value" appearance="outline" class="hide">
      <input matInput formControlName="crp" readonly="true">
    </mat-form-field>

    <app-search-patient
      [searchControl]="form.controls.paciente"
      (selectionChange)="getPatientHealthPlans()"
    >
    </app-search-patient>

    <mat-form-field appearance="outline" *ngIf="isSchedulersView? ( !!form.controls.paciente.value && modalidades.length) : (modalidades.length)">
      <app-loading
        [scale]="0.7"
        class="input-loading"
        *ngIf="isGettingApiResult && step == 1"
      ></app-loading>
      <mat-label>Tipo de agendamento</mat-label>
      <mat-select formControlName="modalidade" (selectionChange)="nextStep(1)">
        <mat-option *ngFor="let modalidade of modalidades" [value]="modalidade">
          {{ modalidade.type ? modalidade.type + ' - ' : '' }}
          {{ modalidade.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <p
      style="margin: 0 0 24px;"
      *ngIf="!isGettingApiResult && step == 1 && modalidades.length == 0"
    >
      Nenhum tipo de agendamento encontrado para este paciente
    </p>
  
    <ng-container *ngIf="step > 1 && occupations.length && !professionalId">
      <mat-form-field appearance="outline">
        <app-loading
          [scale]="0.7"
          class="input-loading"
          *ngIf="isGettingApiResult && step == 2"
        ></app-loading>
        <mat-label>Profissional</mat-label>
        <mat-select formControlName="occupation" (selectionChange)="nextStep(2)">
          <mat-option *ngFor="let occupation of occupations" [value]="occupation">
            {{ occupation.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  
    <ng-container *ngIf="step > 2">
      <mat-form-field appearance="outline">
        <app-loading
          [scale]="0.7"
          class="input-loading"
          *ngIf="isGettingApiResult && step == 3"
        ></app-loading>
        <mat-label>Tipo de atendimento</mat-label>
        <mat-select formControlName="procedure" (selectionChange)="nextStep(3)">
          <mat-option *ngFor="let procedure of procedures" [value]="procedure">
            {{ procedure.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p
        style="margin: 0 0 24px;"
        *ngIf="!isGettingApiResult && !procedures.length"
      >
        Nenhum procedimento encontrado para este tipo de profissional/especialidade
      </p>
    </ng-container>
  
    <ng-container *ngIf="step > 3">
      <mat-form-field appearance="outline">
        <app-loading
          [scale]="0.7"
          class="input-loading"
          *ngIf="isGettingApiResult && step == 4"
        ></app-loading>
        <mat-label>Clínica (local do atendimento)</mat-label>
        <mat-select formControlName="local" (selectionChange)="nextStep(4)">
          <mat-option *ngFor="let local of locals" [value]="local">
            {{ local.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p style="margin: 0 0 24px;" *ngIf="!isGettingApiResult && !locals.length">
        Nenhuma clínica encontrada para os dados informados
      </p>
    </ng-container>
  
    <mat-form-field appearance="outline" *ngIf="step > 3" class="hide">
      <mat-label>A partir de</mat-label>
      <input
        matInput
        required
        readonly
        formControlName="scheduleDate"
        [min]="today"
        placeholder="Data do Agendamento"
        [matDatepicker]="scheduleDate"
        (dateChange)="showSchedule(); scheduleDate.close()"
        (click)="scheduleDate.open()"
        [value]="scheduleDate"
      />
  
      <mat-datepicker #scheduleDate></mat-datepicker>
      <mat-datepicker-toggle matSuffix [for]="scheduleDate">
      </mat-datepicker-toggle>
    </mat-form-field>
    <div *ngIf="isGettingApiResult" class="loadSpinner">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </div>
</span>

<span *ngIf="!isSchedulersView">

  <div *ngIf="!professionalHasOnlineConfiguration" style="text-align: center;">
    <p style="margin: 20px 0 24px;">Esse profissional está sem agenda disponível no momento<span *ngIf="patientInfo">, clique em voltar para mais opções</span></p>
  </div>
  
  <div [formGroup]="form" *ngIf="professionalHasOnlineConfiguration">
    <mat-form-field *ngIf="!!form.controls.crp.value" appearance="outline" class="hide">
      <input matInput formControlName="crp" readonly="true">
    </mat-form-field>
  
    <mat-form-field appearance="outline">
      <app-loading
        [scale]="0.7"
        class="input-loading"
        *ngIf="isGettingApiResult && step == 1"
      ></app-loading>
      <mat-label>Tipo de agendamento</mat-label>
      <mat-select formControlName="modalidade" (selectionChange)="nextStep(1)">
        <mat-option *ngFor="let modalidade of modalidades" [value]="modalidade">
          {{ modalidade.type ? modalidade.type + ' - ' : '' }}
          {{ modalidade.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  
    <ng-container *ngIf="step > 1 && occupations.length && !professionalId">
      <mat-form-field appearance="outline">
        <app-loading
          [scale]="0.7"
          class="input-loading"
          *ngIf="isGettingApiResult && step == 2"
        ></app-loading>
        <mat-label>Profissional</mat-label>
        <mat-select formControlName="occupation" (selectionChange)="nextStep(2)">
          <mat-option *ngFor="let occupation of occupations" [value]="occupation">
            {{ occupation.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  
    <ng-container *ngIf="step > 2">
      <mat-form-field appearance="outline">
        <app-loading
          [scale]="0.7"
          class="input-loading"
          *ngIf="isGettingApiResult && step == 3"
        ></app-loading>
        <mat-label>Tipo de atendimento</mat-label>
        <mat-select formControlName="procedure" (selectionChange)="nextStep(3)">
          <mat-option *ngFor="let procedure of procedures" [value]="procedure">
            {{ procedure.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p
        style="margin: 0 0 24px;"
        *ngIf="!isGettingApiResult && !procedures.length"
      >
        Nenhum procedimento encontrado para este tipo de profissional/especialidade
      </p>
    </ng-container>
  
    <ng-container *ngIf="step > 3">
      <mat-form-field appearance="outline">
        <app-loading
          [scale]="0.7"
          class="input-loading"
          *ngIf="isGettingApiResult && step == 4"
        ></app-loading>
        <mat-label>Clínica (local do atendimento)</mat-label>
        <mat-select formControlName="local" (selectionChange)="nextStep(4)">
          <mat-option *ngFor="let local of locals" [value]="local">
            {{ local.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p style="margin: 0 0 24px;" *ngIf="!isGettingApiResult && !locals.length">
        Nenhuma clínica encontrada para os dados informados
      </p>
    </ng-container>
  
    <mat-form-field appearance="outline" *ngIf="step > 3" class="hide">
      <mat-label>A partir de</mat-label>
      <input
        matInput
        required
        readonly
        formControlName="scheduleDate"
        [min]="today"
        placeholder="Data do Agendamento"
        [matDatepicker]="scheduleDate"
        (dateChange)="showSchedule(); scheduleDate.close()"
        (click)="scheduleDate.open()"
        [value]="scheduleDate"
      />
  
      <mat-datepicker #scheduleDate></mat-datepicker>
      <mat-datepicker-toggle matSuffix [for]="scheduleDate">
      </mat-datepicker-toggle>
    </mat-form-field>
  </div>
</span>

