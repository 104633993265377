import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {  GenerateLinkBody } from '../shared/components/modal-payment/amor-saude.interface';
import { AccountInfo, LocalStorageUtil } from '../util/local-storage-util';
import { ApiServiceService } from './api-service.service';

@Injectable({
  providedIn: 'root'
})
export class AmorSaudeService {
  account: AccountInfo;
  constructor( private apiService: ApiServiceService ) { 
    this.account = LocalStorageUtil.getAccountInfo();
  }

  public getLink(params: GenerateLinkBody): Observable<any>{
    return this.apiService.post(`/amor-saude/get-link`, params);
  }

  public checkIfWasPayed(amorSaudeId: number): Observable<any>{
    return this.apiService.get(`/amor-saude/check-payment/${amorSaudeId}`);
  }

  public async getScheduleProcedureValue(scheduleId: number): Promise<any>{
    return await this.apiService.get(`/amor-saude/schedule/${scheduleId}`).toPromise();
  }


}
