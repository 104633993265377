import { Component, Input, EventEmitter, Output, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgendamentosService } from 'src/app/services/agendamentos.service';
import { LoginService } from 'src/app/services/login.service';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { FRONT_REMOTECARE } from 'src/app/app.api';
import { ConfirmationComponent } from './../dialogs/confirmation/confirmation.component';
import { ModalPaymentComponent } from '../modal-payment/modal-payment.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { PaymentService } from 'src/app/services/payment.service';
import { UsageRulesComponent } from '../modal-payment/usage-rules/usage-rules.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { atcb_action } from "add-to-calendar-button";

@Component({
  selector: 'app-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss'],
})
export class CardItemComponent implements OnInit{
  @Input() item: any;
  @Input() deleting: boolean;
  @Input() itemType: string = '';
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();
  @Output() timesOver: EventEmitter<any> = new EventEmitter();
  accountInfo = LocalStorageUtil.getAccountInfo();

  isAmorSaude = false;
  className = '';
  dialogRef: any;

  public timerToShow:string = '00:00:00';
  public paymentCardDuration:any;
  public redTimer = false;
  public addingToCalendar = false;

  constructor(
    private agendamentosService: AgendamentosService,
    private paymentService: PaymentService,
    private loginService: LoginService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private socket: Socket,
  ) {
    this.isAmorSaude = this.loginService.isAmorSaude();
    this.className = this.isAmorSaude? 'amorsaude' : '';
  }

  @HostListener('window:focus', ['$event'])
  refreshPage($event:any){
    if(this.item.statusPayment === 'pending' || this.item.statusPayment === 'create_payment'){
      clearInterval(this.paymentCardDuration);
      this.getTimer();
    }
  }

  ngOnInit(): void {
    if(this.item.statusPayment === 'pending' || this.item.statusPayment === 'create_payment')
      this.getTimer();
  }

  public getTimer(){

    if(this.item.status === "CANCELADO"){
      this.timesOver.emit(this.item)
      return;
    }

    this.socket.emit('join-payment', {
      scheduleId: this.item.agendaId,
    });

    this.socket.on('payment-update',(res)=>{
      this.updatePayment(res);
    });

    const accountTime = this.accountInfo?.timeToCancelPreSchedule || 10;

    const reservedDate = new Date(this.item.reservedAt).getTime() / 1000;
    const now = new Date().getTime() / 1000;
    const timeRemaining = (accountTime * 60) - (now - reservedDate)

    const [defaultTime, defaultFront] = [ timeRemaining , 'Carregando...'];
    this.timerToShow = defaultFront;
    var times = defaultTime, min, sec, hour;
    this.redTimer = false;
    this.paymentCardDuration = setInterval(() =>{

      hour = Math.floor(times / 3600);
      min = Math.floor((times % 3600) / 60);
      sec = Math.floor(times % 60);

      hour = hour.toString().padStart(2, '0');
      min = min.toString().padStart(2, '0');
      sec = sec.toString().padStart(2, '0');

      this.timerToShow = hour + ":" + min + ":" + sec;
      
      if (--times < 0) {
          this.timesOver.emit(this.item)
          clearInterval(this.paymentCardDuration);
          this.dialogRef.close();
      }

      if(times < 90) this.redTimer = true;

    },1000);
  }

  private updatePayment(res: any){
    if(res.status === 'paid'){
      this.snack.open('Pagamento confirmado', 'ok', {duration: 3000});
      this.onRefresh.emit(true);
    }else{
      this.snack.open('Pagamento não efetuado', 'ok', {duration: 3000});
    }
  }

  public cancelarAgendamento(event: any, id: number, professionalId: number) {
    const TimeToCancelBeforeSchedule = LocalStorageUtil.getAccountInfo().TimeToCancelBeforeSchedule;

    event.preventDefault();

    const localHour = moment();//.utcOffset('America/Sao_Paulo'); // Formatar horario local para o horario de brasilia
    const timeoutToCancelSchedule = moment(this.item.fullDate).subtract(TimeToCancelBeforeSchedule, 'hours');//.utcOffset('America/Sao_Paulo'); // Formatar horario local para o horario de brasilia e subtrair o tempo de cancelamento

    // Em produção o horário local está sendo gerado 3 horas a frente, então foi adicionado 3 horas ao tempo de cancelamento do agendamento;
    if(localHour.isAfter(timeoutToCancelSchedule.add(3,'hours'))){
      this.snack.open('Não é possível cancelar essa consulta, pois o tempo restante para sua consulta '+
      'é menor que o tempo permitido pela clínica para realizar um cancelamento. Para mais informações, entrar em contato com a Clínica',
      'ok');
      return;
    }
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        disableClose: true,
        data: {
          title: 'Desmarcação de agendamento',
          msg: 'Por favor, preencha o motivo do cancelamento',
          cancel: 'Voltar',
          confirm: 'Confirmar',
          field: {
            label: 'Motivo do cancelamento',
            required: true,
            value: '',
          },
          onConfirm: (e) => {
            const reason = dialogRef.componentInstance.data.field.value;
            if (!_.isEmpty(reason)) {
              this.onDelete.emit({ id, reason, professionalId });
              dialogRef.close();
            } else {
              this.snack.open('Favor preencher motivo do cancelamento', '', {
                duration: 3000,
                panelClass: this.className
              });
              this.cancelarAgendamento(event, id, professionalId);
            }
          },
        },
      });
  }

  public getMapView(item) {
    const { clinicAddress, clinicAddressNumber, clinicAddressZipcode } = item;
    if (!clinicAddress || !clinicAddressNumber || !clinicAddressZipcode) {
      return;
    }
    return `https://www.google.com/maps/search/?api=1&query=${clinicAddress}, ${clinicAddressNumber}, ${clinicAddressZipcode}`;
  }

  public getStatusIcon(status: string) {
    const statusMap: any = new Map([
      ['AGENDADO', { name: 'calendar-alt', type: 'fas' }],
      ['REALIZADO', { name: 'user-check', type: 'fas' }],
      ['FALTOU', { name: 'user-times', type: 'fas' }],
      ['CANCELADO', { name: 'calendar-times', type: 'fas' }],
      ['PRÉ-AGENDADO', { name: 'calendar-alt', type: 'fas' }],
    ]);
    if (!statusMap.get(status)) {
      throw new Error(`Tipo de status de agendamento desconhecido: ${status}`);
    }
    return [statusMap.get(status).type, statusMap.get(status).name];
  }

  public photoError(item: any) {
    item.professionalPhoto = null;
  }

  public onPayment(agenda: any){
    
    if(agenda.statusPayment === 'create_payment')
      this.router.navigate(['/agendamentos/adicionar'],{
        queryParams: agenda.toConfirmationScreen
      });
    else
      this.paymentService.getPaymentDetails(agenda.agendaId).subscribe(
        res =>{
          this.dialogRef = this.dialog.open(ModalPaymentComponent,{
            disableClose: true,
            panelClass: 'paymentModal',
            data:{
              scheduleId: agenda.agendaId,
              search: res,
              linkedPayment: false
            }
          });
      
          this.dialogRef.afterClosed().subscribe(
            res =>{
              this.paymentService.verifyTransactionPayment(agenda.agendaId).subscribe(
                paymentRes=>{

                  if( paymentRes.status === 'paid' || paymentRes === true)
                    clearInterval(this.paymentCardDuration);
                  else if(paymentRes?.status === 400){
                    this.snack.open('Erro ao verificar confirmação de pagamento','ok', {duration: 3000, panelClass: this.className});
                    return;
                  }
                    
                    this.onRefresh.emit(true);
                },
                err=>{
                  this.snack.open('Erro ao verificar confirmação de pagamento','ok', {duration: 3000, panelClass: this.className});
                }
              )
            }
          )
        },
        err =>{
          this.snack.open('Erro ao carregar detalhes do agendamento', 'ok', { duration: 3000 });
        }
      )

  }

  public onUpdatePreSchedulePayment(agendaId: number) {

    this.agendamentosService.updatePreSchedulePayment(agendaId).subscribe(
      res =>{
        this.onRefresh.emit(true);
        this.snack.open('O pagamento está sendo processado', 'ok', {duration: 5000, panelClass: this.className})
      },
      err =>{
        this.snack.open('Houve um erro ao criar o agendamento','ok', {duration: 3000, panelClass: this.className});
      }
    );
  }

  public JoinVideoCall(linkTeleconsulta: string) {
    window.open(`${FRONT_REMOTECARE}?id=${linkTeleconsulta}`, '_blank');
  }

  showUsageRules(){
    const dialogRef = this.dialog.open(UsageRulesComponent,{
      disableClose: true,
      panelClass: 'paymentModal'
    });

    dialogRef.afterClosed().subscribe(()=>{})
  }

  public showPaymentDetails(agendaId: number){
    const dialogRef = this.dialog.open(PaymentInfoComponent,{
      disableClose: true,
      panelClass: 'paymentModal',
      data: {
        agendaId
      }
    });

  }

  public onAddToCalendar(item){
    this.addingToCalendar = true;
    const config = this.createConfiguration(item);

    const button = document.getElementById('addToCalendar-'+ item.agendaId);

    if(button){
      button.addEventListener('click', ()=> atcb_action(config, button));
      this.addingToCalendar = false;
      button.click();
    }
  }

  private createConfiguration(item): any{

    const configuration = {
      name: "",
      description: "",
      startDate: "",
      startTime: "",
      endTime: "",
      options: ["Google", "Apple", "Outlook.com"],
      timeZone: "America/Sao_Paulo",
      location: "",
      language: "pt"
    };

    const date = item.agendaDate.split('T');
    const startTime = item.agendaHourStart.toString().padStart(4, '0');
    const endTime = item.agendaHourEnd.toString().padStart(4, '0');

    configuration.startDate = date[0];
    configuration.startTime = startTime.slice(0,2) + ':' + startTime.slice(-2);
    configuration.endTime = endTime.slice(0,2) + ':' + endTime.slice(-2);

    configuration.name = `Agendamento com ${item.professionalTitle || ''} ${item.professionalName}`;
    configuration.description = `Consulta de procedimento ${item.procedureNickname.toUpperCase()}, em ${item.clinicName}.`;

    if(item.clinicAddress)
      configuration.location = `${item.clinicAddress}, ${item.clinicAddressNumber} ${item.clinicAddressComplement || ''}, ${item.clinicAddressNeighbor}, ${item.clinicAddressCity} — ${item.clinicAddressUf}, CEP: ${item.clinicAddressZipcode}.`
    
    if(item.procedureTeleconsultation){
      configuration.location = `${FRONT_REMOTECARE}?id=${item.linkTeleconsulta}`
      configuration.description += ` Segue o link da teleconsulta: ${FRONT_REMOTECARE}?id=${item.linkTeleconsulta}`
    }

    return configuration;
  }
}
