import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import {
  Validators,
  FormGroup,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { IHealthPlanResponse } from 'src/app/pages/cadastro/cadastro.interfaces';
import { IConvenioSelect } from 'src/app/pages/perfil/perfil.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MSG } from 'src/app/util/constants';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'form-convenio',
  templateUrl: './form-convenio.component.html',
  styleUrls: ['./form-convenio.component.scss'],
})
export class FormConvenioComponent implements OnInit {
  public conveniosSelect: IConvenioSelect[] = [];
  public exibeConvenio: boolean = false;
  public index: number;
  public healthPlans = new Set();
  public plans: any = [];
  public isAdding: boolean;
  public isEditing: boolean;
  public tempEdit: any = {};
  public convenioEditing: string;
  public companyEditing: string;
  public planEditing: string;
  public cardNumberEditing: string;
  public validityEditing: string;
  public className: string = '';
  public healthPlanHasChanged: boolean;
  public canSaveEdition: boolean = true;
  public isAmorSaude: boolean = false;
  public userCan: any;

  @Input() convenios: IHealthPlanResponse[];
  @Input() conveniosQueue: IHealthPlanResponse[];
  @Input() patient;
  @Output() onRemovePlan: EventEmitter<any> = new EventEmitter();
  @Output() onAddPlan: EventEmitter<any> = new EventEmitter();
  @Output() onEditPlan: EventEmitter<any> = new EventEmitter();

  public form: FormGroup = new FormGroup({
    corporateName: new FormControl('', Validators.required),
    plan: new FormControl('', Validators.required),
    healthPlanId: new FormControl(''),
    company: new FormControl(''),
    cardNumber: new FormControl(''),
    validity: new FormControl('', this.dateValidator),
  });

  public formEdition: FormGroup = new FormGroup({
    corporateName: new FormControl('', Validators.required),
    planEdit: new FormControl('', Validators.required),
    company: new FormControl(''),
    cardNumber: new FormControl(''),
    validity: new FormControl('', this.dateValidator),
  });

  constructor(private snackBar: MatSnackBar, private loginService: LoginService) {
    this.isAmorSaude = this.loginService.isAmorSaude();
    this.className = this.isAmorSaude? 'amorsaude' : '';
  }

  public ngOnInit() {
    const profile = this.patient.permissions.profile;
    this.userCan = (profile && profile.healthplan) || {};
  }

  public onChangeCorporate(index: string) {
    this.plans = this.convenios[index].plans;
  }

  public removePlan(healthPlanId: number) {
    if (!this.itemCanBeDeleted(healthPlanId)) return;
    this.onRemovePlan.emit(healthPlanId);
    this.healthPlanHasChanged = true;
  }

  public editPlan(plan) {
    this.formEdition.controls.corporateName.setValue(plan.value.corporateName);

    if (plan.value.corporateName) {
      this.plans = _.find(
        this.convenios,
        (i) => i.corporateName === plan.value.corporateName,
      ).plans;
    }

    this.formEdition.controls.planEdit.setValue(plan.value.plan);
    this.formEdition.controls.company.setValue(plan.value.company);
    this.formEdition.controls.cardNumber.setValue(plan.value.cardNumber);
    this.formEdition.controls.validity.setValue(plan.value.validity);
    this.isEditing = plan.value.id;
  }

  public verifyPlans() {
    // if (!this.plans.length) {
    //   this.showError(MSG.PROFILE.EMPTY_HEALTHPLAN_SELECT);
    // }
  }

  public onOpenEditItem() {
    this.plans = this.convenios[0].plans;
  }

  public isValidEdition() {
    _.each(_.keys(this.form.controls), (field) => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
    return this.formEdition.valid;
  }

  public onEditItem(val, id, key, $event?) {
    if (this.isValidEdition()) {
      if (key === 'validity') {
        val = val.replace(new RegExp('/', 'g'), '');
      }

      if (key === 'corporateName') {
        this.onChangeCorporate($event.value);
        this.formEdition.controls.plan.reset();
      }

      this.tempEdit[id] = this.tempEdit[id] || {};
      this.tempEdit[id][key] = val;
      this.canSaveEdition = true;
    } else {
      this.canSaveEdition = false;
    }
  }

  public saveEdition(plan) {
    if (!this.isValidEdition()) {
      this.showError('Verifique os campos informados');
      this.canSaveEdition = false;
      return;
    }

    if (this.hasEditedFields(plan.key)) {
      this.onEditPlan.emit({ id: plan.key, value: this.tempEdit[plan.key] });
      this.tempEdit[plan.key] = {};
    }

    this.isEditing = false;
    this.healthPlanHasChanged = true;
    // this.formEdition.reset();
  }

  public cancelEdition() {
    this.plans = [];
    this.isEditing = false;
  }

  public addPlan() {
    this.validForm();

    if (this.form.invalid) {
      this.showError(MSG.PROFILE.INCORRECT_HEALTHPLAN_FIELDS);
      return;
    }

    const id = `temp_${new Date().getTime()}`;
    const plan = {
      id,
      plan: {
        id,
        healthPlanId: this.form.value.plan.healthPlanId,
        corporateName: this.form.value.plan.corporateName,
        plan: this.form.value.plan.plan,
        planId: this.form.value.plan.id,
        company: this.form.value.company,
        cardNumber: this.form.value.cardNumber,
        validity: this.form.value.validity,
      },
    };
    this.onAddPlan.emit(plan);
    this.form.reset();
    this.plans = [];
    this.isAdding = false;
    this.isEditing = false;
    this.healthPlanHasChanged = true;
  }

  public itemCanBeDeleted(id: number) {
    return this.userCan.delete || /temp_/.test(id.toString());
  }

  private hasEditedFields(el) {
    return this.tempEdit[el] && _.keys(this.tempEdit[el]).length;
  }

  private validForm() {
    _.each(_.keys(this.form.controls), (field) => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  private dateValidator(control: AbstractControl) {
    if (!control.value) return;
    if (!moment(control.value, 'DD/MM/YYYY').isValid()) {
      return { validator: true };
    }
    return null;
  }

  private showError(msg: string) {
    this.snackBar.open(msg, '', {
      duration: 5000,
      panelClass: this.className
    });
  }
}
