import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {
  loading = true;
  paymentInfo: any = {}
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    private paymentService: PaymentService,
    public dialogRef: MatDialogRef<PaymentInfoComponent>,
    private snack: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.loadPayemntInfo()
  }

  loadPayemntInfo(){

    const agendaId = this.data.agendaId
    this.paymentService.loadPaymentInfo(agendaId).subscribe(
      res =>{
        this.paymentInfo = res;
        this.loading = false;
      },
      err =>{
        this.snack.open('Erro ao carregar informações de pagamento', 'ok', {
          duration: 3000
        });
        this.dialogRef.close();
      }
    )
  }

  parseToFloat(value: string): number{
    return parseFloat(value);
  }
}
