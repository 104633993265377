import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CadastroComponent } from 'src/app/pages/cadastro/cadastro.component';
import { EsqueciMinhaSenhaComponent } from 'src/app/pages/esqueci-minha-senha/esqueci-minha-senha.component';
import { ILoginResponse } from 'src/app/pages/login/login.interface';
import { Views } from 'src/app/pages/views';
import { LoginService } from 'src/app/services/login.service';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';

@Component({
  selector: 'app-partial-login',
  templateUrl: './partial-login.component.html',
  styleUrls: ['./partial-login.component.scss']
})
export class PartialLoginComponent extends Views implements OnInit {

  private unsubscribe$: Subject<void> = new Subject();
  public loging: boolean;
  public urlParams
  public isAmorSaude = false
  public isSchedulersView = false;
  public isAllowedToRegister = true;
  public className: string = '';
  public step = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any = null,
    public dialogRef: MatDialogRef<PartialLoginComponent>,
    private fb: FormBuilder,
    private loginService: LoginService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
  ) {
    super();
    this.buildForm();

    this.isAmorSaude = this.loginService.isAmorSaude();
    this.isSchedulersView = loginService.isSchedulersView();
    this.isAllowedToRegister = !loginService.noRegister();
    this.className = this.isAmorSaude? 'amorsaude' : '';
   }

   public ngOnInit() {
    this.initForm(this.fb);
  }
  
  private buildForm() {
    this.campos = {
      id: [],
      password: [],
    };
  }

  public checkRegistration(){
    if(this.step === 1){
      this.checkUser();
    }else{
      this.login();
    }
  }

  public stepBack(){
    this.step--;
  }

  public checkUser(){
    this.loading = true;
    this.loginService.checkUser(this.fg.value.id).subscribe({
      next: res=>{
        if(res.status === 200){
          this.step++;
          return;
        }

        if(res.status === 404 && this.isAllowedToRegister)
          this.newUser();

        if(res.status === 404 && !this.isAllowedToRegister){
          this.snack.open('Usuário inválido', 'ok', { duration: 3000, panelClass: this.className });
          this.loging = false;
        }
        

      },
      error: error =>{
          this.snack.open('Erro ao validar usuário', 'ok', { duration: 3000, panelClass: this.className });
          this.loging = false;
      }
    })
  }
  
  public login() {
    this.loging = true;
    const account = LocalStorageUtil.getAccountInfo();
    this.loginService
      .login(this.fg.value, account.accountId, this.isSchedulersView, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp: ILoginResponse) => {
          this.loging = false;
          LocalStorageUtil.setToken(resp.accessToken);
          if("patient" in resp) {
            LocalStorageUtil.setPatientInfo(resp.patient);
            localStorage.removeItem('scheduler-info')
          }
          
          if("scheduler" in resp) {
            LocalStorageUtil.setSchedulerInfo(resp.scheduler);
            localStorage.removeItem('patient-info')
          }
          
          sessionStorage.removeItem('dont-disturb');

          this.loginService.connectSentry()
          this.data.onConfirm();
          this.dialogRef.close();
        },
        (err) => {
          this.snack.open('Usuário ou senha inválido', '', { duration: 3000, panelClass: this.className });
          this.loging = false;
        },
      );
  }

  public newUser(goBack: boolean = false){
    if(!goBack)
      this.step = 4;
    else
      this.step = 1;
  }

  public forgotPassword(goBack: boolean = false){
    if(!goBack)
      this.step = 3;
    else
      this.step = 1;
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
