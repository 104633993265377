<div class="card-list">
  <app-card-item
    *ngFor="let item of content"
    [itemType]="title"
    [item]="item"
    [deleting]="isDeleting"
    (onDelete)="onDeleteHandler($event)"
    (onRefresh)="onRefreshHandler()"
    (timesOver)="onTimesOver($event)"
    class="card-list-item-wrapper"
  >
  </app-card-item>
</div>
