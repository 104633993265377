import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { GeneratePaymentParams } from '../shared/components/modal-payment/amor-saude.interface';
import { MatSnackBar } from '@angular/material/snack-bar';

var creditCardType = require("credit-card-type");

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor( private apiService: ApiServiceService, private snack: MatSnackBar ) {  }

  //Verifica se um número de cartão é válido usando o algoritmo de Luhn
  async luhnValidation (cardNumber: string): Promise<boolean> {
    if (!cardNumber.length) {
        return;
    }

    // Remove o último dígito;
    const lastDigit = Number(cardNumber[cardNumber.length - 1]);

    // inverte os números do cartão
    const reverseCardNumber = cardNumber.slice(0, cardNumber.length - 1).split('').reverse().map(x => Number(x));
    let sum = 0;

    // Múltiplica por 2 cada dígito em posição ímpar
    // Depois subtrai 9 se o resultado da multiplicação for > 9
    for (let i = 0; i <= reverseCardNumber.length - 1; i += 2) {
        reverseCardNumber[i] = reverseCardNumber[i] * 2;
        if (reverseCardNumber[i] > 9) {
            reverseCardNumber[i] = reverseCardNumber[i] - 9;
        }
    }

    // Soma todos os valores obtidos no loop anterior
    sum = reverseCardNumber.reduce((acc, currValue) => (acc + currValue), 0);

    // Calcula o módulo de 10 da soma anterior com o último digito removido na primeira etapa
    // Se o resultado for 0, ele será um cartão válido
    return ((sum + lastDigit) % 10 === 0);
  }

  
  async getCardFlag(cardNumber: string): Promise<string>{

    const cardInfo = await creditCardType(cardNumber);

    return cardInfo[0].niceType;
  }

  async copyCodeToClipboard(text:string){
    return await new Promise((res) =>{
      const textarea = document.createElement('textarea');
      textarea.style.position = 'fixed';
      textarea.style.left = '0';
      textarea.style.top = '0';
      textarea.style.opacity = '0';
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.snack.open(
        'Código copiado', 
        'ok', 
        { 
          duration: 2000 
        }
      );
      res(true);
    });
  }

  hasCardToken(patientId:number){
    return this.apiService.get(`/payment/has-card-token/${patientId}`);
  }

  hasPaymentToken(token:string){
    return this.apiService.get(`/payment/token-validation/${token}`);
  }

  verifyTransactionPayment(scheduleId:number){
    return this.apiService.get(`/payment/verify-payment/${scheduleId}`);
  }

  getPaymentDetails(scheduleId:number){
    return this.apiService.get(`/payment/details/${scheduleId}`);
  }

  loadUsageRules(accountId){
    return this.apiService.get(`/payment/usage-rules/${accountId}`);
  }

  loadPaymentInfo(agendaId: number){
    return this.apiService.get(`/payment/info/${agendaId}`);
  }

  getPaymentTypeAccepted(accountId: number){
    return this.apiService.get(`/payment/type-accepted/${accountId}`);
  }
  
  generatePaymentLink(scheduleId: number){
    return this.apiService.get(`/payment/link/${scheduleId}`);
  }

  createTransaction(params: any, linkedPayment: boolean){
    const urn = linkedPayment? `/payment/linked-transaction` : `/payment/transaction`
    return this.apiService.post(urn, params);
  }

  createLinkedTransaction(params: any){
    return this.apiService.post(`/payment/linked-transaction`, params);
  }

  generatePayment(params:GeneratePaymentParams){
    return this.apiService.post(`/payment/generate`, { ...params });
  }

  generateInstallments(params:{ cardFlag: string, value: string, scheduleId: number }){
    return this.apiService.post(`/payment/installments`, { ...params });
  }
}
