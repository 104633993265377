<div *ngIf="loading" class="loading">
    <mat-spinner [diameter]="50" class="spinner"></mat-spinner>
</div>
<span *ngIf="!loading"> 
    <h2 mat-dialog-title>Regras de utilização</h2>
      
    <mat-dialog-content>
        <div [innerHTML]="usageRules | safeHtml" class="ck-content"></div>
    </mat-dialog-content>
    
    <mat-dialog-actions>
        <button mat-button mat-dialog-close> Fechar</button>
    </mat-dialog-actions>
</span>
