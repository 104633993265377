import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { CadastroService } from 'src/app/services/cadastro.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ativar-email',
  templateUrl: './ativar-email.component.html',
  styleUrls: ['./ativar-email.component.scss'],
})
export class AtivarEmailComponent implements OnInit {
  public token: string;
  public msg: string = 'Validando token de acesso';
  public loading: boolean = true;

  public accountId: number;
  public waitLocalstorage: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cadastroService: CadastroService,
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.params['token'];

    this.waitLocalstorage = setInterval(() => {
      this.accountId = LocalStorageUtil.getAccountInfo().accountId;

      if (this.accountId != null) {
        this.cadastroService
          .confirmEmail({ token: this.token, accountId: this.accountId })
          .subscribe(
            (resp) => {
              sessionStorage.setItem('dont-disturb', '1');
              this.msg =
                'Cadastro ativado com sucesso! \n' +
                'Estamos redirecionando para o login';

              setTimeout(() => {
                this.loading = false;
                this.router.navigate(['/login'], {
                  queryParamsHandling: 'preserve',
                });
              }, 2000);
            },
            () => {
              this.msg = 'Token inválido ou já utilizado!';
              this.loading = false;
            },
          );

        clearInterval(this.waitLocalstorage);
      }
    }, 1000);
  }
}
