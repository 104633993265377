<mat-card-content>
   <div class="row">
     <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div mat-dialog-actions class="row">
        <h1>Histórico</h1>
        <button [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary btn-round' : ' btn-secondary btn-round closeBtn'" class="closeBtn" (click)="onClickClose()">
          <fa-icon icon="times"></fa-icon>
        </button>
       </div>
       <div class="no-result" *ngIf="data.voucherHistory.length == 0">
        <span>Nenhum procedimento agendado</span>
       </div>
       <div class="historico">
         <h3 *ngFor="let h of data.voucherHistory; let i = index">
            <div class=""><b>Data do agendamento: </b> {{h.scheduleDate | date: 'DD/MM/yyyy '}}</div>
            <div class=""><b>Procedimento: </b> {{h.procedureName}}</div>
            <div class=""><b>Clínica: </b> {{h.clinicName}}</div>
            <div class=""><b>Profissional: </b> {{h.professionalName}}</div>
            <div class=""><b>Profissão: </b> {{h.profession}}</div>
            <div class=""><b>Especialidade: </b> {{h.specialty}}</div>
         </h3>
       </div>
     </div>
     <br />
   </div>
 </mat-card-content>
 
 