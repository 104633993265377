<div class="formSearch">
  <mat-form-field appearance="outline">
      <mat-label>Paciente</mat-label>
      <input 
          matInput
          [formControl]="searchControl"
          [matAutocomplete]="auto" 
          placeholder="Digite o nome do paciente" 
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let patient of patients" [value]="patients" (click)="onSelectPatient(patient)">
            {{ patient.name }}
          </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="loadingList" class="floatingLoading">
      <app-loading></app-loading>
    </div>
</div>
