import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AgendamentosService } from 'src/app/services/agendamentos.service';

@Component({
  selector: 'app-search-patient',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.scss']
})
export class SearchPatientComponent implements OnInit {
  @Input() searchControl: FormControl;
  @Output() selectionChange: EventEmitter<boolean> = new EventEmitter();


  patients:{id: number, name: string}[] = [];
  patientsNotFound = [ {id: -1, name: 'Nenhum paciente encontrado'}]

  showList = false;
  loadingList = false;

  constructor(
    private agendamentosService: AgendamentosService,
    private renderer: Renderer2, 
    private elRef: ElementRef,
    private snack: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.searchControl.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((name) => {

        if(typeof name === 'string')
          if (name.length >= 3) 
            return this.search(name);
          else{
            this.showList = false;
            return of([]);
          }
        else {
          this.showList = false;
          return of([]);
        }

      })
    )
    .subscribe(
      (patients) => {

        this.patients = patients;
        this.showList = true;
        this.loadingList = false;
      },
      (error) => {
        this.showList = false;
        this.loadingList = false;
        this.snack.open('Erro ao carregar pacientes, tente novamente', 'ok', { duration: 3000 });
      }
      
    );


    this.renderer.listen('document', 'click', (event: Event) => {
      if (!this.elRef.nativeElement.contains(event.target)) {
        this.showList = false;
      }
    });
  }

  search(name: string){
    this.loadingList = true;
    this.patients = [];
    return this.agendamentosService.findPatientsByInfo({name}).pipe(
      map(res =>{
        if(res?.data?.length > 0)
          return res.data;
        else
          return this.patientsNotFound;
      }),
      catchError(err =>{
        this.showList = false;
        this.loadingList = false;
        this.snack.open('Erro ao carregar pacientes, tente novamente', 'ok', { duration: 3000 });
        return of([]);
      })
    )
  }

  onSelectPatient(patient: {id: number, name: string}){
    if(!patient || patient.id == -1){
      this.showList = false;
      return;
    }

    this.searchControl.setValue(patient);
    this.showList = false;
    this.selectionChange.emit(true)
  }

  displayFn(patient: { id: number, name: string }): string {
    return patient && patient.name ? patient.name : '';
  }

}
