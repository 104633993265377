<div *ngIf="loading" class="loading">
    <mat-spinner [diameter]="50" class="spinner"></mat-spinner>
</div>
<span *ngIf="!loading"> 
    <div class="header">
        <button mat-mini-fab  mat-dialog-close class="closeModal">
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
    <h2 mat-dialog-title>Informações do pagamento</h2>
      
    <mat-dialog-content>
        <div class="ck-content">
            <div class="info">
                <div class="info-item">
                    <b>Procedimento:</b>
                    <p>{{ paymentInfo.procedureName }}</p>
                </div>
                <div class="info-item">
                    <b>Valor do procedimento:</b>
                    <p>R$ {{ paymentInfo.procedureValue }}</p> 
                </div>
                <div class="info-item">
                    <b>Voucher:</b>
                    <p>{{ paymentInfo.voucher || ' - ' }}</p> 
                </div>
                <div class="info-item">
                    <b>Desconto:</b>
                    <p>R$ {{ paymentInfo.discount }}</p> 
                </div>
                <div class="info-item">
                    <b>Parcelas:</b>
                    <p>{{ paymentInfo.installments}}</p> 
                </div>
                <div class="info-item">
                    <b>Forma de pagamento:</b>
                    <p>{{ paymentInfo.receiptForm}}</p> 
                </div>
                <div class="info-item total">
                    <b>Total pago:</b>
                    <p>R$ {{ paymentInfo.total }}</p> 
                </div>

            </div>

        </div>
    </mat-dialog-content>
</span>
