import { AgendamentosService } from '../../services/agendamentos.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IAgendamentoResponse } from '../agendamentos/agendamentos.interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { LoginService } from 'src/app/services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-agenda',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  public content: IAgendamentoResponse[] = [];
  public isGettingApiResult: boolean = true;
  public isAmorSaude: boolean = true;
  public title = 'Histórico';
  public isNotFound: boolean;
  public isFiltering: boolean;
  private unsubscribe$: Subject<void> = new Subject();
  private lastDay: number;
  private resultsLimit: number = 20;
  private className: string = ''

  public filter: FormGroup = new FormGroup({
    filterDate: new FormControl(),
  });

  constructor(
    private agendamentosService: AgendamentosService,
    private loginService: LoginService,
    private snack: MatSnackBar
  ) {
    this.isAmorSaude = this.loginService.isAmorSaude();
    this.className = this.isAmorSaude? 'amorsaude' : ''

  }

  public ngOnInit() {
    window.scrollTo(0, 0);
    this.getHistory();
  }

  public getHistory(scrolled?: boolean) {
    const filterDate = this.filter.value.filterDate
      ? moment(this.filter.value.filterDate).valueOf()
      : null;

    this.isGettingApiResult = true;
    this.agendamentosService
      .getAgendamentos('true', this.resultsLimit, this.lastDay, filterDate)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((history: IAgendamentoResponse[]) =>
        this.setHistory(history, scrolled),
      );
  }

  public setHistory(history: IAgendamentoResponse[], scrolled?: boolean) {
    this.isGettingApiResult = false;
    this.isNotFound = !history.length;

    if (this.filter.value.filterDate) {
      this.isFiltering = true;
      this.content = history;
      return;
    }

    if (this.isNotFound) return;

    this.content = scrolled ? [...this.content, ...history] : history;
    this.updatePagination(history);
  }

  public onScroll() {
    if (!this.isGettingApiResult) this.getHistory(true);
  }

  private updatePagination(agendamentos: IAgendamentoResponse[]) {
    this.lastDay = _.last(agendamentos).fullDate;
  }

  public getFilter() {
    return this.filter.value.filterDate;
  }

  public onFilterHistory() {
    this.getHistory();
  }

  public resetFilter() {
    this.lastDay = null;
    this.isFiltering = false;
    this.filter.reset();

    this.getHistory();
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
