<div class="loginCard" align="center">
  <mat-card>
    <div class="title purple">
      {{isSchedulersView? 'Nova' : 'Alterar'}} senha
    </div>

    <mat-spinner [diameter]="30"
      color="primary"
      *ngIf="loading">
    </mat-spinner>

    <mat-form-field appearance="outline">
      <mat-label>Senha</mat-label>
      <input matInput type="password" [(ngModel)]="password" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Confirme a senha</mat-label>
      <input matInput type="password" [(ngModel)]="confirmpassword" />
    </mat-form-field>

    <div *ngIf="!passwordChanged" class="col-12">
      <button mat-button [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary btn-large' : 'btn-secondary btn-large'" (click)="changePassword()">
        <span class="font19">Redefinir senha</span>
      </button>
    </div>

    <div *ngIf="passwordChanged" class="alert-success">
      Senha alterada com sucesso!
    </div><br>

  </mat-card>

  <app-footer></app-footer>
</div>
