import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SlackService {
  private webhook =
    'https://hooks.slack.com/services/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';

  constructor(private http: HttpClient) {}

  private sendMessage(msg): Observable<any> {
    return this.http.post(this.webhook, msg);
  }

  public slackTest(msg) {
    this.sendMessage({
      channel: '#ti-bugs',
      attachments: [
        {
          author_name: window.location.href,
          color: 'danger',
          title: 'Erro de execução',
          text: msg,
        },
      ],
    }).toPromise();
  }
}
