import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { atcb_action } from "add-to-calendar-button";
import * as moment from 'moment';
import { padStart } from 'lodash';
import { FRONT_REMOTECARE } from 'src/app/app.api';
import { IAgendamentoSearch } from 'src/app/pages/agendamentos/agendamentos.interfaces';
import { AgendamentosService } from 'src/app/services/agendamentos.service';
import { LoginService } from 'src/app/services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Views } from 'src/app/pages/views';
import { AccountInfo, LocalStorageUtil } from 'src/app/util/local-storage-util';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'agendamento-confirmacao',
  templateUrl: './confirmacao.component.html',
  styleUrls: ['./confirmacao.component.scss'],
})
export class ConfirmacaoComponent implements OnInit {
  @Input() step: number;
  @Input() amorSaude: boolean;
  @Input() isSchedulersView: boolean = false;
  @Input() search: IAgendamentoSearch;
  @Output() newPatientSchedule: EventEmitter<any> = new EventEmitter();
  
  accountInfo: AccountInfo;
  addingToCalendar = false;
  teleconsultation = {
    linkTeleconsulta: null
  };

  isPrevidas = false;

  constructor(
    private agendaService: AgendamentosService,
    private loginService: LoginService,
    private paymentService: PaymentService,
    private snack: MatSnackBar,
    private views: Views
  ){
    this.isPrevidas = loginService.isPrevidas();
    this.accountInfo = LocalStorageUtil.getAccountInfo()
  }

  async ngOnInit(): Promise<void> {

    if(this.isPrevidas){
      this.loginService.logout(this.isSchedulersView);
      this.snack.open('Agendamento realizado com sucesso', 'ok', { duration: 5000 });

      window.parent.postMessage('close', '*')
    }

    if(this.search.procedure.teleconsulta){
      this.addingToCalendar = true;
      const scheduleId = this.search.agendaId ?? this.search.preScheduledId;
      this.teleconsultation = await this.agendaService.getTeleconsultationLink(scheduleId)
      .then(res =>{
        this.addingToCalendar = false;
        return res;
      }).catch(
        err =>{
          this.addingToCalendar = false;
        }
      );
    } 
  }

  public onAddToCalendar(){
    this.addingToCalendar = true;
    
    const config = this.createConfiguration();

    const button = document.getElementById('addToCalendar');

    if(button){
      button.addEventListener('click', ()=> atcb_action(config, button));
      this.addingToCalendar = false;
      button.click();
    }
  }

  private createConfiguration(): Object{

    const configuration = {
      name: "",
      description: "",
      startDate: "",
      startTime: "",
      endTime: "",
      options: ["Google", "Apple", "Outlook.com"],
      timeZone: "America/Sao_Paulo",
      location: "",
      language: "pt"
    };

    const date = this.search.scheduleDate.slice(-4) + '-' +  this.search.scheduleDate.slice(2, 4) + '-' + this.search.scheduleDate.slice(0, 2);
    const startTime = this.search.hour.startTime.toString().padStart(4, '0');
    
    const _hour = startTime.substr(0, 2);
    const _minute = startTime.substr(2, 2);
    const _date = moment();
    _date.set('hour', +_hour);
    _date.set('minute', +_minute);
    _date.set('second', 0);
    _date.add(this.search.procedure.duration, 'minutes');

    const endTime = +(
      _date.get('hour') + padStart('' + _date.get('minute'), 2, '0')
    );
    configuration.startDate = date;
    configuration.startTime = startTime.slice(0,2) + ':' + startTime.slice(-2);
    configuration.endTime = padStart(endTime, 4, '0').slice(0,2) + ':' + padStart(endTime, 4, '0').slice(-2);

    configuration.name = `Agendamento com ${this.search.treatmentPronoun || ''} ${this.search.professional}`;
    configuration.description = `Consulta de procedimento ${this.search.procedure.name.toUpperCase()}, em ${this.search.local.name}.`;

    if(this.search.local.address)
      configuration.location = `${this.search.local.address}, ${this.search.local.number} ${this.search.local.complement || ''}, ${this.search.local.neighbor}, ${this.search.local.city} — ${this.search.local.uf}, CEP: ${this.search.local.zipcode}.`

    if(this.search.procedure.teleconsulta){
      configuration.location = `${FRONT_REMOTECARE}?id=${this.teleconsultation.linkTeleconsulta}`;
      configuration.description += ` Segue o link da teleconsulta: ${FRONT_REMOTECARE}?id=${this.teleconsultation.linkTeleconsulta}`;
    }
      
    return configuration;
  }

  public newSchedule(){
    this.newPatientSchedule.emit({
      step: 0,
      search: {}
    })
  }

  public async copyPaymentLink(){

    if(!this.search?.paymentLink){
      this.snack.open('Link não encontrado', 'ok', {
        duration: 4000
      });
      return;
    }

    await this.paymentService.copyCodeToClipboard(this.search.paymentLink).then(()=>{
      this.snack.open('O link foi copiado com sucesso', 'ok', {
        duration: 4000
      });

    }).catch(
      ()=>{
        this.snack.open('Erro ao copiar link de pagamento', 'ok', {
          duration: 4000
        })

      }
    );
  }
}
