import { CadastroService } from 'src/app/services/cadastro.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Views } from '../views';
import { LoginService } from 'src/app/services/login.service';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.scss'],
})
export class RedefinirSenhaComponent extends Views implements OnInit {
  public loading = true;
  public msg = '';
  public token = '';
  public password = '';
  public confirmpassword = '';
  public passwordChanged = false;
  public isAmorSaude = false;
  public isSchedulersView = false;
  public className: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snack: MatSnackBar,
    private cadastroService: CadastroService,
    private loginService: LoginService
  ) {
    super();
    this.isAmorSaude = this.loginService.isAmorSaude();
    this.className = this.isAmorSaude? 'amorsaude': '';
    this.isSchedulersView = loginService.isSchedulersView();
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.params['token'];

    this.loading = false;
  }

  changePassword() {
    if (this.password != this.confirmpassword) {
      this.snack.open('Senhas não conferem!', 'Ok', { duration: 3000, panelClass: this.className });
      return;
    }

    const account = LocalStorageUtil.getAccountInfo();
    this.cadastroService
      .changePassword({
        token: this.token,
        accountId: account.accountId,
        password: this.password,
        isSchedulersView: this.isSchedulersView 
      })
      .subscribe(
        (resp) => {
          this.snack.open('Senha alterada com sucesso!', 'Ok', {
            duration: 4000,
            panelClass: this.className
          });

          this.passwordChanged = true;

          if(this.isSchedulersView)
            this.router.navigate(['/agendadores/login']);
        },
        () => {
          this.snack.open('Token inválido ou já utilizado!', 'Ok', {
            duration: 3000,
            panelClass: this.className
          });
        },
      );
  }
}
