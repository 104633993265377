<div class="checkPayment" *ngIf="checkingPayment">
  <mat-spinner [diameter]="50" color="secondary">
  </mat-spinner>
</div>
<main-nav class="main-nav" >
  <div class="checkPayment" *ngIf="loadingProfile">
    <mat-spinner [diameter]="50" color="secondary">
    </mat-spinner>
  </div>
  <span class="professionalHours row" *ngIf="!loadingProfile">
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12" *ngIf="professionalId && (step < 6)">
      <app-profile-card 
        [isAmorSaude]="isAmorSaude"
        [professionalId]="professionalId"
        [completeBioOnly]="completeBioOnly"
      ></app-profile-card> 
    </div>
  
    <div [ngClass]="professionalId && (step < 6)? 'col-xl-7 col-lg-7 col-md-7 col-sm-12': 'col-xl-12 col-lg-12 col-md-12 col-sm-12'">
      <form>
        <agendamento-inputs
          [step]="step"
          [search]="search"
          [hidden]="step > 5"
          [professionalId]="professionalId"
          [rescheduleId]="rescheduleId"
          (stepChanged)="onChangeStep($event)"
          (dataChanged)="onChangeData($event)"
        >
        </agendamento-inputs>
    
        <agendamento-horarios
          [step]="step"
          [search]="search"
          [patientInfo]="patientInfo"
          [queryParams]="queryParams"
          *ngIf="step == 6"
          (stepChanged)="onChangeStep($event)"
          (dataChanged)="onChangeData($event)"
          class="p-display-block"
          [professionalId]="professionalId"
          [rescheduleId]="rescheduleId"
        >
        </agendamento-horarios>
    
        <agendamento-pre-confirmacao
          [step]="step"
          [search]="search"
          [error]="error"
          [isAmorSaude]="isAmorSaude"
          [accountInfo]="accountInfo"
          (settingVoucher)="onSettingVoucher($event)"
          *ngIf="step == 7 || step == 8"
        ></agendamento-pre-confirmacao>
    
        <agendamento-confirmacao 
          *ngIf="step == 9"
          [amorSaude]="isAmorSaude"
          [isSchedulersView]="isSchedulersView"
          [search]="search"
          (newPatientSchedule)="newPatientSchedule($event)"
        > </agendamento-confirmacao>
    
        <agendamento-controls
          [step]="step"
          [stopTimer]="stopTimer"
          [search]="search"
          [isAmorSaude]="isAmorSaude"
          [isSchedulersView]="isSchedulersView"
          [settingVoucher]="settingVoucher"
          [patientInfo]="patientInfo"
          [accountInfo]="accountInfo"
          (stepChanged)="onChangeStep($event)"
          (confirmedSchedule)="onPaymentToConfirm()"
          (timesOver)="onChangeStep($event, true)"
          *ngIf="step < 8"
          [ngClass]="{ 'controls-fixed': step === 6 }"
        >
        </agendamento-controls>
      </form>
    </div>
  </span>
</main-nav>
