import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Socket } from 'ngx-socket-io';
import { WaitingRoomService } from 'src/app/services/waiting-room.service';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';

@Component({
  selector: 'app-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.scss']
})
export class WaitingRoomComponent implements OnInit {
  loading = true;
  loadingSteps = false;
  step = 1;
  timer = 10;
  progress = 100;
  logo: string;
  clinic: any = null;
  schedules: {ids:number[], descriptions:string[]};
  interval: any;
  progressInterval: any;

  mode: ProgressSpinnerMode = 'determinate';

  cpf = new FormControl('', [Validators.required])

  constructor(
    private route: ActivatedRoute,
    private renderer: Renderer2, 
    private el: ElementRef,
    private snack: MatSnackBar,
    private router: Router,
    private socket: Socket,
    private waitingRoomService: WaitingRoomService
  ) {}
  

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'wrapper');

    this.loadClinica();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'wrapper');
  }

  private loadClinica(){
    this.clinic = {
      name: 'Clinica teste',
      hash: ''
    }
    
    const hash = this.route.snapshot.params['hash'];
    this.waitingRoomService.getClinicInfo(hash).subscribe({
      next: (res)=>{
        this.clinic = res;

        if(res.logo){
          this.logo = res.logo
        }else{
          const accountInfo = LocalStorageUtil.getAccountInfo();
          if (accountInfo){
            this.logo = accountInfo.logo ? accountInfo.logo : '';
          } 
        }
        this.loading = false;
        
      },
      error: (error)=>{
        this.snack.open(
          'Erro ao carregar dados da clínica',
          'ok',
          { duration: 4000 }
        );
        this.router.navigate(['/404']);
        this.loading = false;
      }
    })    
  }

  public checkDocument(){

    if (this.cpf.value) {
      if (!this.waitingRoomService.validarCPF(this.cpf.value)) {
        this.snack.open(
          'CPF inválido por favor verifique se digitou corretamente.',
          'ok',
          { duration: 4000 }
        );
        return;
      }
      this.loadingSteps = true;
      this.waitingRoomService.getSchedules(this.clinic.id, this.cpf.value).subscribe({
        next: (res:{ids:number[], descriptions:string[]}) =>{
          this.cpf.reset();
          this.step++;
          this.schedules = res;
          this.loadingSteps = false;
        },
        error: (error) =>{
          this.snack.open(
            'Erro ao carregar agendamentos, tente novamente',
            'ok',
            { duration: 4000 }
          );
          this.loadingSteps = false;
        }
      })
      
    }else{
      this.snack.open(
        'CPF é necessário',
        'ok',
        { duration: 3000 }
      );
      this.cpf.markAsTouched();
      return
    }
  }

  public cancelConfirmation(){
    this.step = 1;
    this.schedules = null;
  }

  public confirmSchedules(){
    this.loadingSteps = true;
    const arrivedAt = moment().subtract('3','h').format();
    this.waitingRoomService.confirmSchedules(this.schedules.ids, arrivedAt).subscribe({
      next: (res) =>{
        res.ids.forEach(id => {
          this.socket.emit('schedule-update', {
            professionalId: id,
          })
        });
        
        this.step = 3;
        this.startTimer();
        this.loadingSteps = false;
      },
      error: (error) =>{
        this.snack.open(
          'Erro ao confirmar presença, tente novamente',
          'ok',
          { duration: 4000 }
        );
        this.loadingSteps = false;
      }
    });
  }

  private startTimer(){
    this.interval = setInterval(()=>{
      if(this.timer !== 0){
        this.timer--;      
      }
      else
        this.stopTimer();
      
    }, 1000);

    this.progressInterval = setInterval(()=>{
      if(this.progress !== 0){
        this.progress--;          
      }
      else
        this.stop();
    }, 100)
  }

  private stopTimer(){
    clearInterval(this.interval);
    this.timer = 10;
    this.progress = 100;
    this.cancelConfirmation();
  }

  private stop(){
    clearInterval(this.progressInterval);
  }

}
