<main-nav>
  <app-loading *ngIf="isGettingApiResult" style="margin: 0 auto;"></app-loading>

  <form [formGroup]="form" *ngIf="!isSchedulersView">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="titleDivisor">
          Dados de Identificação
        </div>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-sm-5 col-12">
            <mat-form-field appearance="outline">
              <mat-label>Nome Completo</mat-label>
              <input matInput type="text" formControlName="name" required />
            </mat-form-field>
          </div>

          <div class="col-sm-4 col-12">
            <mat-form-field appearance="outline">
              <mat-label>CPF</mat-label>
              <input matInput type="text" formControlName="cpf" [specialCharacters]="['/', '.', '-']"
                [mask]="'000.000.000-00'" required />
            </mat-form-field>
          </div>

          <div class="col-sm-3 col-12">
            <mat-form-field appearance="outline">
              <mat-label>Sexo</mat-label>
              <mat-select formControlName="gender">
                <mat-option *ngFor="let gender of genderArr" [value]="gender.value">
                  {{ gender.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-3">
            <mat-form-field appearance="outline">
              <mat-label>Data de nascimento</mat-label>
              <input matInput type="text" formControlName="birthDate" mask="00/00/0000" required />

              <mat-error *ngIf="this.form.controls.birthDate.invalid">
                Data inválida
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-4">
            <mat-form-field appearance="outline">
              <mat-label>Celular</mat-label>
              <input matInput type="text" formControlName="mobileNumber" [specialCharacters]="['(', ')', '-', ' ']"
                [mask]="'(00) 00000-0000'" required="true" />
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-5">
            <mat-form-field appearance="outline">
              <mat-label>E-mail</mat-label>
              <input matInput type="email" formControlName="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                required />

              <mat-error *ngIf="form.controls.email.errors?.pattern">
                E-mail inválido
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="titleDivisor col-12">
          Endereço
        </div>

        <div class="row">
          <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>CEP</mat-label>
              <input
                matInput
                type="text"
                formControlName="zipcode"
                placeholder="CEP"
                (input)="onChangeCEP()"
                [specialCharacters]="['-']"
                mask="00000-000"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Endereço</mat-label>
              <input
                matInput
                type="text"
                formControlName="address"
                placeholder="Endereço"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Número</mat-label>
              <input
                matInput
                type="text"
                formControlName="number"
                placeholder="Número"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Complemento</mat-label>
              <input
                matInput
                type="text"
                formControlName="complement"
                placeholder="Complemento"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Bairro</mat-label>
              <input
                matInput
                type="text"
                formControlName="neighborhood"
                placeholder="Bairro"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Cidade</mat-label>
              <input
                matInput
                type="text"
                formControlName="city"
                placeholder="Cidade"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
            <mat-form-field appearance="outline">
              <mat-label>Estado</mat-label>
              <mat-select formControlName="state">
                <mat-option *ngFor="let t of estados" [value]="t">
                  {{ t }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="titleDivisor">
          Convênios
        </div>
      </div>

      <div class="col-12 col-sm-12" *ngIf="profile">
        <form-convenio [convenios]="convenios" [conveniosQueue]="conveniosQueue" [patient]="profile"
          (onRemovePlan)="onRemovePlan($event)" (onAddPlan)="onAddPlan($event)" (onEditPlan)="onEditPlan($event)">
        </form-convenio>
      </div>

      <div *ngIf="!isOnIframe" class="col-12">
        <div class="titleDivisor">
          Senha
        </div>
      
        <mat-slide-toggle (change)="togglePassword($event)" #updatePass>
          Alterar Senha
        </mat-slide-toggle><br />

        <mat-form-field appearance="outline" *ngIf="updatePass.checked" class="inputPass">
          <mat-label>Nova senha</mat-label>
          <input matInput formControlName="password" [type]="hidePass ? 'password' : 'text'" required />

          <mat-icon matSuffix (click)="hidePass = !hidePass">
            {{ hidePass ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </mat-form-field>
      </div>

      <div class="top20"></div>

      <!-- Esse margin-bottom é necessário para que o aviso de cadastro não apareça a frente desses botões -->
      <div align="right" class="col-12 bot50">
        <button mat-button [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary cancel' : 'btn-secondary cancel'" [routerLink]="[cancelLink]">
          <fa-icon icon="times"></fa-icon> <span> Cancelar</span>
        </button>

        <button mat-button [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary' : 'btn-secondary'" (click)="saveProfile()" [disabled]="isLoading">
          <fa-icon *ngIf="!isLoading" icon="check"></fa-icon>
          <span *ngIf="!isLoading">
            Salvar
          </span>
          <mat-spinner *ngIf="isLoading" [diameter]="20" color="primary">
          </mat-spinner>
        </button>
      </div>
    </div>
  </form>

  <form [formGroup]="schedulerForm" *ngIf="isSchedulersView">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="titleDivisor">
          Dados de Identificação
        </div>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-sm-4 col-12">
            <mat-form-field appearance="outline">
              <mat-label>Nome Completo</mat-label>
              <input matInput type="text" formControlName="name" required />
            </mat-form-field>
          </div>

          <div class="col-sm-4 col-12">
            <mat-form-field appearance="outline">
              <mat-label>CPF</mat-label>
              <input matInput type="text" formControlName="cpf" [specialCharacters]="['/', '.', '-']"
                [mask]="'000.000.000-00'" required />
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-4">
            <mat-form-field appearance="outline">
              <mat-label>E-mail</mat-label>
              <input matInput type="email" formControlName="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                required />

              <mat-error *ngIf="schedulerForm.controls.email.errors?.pattern">
                E-mail inválido
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="!isOnIframe" class="col-12">
        <div class="titleDivisor">
          Senha
        </div>
      
        <mat-slide-toggle (change)="togglePassword($event)" #updatePass>
          Alterar Senha
        </mat-slide-toggle><br />

        <mat-form-field appearance="outline" *ngIf="updatePass.checked" class="inputPass">
          <mat-label>Nova senha</mat-label>
          <input matInput formControlName="password" [type]="hidePass ? 'password' : 'text'" required />

          <mat-icon matSuffix (click)="hidePass = !hidePass">
            {{ hidePass ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </mat-form-field>
      </div>

      <div class="top20"></div>

      <!-- Esse margin-bottom é necessário para que o aviso de cadastro não apareça a frente desses botões -->
      <div align="right" class="col-12 bot50">
        <button mat-button [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary cancel' : 'btn-secondary cancel'" [routerLink]="[cancelLink]">
          <fa-icon icon="times"></fa-icon> <span> Cancelar</span>
        </button>

        <button mat-button [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary' : 'btn-secondary'" (click)="saveScheduleProfile()" [disabled]="isLoading">
          <fa-icon *ngIf="!isLoading" icon="check"></fa-icon>
          <span *ngIf="!isLoading">
            Salvar
          </span>
          <mat-spinner *ngIf="isLoading" [diameter]="20" color="primary">
          </mat-spinner>
        </button>
      </div>
    </div>
  </form>
</main-nav>
