<div class="horarios-detail">
  <div class="horarios-detail-info">
    <ul [ngClass]="isAmorSaude? 'horarios-detail-info-list horarios-detail-amorsaude-color': 'horarios-detail-info-list horarios-detail-default-color'">
      <li>
        <strong>Clínica: </strong>
        {{ search.local.name }}
      </li>
      <li>
        <strong>
          Profissional:
        </strong>
        {{ search.occupation.name }}
        <ng-container *ngIf="search?.expertise?.name">
          ({{ search.expertise.name }})
        </ng-container>
      </li>
      <li>
        <strong>Procedimento: </strong>
        {{ search.procedure.name }}
      </li>
    </ul>
  </div>
</div>
