<main-nav>
  <mat-form-field appearance="outline" [formGroup]="filter">
    <mat-label>
      Filtrar
    </mat-label>
    <input
      matInput
      formControlName="filterDate"
      placeholder="Filtrar por data do histórico"
      [matDatepicker]="filterDate"
      (dateChange)="onFilterHistory()"
      (click)="filterDate.open()"
      [value]="filterDate"
    />

    <mat-datepicker #filterDate></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="filterDate">
    </mat-datepicker-toggle>
  </mat-form-field>

  <p *ngIf="isFiltering">
    Mostrando resultados a partir de
    {{ getFilter() | date: 'DD [de] MMMM [de] YYYY' }}.
    <span (click)="resetFilter()" style="color: #0af; cursor: pointer;"
      >Limpar filtro de data</span
    >
  </p>

  <div
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="100"
    [fromRoot]="true"
    (scrolled)="onScroll()"
  >
    <app-list [content]="content" [title]="title"></app-list>
    <div
      *ngIf="isNotFound && !isGettingApiResult && !content.length"
      class="empty-response-message"
    >
      Nenhum resultado encontrado.
    </div>
  </div>
  <app-loading
    *ngIf="isGettingApiResult"
    class="p-margin-top-1 p-display-block"
  ></app-loading>
</main-nav>
