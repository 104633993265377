<div class="defaultCard" align="center">
    <mat-card>
        <h1 class="title purple" *ngIf="!loading && validToken && !payed">
            Pagamento de agendamento
        </h1>

      <div 
        class="title purple message"
        *ngIf="!loading && (!validToken || payed)"
      >
        <fa-icon *ngIf="!validToken" icon="times-circle" class="not"></fa-icon>
        {{ msg }}
      </div>
        
      <p *ngIf="!loading && payed">
        <fa-icon [icon]="['far', 'check-circle']" size="9x" class="green"></fa-icon>
      </p>

      <div *ngIf="!loading && validToken && !payed">
        <time>
            {{ data.search.scheduleDate | date: 'dddd, DD/MM/YYYY':'DDMMYYYY' }} às
            {{ data.search.hour.startTime | date: 'HH:mm':'HHmm' }} (horário de Brasília)
        </time>
    
        <address *ngIf="!data.search.procedure.teleconsulta">
            {{ data.search.local.name }} -
        <span>
            {{ data.search.local.address }}
        </span>
            {{ data.search.local.number | concat: ', ':true:true }}
            {{ data.search.local.neighbor | concat: ' - ':true:true }}
        </address>

        <div class="procedureName col-12">
            <h2>
            {{ data.search.procedure.name }}
            </h2>
        </div>
        <div class="professinalAndValue">
            <div class="professionalDocument col-xl-7 col-lg-7 col-md-7 col-sm-12">
                <h3 *ngIf="data.search.treatmentPronoun && data.search.professional">
                    {{ data.search.treatmentPronoun }} {{ data.search.professional }}
                </h3>
                <p *ngIf="data.search.occupation && data.search.expertise">
                    {{ data.search.occupation.name }}
                    {{ data.search.expertise.name ? '(' + data.search.expertise.name + ')' : '' }}
                </p>
                <h4 class="purple" *ngIf="data.search.document">
                    {{ data.search.documentType }} {{ data.search.document }}
                    <span *ngIf="data.search.rqe">RQE {{ data.search.rqe }}</span>
                </h4>
            </div>
            <div class="valueToPay col-xl-5 col-lg-5 col-md-5 col-sm-12">
                <h2 class="purple">R$ {{data.search.price.replace('.', ',')}}</h2>
            </div>
        </div>

    </div>

    <button
        mat-buttom
        (click)="onClickPayment()"
        *ngIf="!loading && validToken && !payed"
    >
      Pagar
    </button>
  <div class="load">
      <mat-spinner [diameter]="30" color="primary" *ngIf="loading"></mat-spinner>  
  </div>
    </mat-card>
</div>