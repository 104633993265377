import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { IVoucherHistory } from '../campanhas/campanhas.interfaces';

@Component({
  selector: 'app-hitorico-detalhado',
  templateUrl: './hitorico-detalhado.component.html',
  styleUrls: ['./hitorico-detalhado.component.scss']
})
export class HitoricoDetalhadoComponent implements OnInit {

  public isAmorSaude: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<HitoricoDetalhadoComponent>,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: {voucherHistory: IVoucherHistory[]}
  ) { 

    this.isAmorSaude = this.loginService.isAmorSaude();

  }

  ngOnInit(): void {
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

}
