import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { IPatientResponse } from 'src/app/pages/perfil/perfil.interface';
import { Views } from 'src/app/pages/views';
import { AmorSaudeService } from 'src/app/services/amor-saude.service';
import { LoginService } from 'src/app/services/login.service';
import { PaymentService } from 'src/app/services/payment.service';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { AmorSaudeLinkResponse, GenerateLinkBody, GeneratePaymentParams } from './amor-saude.interface';
import { CompleteAddressComponent } from './complete-address/complete-address.component';
import { UsageRulesComponent } from './usage-rules/usage-rules.component';
import { Socket } from 'ngx-socket-io';
import { Installment, PaymentTransaction, PaymentType } from './payment.interface';


@Component({
  selector: 'app-modal-payment',
  templateUrl: './modal-payment.component.html',
  styleUrls: ['./modal-payment.component.scss']
})
export class ModalPaymentComponent extends Views implements OnInit {

  patient: IPatientResponse;
  url: string = 'https://motor-payment-homolog-1.maistodos.com.br/payment-link/';
  loading = false;
  isAmorSaude = false;
  loadingInstallments = false;
  settingInstallment = false;
  useSameCard = true;
  loadingPaymentType = false;
  paymentType: PaymentType = {
    credit: true,
    pix: true
  }
  hasCardToken = { cardNumber: null, flag: null };
  onlinePaymentConfig = { installments: null }
  amorSaudeId: number;

  paymentMethod: string = '';
  price: string = '';
  cardFlag: string = '';
  local = {
    name: ''
  };
  installments: Installment[] =[];

  campos = {
    name: [Validators.required],
    cardNumber: [Validators.required],
    dueDate: [Validators.required],
    cvv: [Validators.required],
    installment:[Validators.required]
  };

  hasPaymentToken: boolean

  pixCode = new FormControl('');
  pixQRCodeValue = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalPaymentComponent>,
    public dialog: MatDialog,
    public snack: MatSnackBar,
    private amorSaudeService: AmorSaudeService,
    private loginService: LoginService,
    private paymentService: PaymentService,
    private router: Router,
    private socket: Socket,
  ) {
    super();
    this.hasPaymentToken = data.hasOwnProperty('token');

    this.loadingPaymentType = true;
    paymentService.getPaymentTypeAccepted(this.account.accountId).subscribe({
      next: (res: PaymentType)=>{
        this.paymentType = res
        this.loadingPaymentType = false;
      },
      error: err =>{
        this.loadingPaymentType = false;
        this.snack.open('Erro ao buscar tipos de pagamentos aceitos', 'ok', {duration: 3000});
        dialogRef.close();
      }
    })

    if(!this.hasPaymentToken){
      this.patient = LocalStorageUtil.getPatientInfo();
      this.isAmorSaude = loginService.isAmorSaude();
    }else
    this.pixCode.disable();
   }

  ngOnInit(): void {
    if(this.isAmorSaude)
      this.loadLink();
    else
      this.loadPayment();
  }


  onChangePaymentMethod(){
    if(this.paymentMethod === 'pix' && this.pixCode.value === ''){
      this.loading = true;
      this.pay();
    }
    
  }

  async loadLink(){
    this.loading = true;

    const body = {} as GenerateLinkBody;
    body.patientId = this.patient.id;
    if(this.data.campaignId)
      body.campaignId = this.data.campaignId;
    else{ 
      body.scheduleId = this.data.scheduleId;
      if(!this.data.search){
        const data = await this.amorSaudeService.getScheduleProcedureValue(this.data.scheduleId).then(res => res)
        body.amount = parseFloat(data.price);
        body.voucherId = data.voucherId;
      }else{
        body.amount = parseFloat(this.data.search.price);
        body.voucherId = this.data.search.voucherId;
      }
    }

    this.amorSaudeService.getLink(body).subscribe(
      (res: AmorSaudeLinkResponse)=>{
        this.url = res.url;
        this.amorSaudeId = res.amorSaudeId;
        this.loading = false;
      },
      err=>{
        this.dialogRef.close();
        this.snack.open('Erro ao gerar link de pagamento, tente novamente', 'ok' ,{panelClass: 'amorsaude'});
        this.loading = false;
      }
    )
  }

  async loadPayment(){
    this.loading = true;
    const patientId = this.patient? this.patient.id : this.data.patientId;
    const generatePaymentParams = {} as GeneratePaymentParams;
    if('scheduleId' in this.data){
      generatePaymentParams.scheduleId = this.data.scheduleId;

      this.socket.emit('join-payment', {
        scheduleId: this.data.scheduleId,
      });
  
      this.socket.on('payment-update',(res)=>{
        this.updatePayment(res);
      })
    }
    
      
    if('campaignId' in this.data){
      generatePaymentParams.campaignId = this.data.campaignId;

      this.socket.emit('join-campaign-payment', {
        campaignPatientId: this.data.campaignId + '#' + patientId,
      });
  
      this.socket.on('campaign-payment-update',(res)=>{
        this.updatePayment(res);
      })
    }

    if(this.data.search?.voucherId){
      generatePaymentParams.voucherId = this.data.search.voucherId;
      generatePaymentParams.amount = this.data.search.price;
    }

    generatePaymentParams.patientId = patientId;

      if(!this.hasPaymentToken && !this.data.linkedPayment)
        forkJoin({
          generatePayment: this.paymentService.generatePayment(generatePaymentParams),
          hasCardToken: this.paymentService.hasCardToken(patientId)
        }).subscribe(res=>{
          this.hasCardToken = res.hasCardToken;
        },
        err =>{
          this.snack.open('Erro ao gerar registro de pagamento', 'ok' ,{duration: 3000});
          this.dialogRef.close();
        })

      this.loading = false;
      this.initForm(this.fb);
      this.fg.controls.installment.disable();

  }

  updatePayment(res: any){
    if(res.status === 'paid'){
      this.snack.open('Pagamento confirmado', 'ok', {duration: 3000});
      this.dialogRef.close({payed: true});
    }else if(res.status === 'pending'){
      this.snack.open('Aguardando aprovação de pagamento', 'ok', {duration: 4000});
    }else
      this.snack.open('Pagamento não efetuado', 'ok', {duration: 3000});
  }

  onlyNumbers(e:KeyboardEvent){
    const k = e.charCode;
    // this.setSpaceCreditCard();
    return k >= 48 && k <= 57;
  }

  async validateCardFlag(e:any){
    const cardNumber = e.target.value.replace(/\D/g, "")
    const isValidaCard = await this.paymentService.luhnValidation(cardNumber);

    if(!isValidaCard){
      this.fg.controls.cardNumber.setErrors({ 'invalid': true})
      this.fg.controls.installment.reset();
      this.fg.controls.installment.disable();
      return;
    }

    const params = {} as any;

    this.cardFlag = await this.paymentService.getCardFlag(cardNumber);

    if('scheduleId' in this.data){
      params.scheduleId = this.data.scheduleId;
      params.value = this.data.search.price;
    }

    if('campaignId' in this.data){
      params.campaignId = this.data.campaignId;
      params.value = this.data.amount;
    }

    params.cardFlag = this.cardFlag;

    this.loadingInstallments = true;
    this.paymentService.generateInstallments(params).subscribe({
      next: res =>{
        if(!(!!res.allowedFlag)){
          this.fg.controls.cardNumber.setErrors({ 'invalidFlag' : true });
          this.loadingInstallments = false;
          return;
        }

        this.installments = res.installments;
        if(res.installments.length === 1)
          this.fg.controls.installment.setValue(res.installments[0])

        this.loadingInstallments = false;
        this.fg.enable()
      },
      error: err =>{
        this.loadingInstallments = false;
        this.snack.open('Erro ao carregar tipos de parcelamentos, tente novamente', 'ok', { duration: 5000 });
      }
    })
  }

  toUseSameCard(useCard: boolean){

    if(useCard){
      if(this.fg.getRawValue().installment === null){
        const params = {
          cardFlag: this.hasCardToken.flag,
          value: this.data.search.price, 
          scheduleId: this.data.scheduleId
        }

        this.cardFlag = params.cardFlag;
        this.loadingInstallments = true;
        this.paymentService.generateInstallments(params).subscribe({
          next: res =>{
            if(!(!!res.allowedFlag)){
              this.snack.open(`O cartão do tipo ${params.cardFlag} não é mais aceito, tente um novo`, 'ok', { duration: 5000 });
              this.loadingInstallments = false;
              this.hasCardToken.cardNumber = null;
              return;
            }
    
            this.installments = res.installments;
            if(res.installments.length === 1)
              this.fg.controls.installment.setValue(res.installments[0]);
            
            this.settingInstallment = true;
            this.loadingInstallments = false;
            this.fg.enable()
          },
          error: err =>{
            this.loadingInstallments = false;
            this.snack.open('Erro ao carregar tipos de parcelamentos, tente novamente', 'ok', { duration: 5000 });
          }
        });

        return;
      }

      this.loading = true;
      const params = {
        payment_type: this.paymentMethod,
        amount: this.data.search?.price || this.price,
        scheduleId: this.data.scheduleId,
        local: this.data.search?.local.name || this.local.name,
        installments: this.fg.getRawValue().installment.quantity,
        cardFlag: this.cardFlag
      }

      this.paymentService.createTransaction(params, false)
      .toPromise()
      .then(res=>{

        if(res?.status === 400){
          this.router.navigate(['/agendamentos']);
          this.dialogRef.close();
          this.snack.open(res?.message || 'Erro ao finalizar pagamento', 'ok', { duration: 3000 });
          this.loading = false;
        }

        if(res?.message ==='Incomplete address'){
          const dialogAddress = this.dialog.open(CompleteAddressComponent,{
            disableClose: true,
            data:{
              scheduleId: this.data.scheduleId
            }
          });

          dialogAddress.afterClosed().subscribe(res =>{
            if(res.success)
              this.toUseSameCard(useCard);
            else{
                
              dialogAddress.close();
              this.loading = false;
              return;
            }
          })
        }else{
          this.dialogRef.close({payed: true})
          this.loading = false;
        }

      })
      .catch(err=>{
        this.router.navigate(['/agendamentos']);
        this.dialogRef.close();
        this.snack.open('Erro ao finalizar pagamento', 'ok', { duration: 3000 });
        this.loading = false;
      });

    }else{
      this.hasCardToken.cardNumber = null;
      return;
    } 
  }

  async setSpaceCreditCard(e:InputEvent){
    let cardNumber = this.fg.controls.cardNumber.value;
    cardNumber = cardNumber.replace(/\D/g, "");
    this.fg.controls.cardNumber.setValue(cardNumber.match(/\d{1,4}/g).join(' '));
  }

  setDivisionDueDate(e:InputEvent){
    const dueDate = this.fg.controls.dueDate.value;
    if(e.data === null){
      if(dueDate[dueDate.length - 1] === '/')
        this.fg.controls.dueDate.setValue(dueDate.replace('/', ''))
      
      return;
    }

    if(dueDate.length === 2){
      this.fg.controls.dueDate.setValue(dueDate + '/');
    }else if(dueDate.length === 3 && dueDate[2] !== '/'){
      const dividedDueDate = dueDate.slice(0, 2);
      this.fg.controls.dueDate.setValue(dividedDueDate + '/' + dueDate[2]);
    }
  }

  async pay(){
    const params = {} as PaymentTransaction;
    params.payment_type = this.paymentMethod;
    if('scheduleId' in this.data){
      params.scheduleId = this.data.scheduleId;
      params.local = this.data.search?.local.name || this.local.name;
      params.amount = this.data.search?.price || this.price;
    }
      
    if('campaignId' in this.data){
      params.campaignId = this.data.campaignId;
      params.amount = this.data.amount;
    }


    params.linkedPayment = this.hasPaymentToken;
    params.installments = this.paymentMethod === 'pix'? 1 : (this.fg.controls.installment.value?.quantity || 1);
    if(this.hasPaymentToken) params.accountId = this.data.accountId;

    if(this.paymentMethod === 'credit'){
      if(this.fg.invalid){
        this.snack.open('Preencha todos os campos corretamente', 'ok', { duration: 3000 });
        this.fg.markAllAsTouched();
        return;
      }
  
      const dueDateSplited = this.fg.value.dueDate.split('/');
  
      if( dueDateSplited[0] > 12 || dueDateSplited[1] < 22){
        this.snack.open('Vencimento errado', 'ok', { duration: 3000 });
        return;
      }

      params.card_info = {
        holder_name: this.fg.value.name,
        expiration_month: dueDateSplited[0],
        expiration_year: '20' + dueDateSplited[1],
        card_number: this.fg.controls.cardNumber.value.replace(/\s+/g, ''),
        security_code: this.fg.value.cvv,
        cardFlag: this.cardFlag
      }
      
    }

    this.loading = true;
    let exec: any;

    this.paymentService.createTransaction(params, this.data.linkedPayment).toPromise()
      .then(res=>{
  
        if(res?.message ==='Incomplete address'){
          const dialog = this.dialog.open(CompleteAddressComponent,{
            disableClose: true,
            data:{
              scheduleId: this.data.scheduleId
            }
          });

          dialog.afterClosed().subscribe(res =>{
            if(res.success)
              this.pay();
            else{
              if(this.paymentMethod === 'pix'){
                this.paymentMethod = '';
                this.pixCode.setValue('');
              }
                
              dialog.close();
              this.loading = false;
              return;
            }
          })
        }else if(res?.status === 403){
          this.snack.open(res.message, 'ok', { duration: 4000 })
          const dialog = this.dialog.open(CompleteAddressComponent,{
            disableClose: true,
            data:{
              scheduleId: this.data.scheduleId,
              patientData: res.data
            }
          });

          dialog.afterClosed().subscribe(res =>{
            if(res.success)
              this.pay();
            else{
              if(this.paymentMethod === 'pix'){
                this.paymentMethod = '';
                this.pixCode.setValue('');
              }
                
              dialog.close();
              this.loading = false;
              return;
            }
          })
        }else if(res?.status === 400){
          if(res.message === 'Parâmentros inválidos'){
            this.snack.open('Houve um problema com seus dados, por favor, entre em contato com a clínica para solucionar o problema', 'ok', { duration: 8000 });
          }else if(this.paymentMethod === 'credit')
            this.snack.open('Houve um problema para finalizar o pagamento, tente novamento ou use outro cartão', 'ok', { duration: 5000 });
          else
            this.snack.open('Erro ao gerar código pix', 'ok', { duration: 3000 });
          
          if(!this.hasPaymentToken){
            this.dialogRef.close(); 
            this.router.navigate(['/agendamentos']);
          }
          this.loading = false;
          
        }else if(res?.status === 409){
          this.snack.open('Tempo de reserva alcançado, token invalidado. Recarregando...', 'ok', { duration: 4000 });
          setTimeout(()=> window.location.reload(), 2000);
        }else{
          if(this.paymentMethod === 'credit')
            this.dialogRef.close({payed: true});
          else
            this.pixCode.setValue(res.code);
            this.pixQRCodeValue = res.code;
  
          this.loading = false;
        }

      })
      .catch(err=>{

        if(err.error.message === 'Parâmentros inválidos'){
          this.snack.open('Houve um problema com seus dados, por favor, entre em contato com a clínica para solucionar o problema', 'ok', { duration: 8000 });
        }else if(this.paymentMethod === 'credit')
          this.snack.open('Houve um problema para finalizar o pagamento, tente novamento ou use outro cartão', 'ok', { duration: 5000 });
        else
          this.snack.open('Erro ao gerar código pix', 'ok', { duration: 3000 });
        
        if(!this.hasPaymentToken){
          this.dialogRef.close(); 
          this.router.navigate(['/agendamentos']);
        }
        this.loading = false;
      });
  }

  conclude(){
    let result = {}
    
    if(this.amorSaudeId)
      result = {amorSaudeId: this.amorSaudeId}

    this.dialogRef.close(result)
  }

  copyCodeToClipboard(){
    return new Promise(res =>{
      const textarea = document.createElement('textarea');
      textarea.style.position = 'fixed';
      textarea.style.left = '0';
      textarea.style.top = '0';
      textarea.style.opacity = '0';
      textarea.value = this.pixCode.value;
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.snack.open(
        'Código copiado', 
        'ok', 
        { 
          duration: 2000 
        }
      );
      res(true);
    });
  }

  showUsageRules(){
    const dialogRef = this.dialog.open(UsageRulesComponent,{
      disableClose: true,
      panelClass: 'paymentModal'
    });

    dialogRef.afterClosed().subscribe(()=>{})
  }

}
