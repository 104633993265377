<div class="loginCard" align="center">
  <mat-card>
    <p>{{ msg }}</p>

    <mat-spinner [diameter]="30"
      color="primary"
      *ngIf="loading">
    </mat-spinner>

    <a *ngIf="!loading" routerLink="/login">
      Ir para tela de login
    </a>
  </mat-card>

  <app-footer></app-footer>
</div>
