import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { Observable } from 'rxjs';
import { IAgendamentoHorarioResponse } from '../pages/agendamentos/agendamentos.interfaces';
import {
  IExpertise,
  ILocal,
  IProcedure,
  IOccupation,
  IModalidades,
} from '../shared/partials/agendamento/inputs/inputs.component.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private apiService: ApiServiceService) {}

  public getModalidades(planIds: string, professionalId: number = null, isSchedulersView: boolean = false, patientId: number = null): Observable<IModalidades[]> {
    return this.apiService.get('/company/products', { planIds, professionalId, isSchedulersView, patientId });
  }

  public getModalidadesConta(accountId: number, professionalId: number = null): Observable<IModalidades[]> {
    return this.apiService.get('/company/account-products', { accountId, professionalId });
  }

  public getOccupation(
    procedureId: number, 
    professionalId: number = null, 
    isSchedulersView: boolean = false,
    patientId: number = null
  ): Observable<IOccupation[]> {
    return this.apiService.get('/company/occupation', { 
      procedureId, 
      professionalId, 
      isSchedulersView,
      patientId 
    });
  }

  public getAccountOccupation(accountId: number, procedureId: number,professionalId: number = null): Observable<IOccupation[]> {
    return this.apiService.get('/company/account-occupation', { accountId, procedureId, professionalId });
  }

  public getExpertise(
    professionalId: number,
    isParticular: boolean,
    isSchedulersView: boolean = false
  ): Observable<IExpertise[]> {
    return this.apiService.get('/company/expertise', {
      professionalId,
      isParticular,
      isSchedulersView
    });
  }

  public getProcedure(
    expertiseId?: string | null,
    occupationId?: number,
    healthPlanId?: number,
    professionalId?: number,
    isSchedulersView: boolean = false,
    patientId: number = null
  ): Observable<IProcedure[]> {
    return this.apiService.get(
      '/company/procedure',
      expertiseId
        ? { expertiseId, healthPlanId, professionalId, isSchedulersView, patientId }
        : { occupationId, healthPlanId, professionalId, isSchedulersView, patientId },
    );
  }

  public getAccountProcedure(
    accountId?: number,
    expertiseId?: string,
    occupationId?: number,
    healthPlanId?: number,
    professionalId?: number
  ): Observable<IProcedure[]> {
    return this.apiService.get(
      '/company/account-procedure',
      expertiseId
        ? { accountId, expertiseId, healthPlanId, professionalId }
        : { accountId, occupationId, healthPlanId, professionalId },
    );
  }

  public getLocal(
    expertiseId?: string,
    occupationId?: number,
    procedureId?: number,
    professionalId?: number,
    isSchedulersView: boolean = false
  ): Observable<ILocal[]> {
    return this.apiService.get('/company/local', {
      expertiseId,
      occupationId,
      procedureId,
      professionalId,
      isSchedulersView
    });
  }

  public getAccountLocal(
    accountId?: number,
    expertiseId?: string,
    occupationId?: number,
    procedureId?: number,
    professionalId?: number
  ): Observable<ILocal[]> {
    return this.apiService.get('/company/account-local', {
      accountId, 
      expertiseId,
      occupationId,
      procedureId,
      professionalId
    });
  }

  public getAvailability(
    procedureId: number,
    occupationId: number,
    expertiseId: string,
    clinicId: number,
    date: string,
    userId?: number,
    crp?: string,
    isSchedulersView?: boolean,
  ): Observable<IAgendamentoHorarioResponse[]> {
    return this.apiService.get('/company/availability', {
      procedureId,
      occupationId,
      expertiseId,
      clinicId,
      date,
      userId,
      crp,
      isSchedulersView
    });
  }

  public async hasAgendaOnlineProcedure(isSchedulersView: boolean = false): Promise<boolean> {
    return await this.apiService
      .get('/company/hasAgendaOnlineProcedure', {isSchedulersView})
      .toPromise();
  }

  public getProfessionalInfo(professionalId: string, accountId: number){
    return this.apiService.get(`/company/professional-info/${professionalId}/${accountId}`)
  }

  public checkProfessionalOnlineConfig(professionalId: number, accountId: number){
    return this.apiService.get(`/company/professional-online-config/${professionalId}/${accountId}`)
  }
}
