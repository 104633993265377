import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageUtil } from '../util/local-storage-util';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceService {
  constructor(private http: HttpClient) {}

  public customHeaders: object;

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders({});

    if (this.customHeaders) {
      // tslint:disable-next-line: forin
      for (const key in this.customHeaders) {
        headers = headers.append(key, this.customHeaders[key]);
      }
    }

    return headers;
  }

  public setCustomHeaders(header: object) {
    this.customHeaders = header;
  }

  private formatErrors(error: any) {
    return throwError(error);
  }

  get(
    path: string | number | any,
    params?: HttpParams | object,
    options?: {
      api?: string;
      responseType?: any;
    },
  ): Observable<any> {
    const api = options && options.api ? options.api : environment.api;
    const endpoint = path.startsWith('http') ? `${path}` : `${api}${path}`;

    let queryData: any = {
      headers: this.getHeaders(),
      responseType: options ? options.responseType : 'json',
    };

    if (params) queryData = { ...queryData, params };

    return this.http.get(`${endpoint}`, queryData).pipe(
      map((response) => response),
      catchError(this.formatErrors),
    );
  }

  post(
    path: string,
    body: object = {},
    options?: {
      api: string;
    },
  ): Observable<any> {
    const api = options ? options.api : environment.api;
    const endpoint = path.startsWith('http') ? `${path}` : `${api}${path}`;

    return this.http
      .post(`${endpoint}`, body, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response) => response),
        catchError(this.formatErrors),
      );
  }

  postPromise(
    path: string,
    body: object = {},
    options?: {
      api: string;
    },
  ): Promise<any> {
    const api = options ? options.api : environment.api;
    const endpoint = path.startsWith('http') ? `${path}` : `${api}${path}`;

    return this.http
      .post(`${endpoint}`, body, { headers: this.getHeaders() })
      .toPromise()
      .then((response) => response)
      .catch((err) => this.formatErrors(err));
  }

  patch(
    path: string | number | any,
    body: object = {},
    options?: {
      api: string;
    },
  ): Observable<any> {
    const api = options ? options.api : environment.api;
    const endpoint = path.startsWith('http') ? `${path}` : `${api}${path}`;
    return this.http
      .patch(`${endpoint}`, JSON.stringify(body), {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.formatErrors));
  }

  put(
    path: string | number | any,
    body: object = {},
    options?: {
      api: string;
    },
  ): Observable<any> {
    const api = options ? options.api : environment.api;
    const endpoint = path.startsWith('http') ? `${path}` : `${api}${path}`;
    return this.http
      .put(`${endpoint}`, JSON.stringify(body), {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.formatErrors));
  }

  delete(
    path: string | number | any,
    params?: HttpParams | object,
    paramType: string = 'query',
    options?: {
      api: string;
    },
  ): Observable<any> {
    const api = options ? options.api : environment.api;
    const endpoint = path.startsWith('http') ? `${path}` : `${api}${path}`;

    let queryData: any = {
      headers: this.getHeaders(),
    };

    if (params) queryData = { ...queryData, params };

    return this.http
      .delete(`${endpoint}`, queryData)
      .pipe(catchError(this.formatErrors));
  }

  async deleteWithBody(
    path: string | number | any,
    body: object,
    options?: {
      api: string;
    },
  ): Promise<any> {
    // O Angular não faz request 'delete' com body
    const api = options ? options.api : environment.api;
    const endpoint = path.startsWith('http') ? `${path}` : `${api}${path}`;

    return await fetch(`${endpoint}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${LocalStorageUtil.getToken()}`,
      },
      body: JSON.stringify(body),
    }).then((res) => res);
  }
}
