<div>
    <button
        mat-button
        class="btn-primary"
        matTooltip="Copiar link"
        matTooltipPosition="above"
        (click)="generatePaymentLink()"
        [disabled]="loadingShare"
    >
        <mat-spinner
        *ngIf="loadingShare"
        class="branco"
        [diameter]="20"
        ></mat-spinner>
        <fa-icon *ngIf="!loadingShare" icon="copy"></fa-icon> Copiar link de pagamento
    </button>
</div>
