import { Component, Input } from '@angular/core';
import { IAgendamento } from 'src/app/pages/agendamentos/agendamentos.interfaces';

@Component({
  selector: 'app-details-horario',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent {
  @Input() search: any;
  @Input() isAmorSaude: any = false;
}
