<div class="snackbarTemplate">
  <span align="center">
    Acesse seu e-mail {{ data.email }} para ativar seu cadastro! Não recebeu?
    clique
    <b (click)="snackBarRef.closeWithAction()" class="link">
      aqui
    </b>
    para reenviarmos
  </span>

  <span (click)="snackBarRef.dismissWithAction()" class="closeButton">
    x
  </span>
</div>
