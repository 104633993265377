import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgendamentosService } from 'src/app/services/agendamentos.service';
import { LoginService } from 'src/app/services/login.service';
import { PacienteService } from 'src/app/services/paciente.service';
import { SlackService } from 'src/app/services/slack.service';
import { COMMUNICARE_API_MESSAGE } from 'src/app/util/constants';
import { UploadFile } from './meus-arquivos.interface';

@Component({
  selector: 'app-meus-arquivos',
  templateUrl: './meus-arquivos.component.html',
  styleUrls: ['./meus-arquivos.component.scss']
})
export class MeusArquivosComponent implements OnInit {
  public editorInstance;
  public fileList: UploadFile[] = [];
  public loading: boolean = false;
  public isAmorSaude: boolean = false;
  public className = ''
  
  constructor(
    private patientService: PacienteService,
    private slackService: SlackService,
    private loginService: LoginService,
    private agendamentosService: AgendamentosService,
    private snack: MatSnackBar
  ) { 
    this.isAmorSaude = this.loginService.isAmorSaude();
    this.className = this.isAmorSaude? 'amorsaude' : '';

  }

  ngOnInit(): void {
    this.getUploadedFiles();
  }

  getUploadedFiles(){
    this.loading = true;
    this.patientService.getUploadedFiles().subscribe(
      (res: UploadFile[]) =>{
        this.fileList = res;
        this.loading = false;
      },
      err =>{
        this.slackService.slackTest(err);
        this.snack.open(
          'Erro ao carregar anexos',
          'Ok',
          {
            duration: 5000,
            panelClass: this.className 
          }
        );
        this.loading = false;
      }
    )
  }

  
  onClickUpload(event) {
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('file', file, file.name);

      this.patientService.uploadFile(formData).subscribe(
        (r) => {
          this.snack.open( 'Arquivo enviado com sucesso', 'Ok',{ duration: 5000, panelClass: this.className  } );
          this.getUploadedFiles()
        },
        (err) => {
          this.slackService.slackTest(err);
          this.snack.open(
            'Erro ao realizar upload! Tente novamente, por favor.',
            'Ok',
            {
              duration: 5000, 
              panelClass: this.className 
            }
          );
        }
      );
    }
  }

  addTag(file: any) {
    const link = COMMUNICARE_API_MESSAGE + '/download/patient-attachment/' + file.name;

    return link;

  }

}
