<div
  [className]="
    'card-item with-shadow rounded card-item-' + (item.status | lowercase)
  "
>
  <div class="card-item-left">
    <img
      *ngIf="item.professionalPhoto"
      width="90"
      height="90"
      [src]="item.professionalPhoto"
      [alt]="item.professionalName"
      (error)="photoError(item)"
    />
    <app-avatar
      *ngIf="!item.professionalPhoto"
      class="card-item-avatar"
      [info]="item.professionalName"
      [size]="'large'"
    ></app-avatar>
  </div>

  <div class="card-item-content">
    <div class="card-item-content-column">
      <div class="card-item-content-column-info">
        <h2 class="purple">
          {{ item.fullDate | date: 'dddd, DD/MM/YYYY [às] HH:mm':'':true }} (horário de Brasília)
        </h2>
        <h3>
          {{ item.clinicName }}
        </h3>

        <a
          [href]="item.clinicAddressWebsite"
          *ngIf="item.procedureTeleconsultation"
          class="p-margin-bottom-1"
          target="_blank"
        >
          {{ item.clinicAddressWebsite }}
        </a>

        <address *ngIf="!item.procedureTeleconsultation">
          <span>{{ item.clinicAddress }}</span
          >{{ item.clinicAddressNumber | concat: ', ':true:true }}
          <br class="hidden-tablet-and-above" />

          <span class="visible-tablet-and-above"></span>
          {{ item.clinicAddressNeighbor }}
          {{ item.clinicAddressCity | concat: '- ':true:true
          }}<span *ngIf="item.clinicAddressCity">{{
            item.clinicAddressUf | concat: '/':true:true
          }}</span>
          <a
            *ngIf="!!getMapView(item)"
            [href]="getMapView(item)"
            target="_blank"
          >
            Ver no mapa
          </a>
        </address>

        <p>{{ item.procedureNickname }}</p>
      </div>
      <div class="card-item-content-column-icon">
        <fa-icon size="lg" [icon]="getStatusIcon(item.status)" class="purple">
        </fa-icon>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="p-display-flex">
      <div class="card-item-content-column-info">
        <h4>
          {{ item.professionalTitle }} {{ item.professionalName }}
          <small *ngIf="item.professionalOccupation">
            - {{ item.professionalOccupation }}
          </small>
        </h4>
        <h4>
          <small *ngIf="item.professionalExpertise">
            {{ item.professionalDocumentType }}/{{
              item.professionalDocumentUf
            }}
            {{ item.professionalDocument }}
          </small>
        </h4>
        <small *ngIf="item.rqe" class="card-item-content-column-info-details">
          <div *ngFor="let rqe of item.rqe.split('|'); let i = index">
            {{ item.professionalExpertise.split('|')[i] }}
            {{ rqe !== ' ' ? 'RQE ' + rqe : '' }}
          </div>
        </small>
      </div>
    </div>
  </div>
  <div class="p-display-flex right">
    <div
      class="card-item-bottom"
      *ngIf="item.status === 'AGENDADO' && itemType !== 'Histórico'"
    >
        <div
          *ngIf=" item.statusPayment && item.statusPayment == 'on_bill_paid'"
          class="paymentStatus"
        >
          <span class="statusPaymentTxt" *ngIf="!deleting && (item.statusPayment == 'pending' || item.statusPayment === 'create_payment')">
            Pendente de pagamento
          </span>
        </div>
        <span class="usageRules" *ngIf="!deleting && item.statusPayment == 'on_bill_paid'">
          <button mat-button (click)="showPaymentDetails(item.agendaId)">Informações do pagamento</button>
        </span>
        <span class="usageRules" *ngIf="!isAmorSaude && item.statusPayment">
          <button mat-button (click)="showUsageRules()">Regras de utilização</button>
        </span>
      <a
        class="btn btn-danger btn-small btn-secondary"
        (click)="JoinVideoCall(item.linkTeleconsulta)"
        *ngIf="item.linkTeleconsulta"
      >
        <span class="p-margin-right-1" *ngIf="!deleting">
          Teleconsulta
        </span>
        <fa-icon size="sm" icon="video"></fa-icon>
      </a>
      <a
        href="#"
        class="btn btn-danger btn-small btn-danger-lined"
        (click)="cancelarAgendamento($event, item.agendaId, item.professionalId)"
      >
        <span class="p-margin-right-1" *ngIf="!deleting">
          Desmarcar
        </span>
        <app-loading
          *ngIf="deleting"
          [scale]="0.5"
          [type]="'danger'"
        ></app-loading>
        <fa-icon *ngIf="!deleting" size="sm" icon="trash-alt"></fa-icon>
      </a>
      <button
        class="btn btn-addCalendar btn-small btn-secondary"
        id="{{'addToCalendar-' + item.agendaId}}"
        (click)="onAddToCalendar(item)"
        >
        <mat-spinner *ngIf="addingToCalendar" [diameter]="25" color="primary"></mat-spinner>
        <span *ngIf="!addingToCalendar" >
          Adicionar na agenda
          <fa-icon [icon]="['fas', 'calendar-plus']" class="icon"></fa-icon>
        </span>
      </button>
    </div>
    <div
      class="card-item-bottom"
      *ngIf="item.status === 'PRÉ-AGENDADO' && itemType !== 'Histórico'"
    >
        <div class="timer-card-item">
          <span class="statusPaymentTxt" *ngIf="!deleting">
            Tempo restante <span [ngClass]=" redTimer? 'redFontTimer': '' ">{{timerToShow}}</span>
          </span>
        </div>
        <div
          [ngClass]="
            item.statusPayment == 'pending' || item.statusPayment === 'create_payment'? 'paymentStatus pendent' : 'paymentStatus'
          "
        >
          <span class="statusPaymentTxt" *ngIf="!deleting && item.statusPayment == 'pending' || item.statusPayment === 'create_payment'">
            Pendente de pagamento
          </span>
          <span class="usageRules" *ngIf="!deleting && item.statusPayment == 'on_bill_paid'">
            <button mat-button (click)="showPaymentDetails(item.agendaId)">Informações do pagamento</button>
          </span>
        </div>
        <button
          *ngIf="
            item.status === 'PRÉ-AGENDADO' &&
            (item.statusPayment == 'pending' || item.statusPayment === 'create_payment')
        "
          mat-button
          [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary btnPayment' : 'btn-secondary btnPayment'"
          (click)="onPayment(item)"
        >
          Pagar
        </button>
      </div>
  </div>
  <div>
  </div>
</div>
