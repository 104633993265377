import { IPatientResponse } from './../pages/perfil/perfil.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceService } from './api-service.service';
import { ISchedulerResponse } from '../pages/perfil/scheduler.interface';

@Injectable({
  providedIn: 'root',
})
export class PerfilService {
  constructor(private apiService: ApiServiceService) {}

  public updatePatient(params: IPatientResponse): Observable<IPatientResponse> {
    return this.apiService.put(`/registration`, params);
  }

  public updateSchedulers(params: ISchedulerResponse): Observable<ISchedulerResponse> {
    return this.apiService.put(`/registration/schedulers`, params);
  }

  public deleteHealthPlan(
    patientId: number,
    healthPlanId: number,
  ): Observable<any> {
    return this.apiService.delete(
      `/registration/${patientId}/health-plans/${healthPlanId}`,
    );
  }
}
