<span class="header">
    <h1 mat-dialog-title>{{data.campaign.title}}</h1>
    <button mat-button mat-dialog-close class="closeModal">
        <fa-icon icon="times"></fa-icon>
    </button>
</span>
<div mat-dialog-content>
    <p>Procedimentos da campanha:</p>
    <span *ngFor="let procedure of data.campaign.compaignProceduresVouchers">
        <ul>
            <li>{{procedure.nickname}}</li>
        </ul>
    </span>

</div>
