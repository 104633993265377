import * as moment from 'moment';
import * as _ from 'lodash';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';

export class HorariosHelper {
  private DEFAULT_DURATION_TIME = 30; // Minutes
  private MINIMUM_PRECEDENCE_SCHEDULE_TIME = LocalStorageUtil.getAccountInfo().MinimumPrecedenceScheduleTime || 1; // hora

  public format(data, date) {
    const dateFromUrl = window.location.href.split('now=')[1];
    const now = dateFromUrl ? moment(parseInt(dateFromUrl)) : moment();
    const nowPrecedence = now.add(
      this.MINIMUM_PRECEDENCE_SCHEDULE_TIME,
      'hours',
    );
    const values: any = _.filter(data, (item) =>
      this.getAvailable(item, date, nowPrecedence),
    );

    return {
      date: date,
      values: _.filter(values, (val) => val.availableTimes.length),
    };
  }

  private getAvailable(item, date, now) {
    const available = _.flatten(
      _.map(item.availableTimes, (target) => this.getRange(target, date, now)),
    );

    if (available.length) {
      const busy = item.busyTimes;
      item.date = date;
      item.availableTimes = this.getResponse(
        this.findOccurences(available, busy),
      );
      return item;
    }
  }

  private getResponse(available) {
    return _.map(available, (av) => {
      delete av.hash;
      return av;
    });
  }

  private findOccurences(availables, busies) {
    if(busies.length == 0) {
      return availables
    }

    return _.filter(availables, (available) => {
      const ocupados = _.filter(busies, (busy) => {
        if(busy.date) {
          const bloqueioInicio = moment(busy.startTime + busy.date, 'hhmmDD/MM/YYYY');
          const bloqueioFim = moment(busy.endTime + busy.date, 'hhmmDD/MM/YYYY');
          return moment(available.hash, 'hh:mm YYYY-MM-DD').isBetween(bloqueioInicio, bloqueioFim, undefined, '[)');
        } else {
          const bloqueioInicio = moment(busy.startTime + busy.startDate, 'hhmmDD/MM/YYYY');
          const bloqueioFim = moment(busy.endTime + busy.endDate, 'hhmmDD/MM/YYYY');
          return moment(available.hash, 'hh:mm YYYY-MM-DD').isBetween(bloqueioInicio, bloqueioFim, undefined, '[)');
        }
      });
      return ocupados.length == 0;
    });
  }

  private getRange(item, date, now: moment.Moment) {
    const startDate = this.getDate(item, date, 'start');
    const endDate = this.getDate(item, date, 'end');
    let current = moment(startDate, 'HH:mm YYYY-MM-DD').format(
      this.baseFormat(),
    );

    const duration = item.duration || this.DEFAULT_DURATION_TIME;
    const resultado = [];
    let dateFound;
    let slots = 0;

    while (!this.maxTimeRange(current, endDate, duration, slots)) {
      dateFound = moment(current, 'HH:mm YYYY-MM-DD');

      if (now.isBefore(dateFound)) {
        this.pushTimeRange(resultado, item, date, current);
      }
      slots++;
      current = this.addTimeRange(current, duration);
    }
    return resultado;
  }

  private pushTimeRange(
    resultado: any[],
    item: any,
    date: any,
    current: string,
  ) {
    resultado.push({
      id: item.id,
      startDate: date,
      hash: moment(current, 'HH:mm YYYY-MM-DD').format(this.baseFormat()),
      startTime: moment(current, 'HH:mm YYYY-MM-DD').format('HHmm'),
      sameTime: item.sameTime
    });
  }

  private maxTimeRange(current, endDate, duration, slots) {
    if (slots > (24 * 60) / duration) {
      throw 'Infinite call';
    }
    const _current = moment(current, 'HH:mm YYYY-MM-DD');
    const _endDate = moment(endDate, 'HH:mm YYYY-MM-DD');
    return _current.isSameOrAfter(_endDate);
  }

  private getDate(item, date, type) {
    return moment(date, 'DDMMYYYY')
      .add(this.hours(item[type + 'Time']), 'hours')
      .add(this.minutes(item[type + 'Time']), 'minutes')
      .format(this.baseFormat());
  }

  private hours(item) {
    return item.toString().slice(0, -2);
  }

  private minutes(item) {
    return item.toString().slice(-2);
  }

  private addTimeRange(date, duration) {
    return moment(date, 'HH:mm YYYY-MM-DD')
      .add(duration || this.DEFAULT_DURATION_TIME, 'minutes')
      .format(this.baseFormat());
  }

  private baseFormat() {
    return 'HH:mm YYYY-MM-DD';
  }
}
