import {
  iConfirmEmail,
  iForgotPassword,
  iChangePassword,
  CheckDocumentResponse,
} from './../pages/cadastro/cadastro.interfaces';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  ICadastroRequest,
  IHealthPlanResponse,
} from '../pages/cadastro/cadastro.interfaces';
import { ApiServiceService } from './api-service.service';
import { AccountInfo } from '../util/local-storage-util';
import { HttpClient } from '@angular/common/http';
import { IAddress, MissingInfo } from '../pages/perfil/perfil.interface';
import { ISchedulerResponse } from '../pages/perfil/scheduler.interface';

@Injectable({
  providedIn: 'root',
})
export class CadastroService {

  public accountChangedSource: Subject<AccountInfo> = new Subject<AccountInfo>();
  public accountChanged$: Observable<AccountInfo> = this.accountChangedSource.asObservable();

  constructor(
    private apiService: ApiServiceService,     
    private http: HttpClient
  ) {}

  public loadAccount(subdomain: string): Observable<AccountInfo> {
    return this.apiService.get(`/registration/account?subdomain=${subdomain}`);
  }

  public loadHealthPlans(subdomain: string): Observable<IHealthPlanResponse[]> {
    return this.apiService.get(
      `/registration/health-plans?subdomain=${subdomain}`,
    );
  }

  public setMissingInfo(data: { scheduleId: number ,address?: IAddress, documents?:MissingInfo}): Observable<any> {
    return this.apiService.put(
      `/registration/missing`, data
    );
  }

  public cadastra(body: ICadastroRequest): Observable<void> {
    return this.apiService.post('/registration', body);
  }

  public confirmEmail(body: iConfirmEmail): Observable<void> {
    return this.apiService.patch('/registration/confirm-email', body);
  }

  public sendEmail(): Observable<void> {
    return this.apiService.post('/registration/reconfirm-email');
  }

  public forgotPassword(body: iForgotPassword): Observable<{email:string}> {
    return this.apiService.post(
      '/registration/forgot-password' +
        '?accountId=' +
        body.accountId +
        '&cpf=' +
        body.cpf +
        '&isSchedulersView='+
        body.isSchedulersView
    );
  }

  public changePassword(body: iChangePassword): Observable<void> {
    return this.apiService.patch('/registration/change-password', body);
  }

  public async checkDocument(cpf: string, accountId: number): Promise<CheckDocumentResponse>{
    return await this.apiService.post(`/registration/check-document`, {cpf, accountId}).toPromise();
  }

  public consultarCEP(cep: string): Observable<any> {
    return this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`);
  }
  
  public loadScheduler(): Observable<ISchedulerResponse> {
    return this.apiService.get('/registration/scheduler');
  }
}
