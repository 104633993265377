<app-offline-bar class="offline-bar" *ngIf="!isOnline"> </app-offline-bar>

<mat-toolbar color="primary" class="nav-top">
  <mat-toolbar-row class="nav-top-container">
    <span class="nav-top-container-avatar">
      <img *ngIf="logo" [src]="logo" class="logoMini" />

      <app-avatar
        *ngIf="!logo"
        [info]="accountName"
        [size]="'small'"
        [ngClass]="isAmorSaude? 'amorsaude-bg-red' : 'bg-purple'"
      ></app-avatar>
      <span class="nav-top-container-account">
        {{ accountName }}
      </span>
    </span>

    <span class="nav-top-container-avatar nav-top-doLogin" *ngIf="needLoginToShow">
      <a routerLink="/login">Entrar</a>
    </span>

    <span class="nav-top-container-avatar" *ngIf="!needLoginToShow">
      <button
        mat-button
        [matMenuTriggerFor]="menu"
        class="nav-top-container-trigger-menu"
      >
        <span class="p-padding-right-1 hideOnMobile">
          {{ userName }}
        </span>
        <app-avatar
          [info]="userName"
          [size]="'small'"
          style="float: right;"
          [ngClass]="isAmorSaude? 'amorSaude-avatarBg': 'avatarBg'"
        ></app-avatar>
      </button>

      <mat-menu #menu="matMenu">
        <button *ngIf="!isSchedulersView || !isPrevidas" mat-menu-item [routerLink]="profileURL">
          <fa-icon [icon]="['far', 'user']" size="sm"></fa-icon>
          <span> Perfil</span>
        </button>

        <button *ngIf="!isOnIframe" mat-menu-item (click)="logout()">
          <fa-icon icon="times"></fa-icon>
          <span> Sair</span>
        </button>

        <small class="app-version"
          >Versão: {{ currentApplicationVersion }}</small
        >
      </mat-menu>
    </span>
  </mat-toolbar-row>
</mat-toolbar>

<div class="app-container main-container">
  <div class="divContainer">
    <div class="updateversion" *ngIf="updatingVersion">
      <span>
        O sistema entrará em manutenção hoje (15/03/2023) entre 22h e 23h59, poderá ser usado como consulta, mas ações realizadas dentro desse intervalo poderão ser perdidas.
      </span>
      <!DOCTYPE html>
    </div>
    
    <div class="sidenav" *ngIf="!needLoginToShow && !isSchedulersView">
      <nav mat-tab-nav-bar [ngClass]="isAmorSaude? 'amorSaudeInk': '' ">
        <a
          mat-tab-link
          [ngClass]="isAmorSaude? 'amorSaude': ''"
          *ngFor="let m of itemsVisible"
          [routerLink]="m.route"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
        >
          <fa-icon
            [icon]="[m.icon.type, m.icon.name]"
            size="lg"
            class="p-margin-right-1"
          ></fa-icon>
          {{ m.name }}
        </a>
      </nav>
    </div>

    <div class="main-card-wrapper">
      <div class="mainCard">
        <div class="row">
          <app-breadcrumbs
            [item]="currentScreen[0]"
            [isAmorSaude]="isAmorSaude"
            class="col-12"
          ></app-breadcrumbs>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>



<button
  mat-fab
  *ngIf="canAddSchedule"
  routerLink="/agendamentos/adicionar"
  [ngClass]="isAmorSaude? 'add-schedule-floating-btn amorSaudeAdd' :'add-schedule-floating-btn'"
>
  <div class="hidden-tablet-and-above">
    <mat-icon>add</mat-icon>
  </div>
  <p class="visible-tablet-and-above p-padding-1">Novo agendamento</p>
</button>

  <mat-toolbar class="navBottom" *ngIf="!needLoginToShow && !isSchedulersView">
  <mat-toolbar-row class="submenu app-container" *ngIf="!isSchedulersView">
    <span align="center">
      <button mat-button routerLink="/agendamentos">
        <fa-icon [icon]="['fas', 'calendar-alt']" size="lg"></fa-icon><br />
        <div class="iconTitle">Agenda</div>
      </button>
    </span>

    <span align="center">
      <button mat-button routerLink="/historico">
        <fa-icon [icon]="['fas', 'history']" size="lg"></fa-icon><br />
        <div class="iconTitle">Histórico</div>
      </button>
    </span>

    <span align="center">
      <button mat-button routerLink="/meus-arquivos">
        <fa-icon [icon]="['fas', 'paperclip']" size="lg"></fa-icon><br />
        <div class="iconTitle">Arquivos</div>
      </button>
    </span>

    <span align="center" *ngIf="showCampaign">
      <button mat-button routerLink="/campanhas">
        <fa-icon [icon]="['fas', 'money-check-alt']" size="lg"></fa-icon><br />
        <div class="iconTitle">Campanhas</div>
      </button>
    </span>
  </mat-toolbar-row>
</mat-toolbar>
