import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaitingRoomService {

  constructor(
    private apiService: ApiServiceService
  ) { }

  
  public validarCPF(cpf: string): boolean {
    if (!cpf) {
      return false;
    }

    let numeros: any;
    let digitos: any;
    let soma: number;
    let i: number;
    let resultado: number;
    let digitos_iguais = 1;

    digitos_iguais = 1;

    if (cpf.length < 11) {
      return false;
    }

    for (i = 0; i < cpf.length - 1; i++) {
      // tslint:disable-next-line:triple-equals
      if (cpf.charAt(i) != cpf.charAt(i + 1)) {
        digitos_iguais = 0;
        break;
      }
    }
    if (!digitos_iguais) {
      numeros = cpf.substring(0, 9);
      digitos = cpf.substring(9);
      soma = 0;
      for (i = 10; i > 1; i--) {
        soma += numeros.charAt(10 - i) * i;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      // tslint:disable-next-line:triple-equals
      if (resultado != digitos.charAt(0)) {
        return false;
      }

      numeros = cpf.substring(0, 10);
      soma = 0;
      for (i = 11; i > 1; i--) {
        soma += numeros.charAt(11 - i) * i;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      // tslint:disable-next-line:triple-equals
      if (resultado != digitos.charAt(1)) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  }

  public getClinicInfo(hash: string): Observable<any>{
    return this.apiService.get(`/waiting-room/${hash}`)
  }

  public getSchedules(clinicId:number, cpf: string): Observable<any>{
    return this.apiService.post(`/waiting-room/schedules`, { clinicId, cpf });
  }

  public confirmSchedules(schedulesId:number[], arrivedAt:string): Observable<any>{
    return this.apiService.post(`/waiting-room/confirm`, { schedulesId, arrivedAt });
  }

}
