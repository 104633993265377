import { CadastroService } from 'src/app/services/cadastro.service';
import {
  MatSnackBar,
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { LoginService } from './../../../services/login.service';
import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AccountInfo, LocalStorageUtil } from 'src/app/util/local-storage-util';
import { menuOptions, MSG } from 'src/app/util/constants';
import { IMenu } from './navigation-bar.interface';
import { environment } from 'src/environments/environment';
import { Views } from 'src/app/pages/views';
import { AgendamentosService } from 'src/app/services/agendamentos.service';
import * as moment from 'moment';
import { VoucherService } from 'src/app/services/voucher.service';
import { takeUntil } from 'rxjs/operators';
import { ISchedulerResponse } from 'src/app/pages/perfil/scheduler.interface';

@Component({
  selector: 'main-nav',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent extends Views implements OnInit {
  @Input() titleScreen: any = '';
  @Input() icon: {};

  @Input() accountName: string;
  @Input() patientName: string;
  @Input() logo: string;
  @Input() email: string = '';

  public isOnline: boolean = navigator.onLine;
  public itemsVisible = menuOptions.filter((i) => i.visible);
  public currentScreen: IMenu[];
  public canAddSchedule: boolean;
  public currentApplicationVersion = environment.appVersion;
  public isEmailConfirmed = true;
  public isAmorSaude = false;
  public needLoginToShow = false;
  public isSchedulersView = false;
  public userName = ''
  private snackBarRef;
  private className = ''
  private accountInfo;
  private patientInfo;
  private schedulerInfo;
  public updatingVersion = false;
  public showCampaign = false;
  public profileURL = "/perfil";
  public isPrevidas = false

  constructor(
    private router: Router,
    private loginService: LoginService,
    private snack: MatSnackBar,
    private cadastroService: CadastroService,
    private voucherService: VoucherService,
    private agendamentosService: AgendamentosService,
  ) {
    super();

    this.updatingVersion = moment().format('DD/MM/YYYY') == '15/03/2023'

    this.currentScreen = menuOptions.filter((val) => {
      return this.router.url.split('?')[0].match(val.route) || this.router.url.match('perfil/');
    });

    this.isAmorSaude = loginService.isAmorSaude();
    this.isSchedulersView = loginService.isSchedulersView();
    this.className = this.isAmorSaude? 'amorsaude' : '';
  }

  public ngOnInit() {
    this.accountInfo = LocalStorageUtil.getAccountInfo();

    this.isPrevidas = this.loginService.isPrevidas()
    
    if(this.isSchedulersView){
      this.schedulerInfo = LocalStorageUtil.getSchedulerInfo();
      this.profileURL = '/agendadores' + this.profileURL;
      if(this.schedulerInfo)
        this.setLocalVars();
      else{
        this.loadCurrentScheduler();
      }
    
    }else{
      this.patientInfo = LocalStorageUtil.getPatientInfo();
      if(this.patientInfo)
        this.voucherService.checkIfAccountHasActiveVouchers('campaign').subscribe({
          next: res=>{
            this.showCampaign = res.hasVouchers;
                
          if (!res.hasVouchers) {
            this.itemsVisible = this.itemsVisible.filter(item => item.route != '/campanhas')
        }
          },
          error: err =>{
            console.log(err)
          }
        })
  
      if(!this.accountInfo){
        this.cadastroService
          .loadAccount(environment.subdomain)
          .subscribe((account: AccountInfo) => {
            this.accountInfo = account;
            LocalStorageUtil.setAccountInfo(account);
  
            if(this.patientInfo == null){
              this.needLoginToShow = true;
              this.accountName = account.accountName;
              this.logo = account.logo;
            }else{  
              if (this.accountInfo == null || this.patientInfo == null)
                this.getLocalVars();
              else this.setLocalVars();
            }
          });
      }else{
        if(this.patientInfo == null){
          this.needLoginToShow = true;
          this.accountName = this.accountInfo.accountName;
          this.logo = this.accountInfo.logo;
        }else{  
          if (this.accountInfo == null || this.patientInfo == null)
            this.getLocalVars();
          else this.setLocalVars();
        }
      }
    }
    
  }

  private loadCurrentScheduler() {
    this.cadastroService
      .loadScheduler()
      .subscribe((_scheduler) => {
        LocalStorageUtil.setSchedulerInfo(_scheduler);
        this.getLocalVars(_scheduler)
      });
  }

  private setLocalVars() {

    if(this.isSchedulersView){
      this.userName = this.schedulerInfo.name;
      this.email = this.schedulerInfo.email;
      this.canAddSchedule = false;
      this.accountName = this.accountInfo.accountName;
      this.logo = this.accountInfo.logo;

    }else{
      this.userName = this.patientInfo.name;
      this.email = this.patientInfo.email;
  
      this.isEmailConfirmed = this.patientInfo.isEmailConfirmed;
      this.accountName = this.accountInfo.accountName;
      this.logo = this.accountInfo.logo;
      this.canAddSchedule = this.userCanAddSchedule();
      if (!this.isEmailConfirmed && !sessionStorage.getItem('dont-disturb'))
        this.showEmailConfirmMessage();
    }

  }

  private getLocalVars(scheduler?: ISchedulerResponse) {
    let count = 0;
    const waitLocalStorage = setInterval(() => {
      this.accountInfo = LocalStorageUtil.getAccountInfo();

      if(this.isSchedulersView){
        if(scheduler){
          this.schedulerInfo = scheduler;
          LocalStorageUtil.setSchedulerInfo(scheduler);
        }
        else
          this.schedulerInfo = LocalStorageUtil.getSchedulerInfo();

        if (this.accountInfo != null && this.schedulerInfo != null) {
          clearInterval(waitLocalStorage);

          this.setLocalVars();
        }

      }else{
        this.patientInfo = LocalStorageUtil.getPatientInfo();
        if (this.accountInfo != null && this.patientInfo != null) {
          clearInterval(waitLocalStorage);

          this.setLocalVars();
        }
      }
      
      if (count == 4) {
        this.snack.open('Token inválido', 'Ok', { duration: 5000, panelClass: this.className });
        localStorage.removeItem('token');
        clearInterval(waitLocalStorage);
        this.router.navigate([(this.isSchedulersView? '/agendadores' : '') + '/login'], { queryParams: {} });
      }

      count++;
    }, 800);
  }

  private showEmailConfirmMessage() {
    this.snackBarRef = this.snack.openFromComponent(SnackBarTemplate, {
      data: {
        email: this.email
      },
      panelClass: this.className
    });

    Object.assign(this.snackBarRef, {
      closeWithAction: () => {
        this.snackBarRef.dismiss();
        this.sendEmail();
      },
      dismissWithAction: () => {
        sessionStorage.setItem('dont-disturb', '1');
        this.snackBarRef.dismiss();
      },
    });
  }

  private userCanAddSchedule() {
    const blockAddSchedule = ['Novo agendamento', 'Perfil', 'Campanhas'];

    let toBlock = false;
    for (const screen of this.currentScreen){
      toBlock = blockAddSchedule.includes(screen.name);

      if(toBlock)
        break;
    }

    return !toBlock;
  }

  public logout() {

    this.loginService.logout(this.isSchedulersView);
    this.snackBarRef.dismiss();
  }

  public sendEmail() {
    this.cadastroService.sendEmail().subscribe(
      (resp) => {
        sessionStorage.setItem('dont-disturb', '1');
        this.isEmailConfirmed = true;
        this.snack.open(MSG.REGISTER_EMAIL_SENT_SUCCESSFULLY, 'Ok', {
          duration: 3000,
          panelClass: this.className
        });
      },
      () => {
        this.isEmailConfirmed = false;
        this.snack.open(MSG.REGISTER_EMAIL_SENT_ERROR, 'Ok', {
          duration: 3000,
          panelClass: this.className
        });
      },
    );
  }
}
@Component({
  selector: 'snackbar-template',
  templateUrl: 'snackbar-template.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class SnackBarTemplate {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarTemplate>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
