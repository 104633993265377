import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { AccountInfo, LocalStorageUtil } from '../util/local-storage-util';

import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Views {
  public account: AccountInfo;
  campos: any;
  loading = true;
  fg: FormGroup;
  tinyClass = '';
  logo: string;
  public isOnIframe: boolean = false;

  constructor() {
    this.account = LocalStorageUtil.getAccountInfo();

    this.isOnIframe = this.getIsOnIframe();
  }

  // Retorna se a aplicação está sendo executada dentro pelo portal do EuSaúde,
  //caso esteja, bloqueia algumas funcionalidades
  public getIsOnIframe(): boolean {
    const url = location.href;

    const domains = ['teste.agendar.cc'];

    for (let domain of domains) {
      if (url.indexOf(domain) !== -1) return true;
    }

    return false;
  }

  initForm(fb: FormBuilder) {
    this.fg = new FormGroup(this.createFields(this.campos, fb));
  }

  createFields(campos: any, fb: FormBuilder): any {
    const fields: any = {};
    for (const key in campos) {
      if (campos.hasOwnProperty(key)) {
        const c = campos[key];
        fields[key] = fb.control(null, c);
      }
    }
    return fields;
  }

  validateAll() {
    Object.keys(this.fg.controls).forEach((field) => {
      const control = this.fg.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  // Método padrão para carregar primeiro nivel de formulários simples
  loadFormValues(response): void {
    for (const e in response) {
      if (this.campos.hasOwnProperty(e)) {
        this.fg.controls[e].setValue(response[e]);
      }
    }
  }

  protected dateValidator(control: AbstractControl) {
    if (!moment(control.value, 'DD/MM/YYYY').isValid()) {
      return { validator: true };
    }

    return null;
  }

  nameValidator(control: AbstractControl) {
    const val = control.value && control.value.trim();
    const reg = /^([a-zàèìòùáéíóúâêîôûãñõäëïöüç]+[\s]{1}[a-zàèìòùáéíóúâêîôûãñõäëïöüç]+)/gi;
    return val && !reg.test(val) && { validator: true };
  }
}
