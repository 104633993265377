import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CadastroService } from 'src/app/services/cadastro.service';
import { GENDERS, MSG } from 'src/app/util/constants';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { environment } from 'src/environments/environment';
import { PerfilService } from './../../services/perfil.service';
import { estados, IConvenioSelect, IPatientResponse } from './perfil.interface';
import { PacienteService } from 'src/app/services/paciente.service';
import { Views } from '../views';
import { LoginService } from 'src/app/services/login.service';
import { ISchedulerResponse } from './scheduler.interface';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent extends Views implements OnInit, OnDestroy {
  @Input() page = '';
  @Output() selectionChange: EventEmitter<MatSelectChange>;
  private unsubscribe$: Subject<void> = new Subject();
  public convenios: IConvenioSelect[] = [];
  public conveniosQueue: any = new Map();
  public deletingQueue: number[] = [];
  public title = 'Perfil';
  public isLoading: boolean;
  public icon = {
    name: 'user',
    type: 'far',
  };
  public estados = estados;

  public profile: IPatientResponse;
  public schedulerProfile: ISchedulerResponse;
  public hidePass = true;
  public isGettingApiResult = true;
  public isAmorSaude = false;
  public isSchedulersView = false;
  public genderArr = GENDERS;
  public className: string = '';
  public cancelLink: string = '';

  public form: FormGroup = new FormGroup({
    id: new FormControl(''),
    cpf: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    birthDate: new FormControl('', [Validators.required, this.dateValidator]),
    mobileNumber: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required]),
    password: new FormControl(''),
    accountId: new FormControl(''),
    gender: new FormControl('', Validators.required),
    healthPlans: new FormControl(''),
    zipcode: new FormControl(''),
    address: new FormControl(''),
    number: new FormControl(''),
    complement: new FormControl(''),
    neighborhood: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
  });

  public schedulerForm: FormGroup = new FormGroup({
    id: new FormControl(''),
    cpf: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required]),
    password: new FormControl(''),
    accountId: new FormControl(''),
  });

  constructor(
    public router: Router,
    private cadastroService: CadastroService,
    private snackBar: MatSnackBar,
    private profileService: PerfilService,
    private patientService: PacienteService,
    private loginService: LoginService
  ) {
    super();
    this.isAmorSaude = this.loginService.isAmorSaude();
    this.isSchedulersView = this.loginService.isSchedulersView();
    this.className = this.isAmorSaude? 'amorsaude' :''
  }

  public ngOnInit() {
    this.cancelLink = this.isSchedulersView? '/agendadores/agendamentos/adicionar' : '/agendamentos';
    if(this.isSchedulersView)
      this.loadCurrentScheduler();
    else
      this.loadCurrentPatient();
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private fillProfiledFields() {
    if(!this.isSchedulersView){
      this.form.patchValue({
        id: this.profile.id,
        cpf: this.profile.cpf,
        name: this.profile.name,
        birthDate: this.profile.birthDate,
        mobileNumber: this.profile.mobileNumber,
        email: this.profile.email,
        gender: this.profile.gender,
        zipcode: this.profile.address.zipcode,
        address: this.profile.address.address,
        number: this.profile.address.number,
        complement: this.profile.address.complement,
        neighborhood: this.profile.address.neighborhood,
        city: this.profile.address.city,
        state: this.profile.address.state,
      });
      this.form.controls.name.disable();
      this.form.controls.cpf.disable();
      this.form.controls.birthDate.disable();
      this.form.controls.gender.disable();
      this.form.controls.password.disable();

    } else{

      this.schedulerForm.patchValue({
        id: this.schedulerProfile.id,
        cpf: this.schedulerProfile.cpf,
        name: this.schedulerProfile.name,
        email: this.schedulerProfile.email,
      });
      this.schedulerForm.controls.password.disable();
    }
  }

  public getHealthPlan() {
    _.each(this.profile.healthPlans, (res) => {
      this.conveniosQueue.set(res.id, res);
    });

    this.cadastroService
      .loadHealthPlans(environment.subdomain)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {
        this.convenios = response;
      });
  }

  public togglePassword(e) {
    if(this.isSchedulersView)
      this.schedulerForm.controls.password[e.checked ? 'enable' : 'disable']();
    else
      this.form.controls.password[e.checked ? 'enable' : 'disable']();
  }

  public saveProfile() {
    this.isLoading = true;

    _.each(_.keys(this.form.controls), (field) => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    if (this.form.invalid) {
      this.snackBar.open(MSG.PROFILE.INCORRECT_FIELDS, '', { duration: 5000, panelClass: this.className });
      this.isLoading = false;
      return;
    }

    this.form.value.healthPlans = this.getHealthPlans();

    this.deletingQueue.length
      ? this.postRegisterAfterDelete()
      : this.postRegister();
  }

  public saveScheduleProfile() {
    this.isLoading = true;

    _.each(_.keys(this.schedulerForm.controls), (field) => {
      const control = this.schedulerForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    if (this.schedulerForm.invalid) {
      this.snackBar.open(MSG.PROFILE.INCORRECT_FIELDS, '', { duration: 5000, panelClass: this.className });
      this.isLoading = false;
      return;
    }

    this.schedulerForm.controls.accountId.setValue(LocalStorageUtil.getAccountInfo().accountId);
    const params = this.schedulerForm.value;

    this.profileService.updateSchedulers(params).subscribe(
      (response) => this.onUpdate(response),
      () => this.onUpdateError(),
    );
  }

  private postRegisterAfterDelete() {
    const observables = [];
    _.each(this.deletingQueue, (id) => {
      observables.push(
        this.profileService.deleteHealthPlan(this.profile.id, id),
      );
    });
    forkJoin(observables).subscribe(() => {
      this.deletingQueue = [];
      this.postRegister();
    });
  }

  private postRegister() {
    this.form.value.accountId = LocalStorageUtil.getAccountInfo().accountId;
    const params = {
      ...this.form.value,
      name: this.form.controls.name.value,
      cpf: this.form.controls.cpf.value,
      birthDate: this.form.controls.birthDate.value,
      gender: this.form.controls.gender.value,
      address: {
        zipcode: this.form.value.zipcode,
      address: this.form.value.address,
      number: this.form.value.number,
      complement: this.form.value.complement,
      neighborhood: this.form.value.neighborhood,
      city: this.form.value.city,
      state: this.form.value.state,
      }
    }
    this.profileService.updatePatient(params).subscribe(
      (response) => this.onUpdate(response),
      () => this.onUpdateError(),
    );
  }

  public onEditPlan(editedItem) {
    _.each(Object.keys(editedItem.value), (item) => {
      this.conveniosQueue.get(editedItem.id)[item] = editedItem.value[item];
    });
  }

  private getHealthPlans() {
    var res = [];

    Array.from(this.conveniosQueue.entries()).forEach((i) => {
      const isTemp = /temp_/.test(i[0]);
      if (isTemp) delete i[1].id;
      if (!isTemp && i[1].deleting) {
        this.deletingQueue.push(i[0]);
      }
      res.push(i[1]);
    });
    return _.filter(res, (r) => !r.deleting);
  }

  private onUpdate(response) {
    // _.each(_.keys(this.form.value), (key) => {
    //   this.profile[key] = this.form.value[key];
    // });

    let[url, message] = ['', '']
    if(this.isSchedulersView){
      LocalStorageUtil.setSchedulerInfo(response);
      url = '/agendadores/agendamentos/adicionar';
      message= MSG.PROFILE.SCHEDULER_UPDATED;

    }else{
      LocalStorageUtil.setPatientInfo(response);
      url = '/agendamentos';
      message = MSG.PROFILE.UPDATED_SUCESSS;
    }

    this.isLoading = false;
    this.router.navigate([url]);
    this.snackBar.open(message, '', { duration: 3000, panelClass: this.className });
  }

  private onUpdateError() {
    this.isLoading = false;
    const message = this.isSchedulersView? MSG.PROFILE.SCHEDULER_UPDATED_ERROR : MSG.PROFILE.UPDATED_ERROR;
    this.snackBar.open(message, '', { duration: 3000, panelClass: this.className });
  }

  public onRemovePlan(healthPlanId: number) {
    this.conveniosQueue.get(healthPlanId).deleting = true;
  }

  public onAddPlan(healthPlan: any) {
    this.conveniosQueue.set(healthPlan.id, healthPlan.plan);
  }

  protected dateValidator(control: AbstractControl) {
    const date = moment(control.value, 'DD/MM/YYYY');
    const isValidDate = date.isValid();
    const isFutureDate = date.isAfter();

    if (!isValidDate || isFutureDate) {
      return { validator: true };
    }
    return null;
  }

  private loadCurrentPatient() {
    this.patientService
      .current()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_patient) => {
        LocalStorageUtil.setPatientInfo(_patient);
        this.profile = LocalStorageUtil.getPatientInfo();
        this.getHealthPlan();
        this.fillProfiledFields();
        this.isGettingApiResult = false;
      },
      error=>{
        if(error?.status == 404){
          this.snackBar.open('Paciente não encontrado', 'ok', { duration: 3000 })
          this.loginService.logout();
        }
      }
    );
  }

  private loadCurrentScheduler() {
    this.cadastroService
      .loadScheduler()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_scheduler) => {
        LocalStorageUtil.setSchedulerInfo(_scheduler);
        this.schedulerProfile = _scheduler;
        this.fillProfiledFields();
        this.isGettingApiResult = false;
      });
  }

  onChangeCEP(): void {
    if (this.form.controls.zipcode.value.length === 8) {
      this.cadastroService.consultarCEP(this.form.controls.zipcode.value).subscribe(
        (result) => {

          this.form.controls.address.setValue(result.logradouro);
          this.form.controls.neighborhood.setValue(result.bairro);
          this.form.controls.city.setValue(result.localidade);
          this.form.controls.state.setValue(result.uf);
        },
        (error) => {
          this.snackBar.open('Erro ao se comunicar com Correios, por favor tente novamente', 'ok', { duration: 3000 });
        }
      );
    }
  }
}
