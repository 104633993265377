import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { freeProcedure, IAgendamentoSearch } from 'src/app/pages/agendamentos/agendamentos.interfaces';
import { AccountInfo } from 'src/app/util/local-storage-util';

@Component({
  selector: 'agendamento-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements OnDestroy{
  constructor(public router: Router, public snack: MatSnackBar) { }

  @Input() step: number;
  @Input() stopTimer:boolean = false;
  @Input() isAmorSaude: boolean = false;
  @Input() isStaging: boolean = false;
  @Input() isSchedulersView: boolean = false;
  @Input() settingVoucher: boolean = false;
  @Input() search: IAgendamentoSearch;
  @Input() patientInfo;
  @Input() accountInfo: AccountInfo;
  @Output() stepChanged: EventEmitter<any> = new EventEmitter();
  @Output() timesOver: EventEmitter<any> = new EventEmitter();
  @Output() confirmedSchedule: EventEmitter<any> = new EventEmitter();
  
  public timerToShow:string = '00:00:00';
  public duration:any;
  public redTimer = false;
  public FreeProcedure = freeProcedure;

  ngOnDestroy(){
    clearInterval(this.duration);
  }

  public nextStep() {
    this.step = ++this.step;
    this.stepChanged.emit(this.step);
  }

  public prevStep() {
    this.step = --this.step;
    this.stepChanged.emit(this.step);
  }

  public onHandlePrev(step: number) {
    clearInterval(this.duration)
    if (step <= 4) {
      const linkTo = this.isSchedulersView? '/agendadores/agendamentos/adicionar' : '/agendamentos'
      this.router.navigate([linkTo], {
        queryParamsHandling: 'preserve',
      });
    }

    if (step > 4) {
      this.prevStep();
    }
  }

  public onHandleNext(step: number) {
    if (step == 7){
      this.confirmedSchedule.emit();
      return;
    }
    this.nextStep();
  }

  ngOnChanges(element){
    if(element.step && element.step.currentValue == 7){
     this.getTimer();
      return;
    }

    if(element.stopTimer && element.stopTimer.currentValue){
      clearInterval(this.duration);
      return;
    }               
  }

  public getTimer(){
    let time: number;
    const accountTime = this.accountInfo?.timeToCancelPreSchedule || 10;
    if(this.search?.reservedAt){
      const reservedTime = new Date(this.search.reservedAt).getTime() / 1000;
      const now = new Date().getTime() / 1000;
      time = (accountTime * 60) - (now - reservedTime);
    }
    else
      time = accountTime * 60;
      
    const [defaultTime, defaultFront] = [ time, `${this.accountInfo.timeToCancelPreSchedule}:00`];
    this.timerToShow = defaultFront;
    var times = defaultTime, min, sec, hour;
    this.redTimer = false;
    this.duration = setInterval(() =>{

      hour = Math.floor(times / 3600);
      min = Math.floor((times % 3600) / 60);
      sec = Math.floor(times % 60);

      hour = hour.toString().padStart(2, '0');
      min = min.toString().padStart(2, '0');
      sec = sec.toString().padStart(2, '0');

      this.timerToShow = hour + ":" + min + ":" + sec;
      
      if (--times < 0) {
          this.timesOver.emit()
          clearInterval(this.duration);
          this.snack.open('Tempo esgotado. Agendamento reservado removido', 'ok',{panelClass: this.isAmorSaude? 'amorsaude' : ''});
          const linkTo = this.isSchedulersView? '/agendadores/agendamentos/adicionar' : '/agendamentos'
          this.router.navigate([linkTo], {
            queryParamsHandling: 'preserve',
          });
      }

      if(times < 90) this.redTimer = true;

    },1000);
  }

}
