import { IPlanResponse } from '../cadastro/cadastro.interfaces';

export interface IConvenioSelect {
  index?: number;
  corporateName?: string;
  healthPlan?: IPlanResponse;
  company?: string;
  cardNumber?: string;
  validity?: string;
  plans?: Array<Object>;
}

export interface IPatientResponse {
  id: number;
  name: string;
  accountId: number;
  birthDate: string;
  company: string;
  cpf: string;
  email: string;
  mobileNumber: string;
  isEmailConfirmed: boolean;
  gender: string;
  healthPlans: any[];
  permissions: any;
  address?: IAddress;
}

export interface IAddress{
  zipcode: string;
  address: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
}

export interface MissingInfo{
  cpf: string;
  email: string;
}

export const estados = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
];
