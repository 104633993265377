<form [formGroup]="form" class="p-margin-top-1" *ngIf="userCan.add">
  <input type="hidden" formControlName="healthPlanId" />
  <ng-container *ngIf="isAdding">
    <div class="row">
      <mat-form-field appearance="outline" class="col-sm-3">
        <mat-label>
          Convênio
        </mat-label>
        <mat-select
          placeholder="Escolha um convenio"
          (selectionChange)="onChangeCorporate($event.value)"
          formControlName="corporateName"
        >
          <mat-option
            *ngFor="let convenio of convenios; index as i"
            [value]="i"
          >
            {{ convenio.corporateName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="col-sm-2"
        *ngIf="plans.length"
      >
        <mat-select placeholder="Escolha um plano" formControlName="plan">
          <mat-option *ngFor="let item of plans" [value]="item">
            {{ item.plan }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="col-sm-3"
        *ngIf="plans.length"
      >
        <mat-label>Contratante (Titular/Empresa)</mat-label>
        <input matInput type="text" formControlName="company" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="col-sm-2"
        *ngIf="plans.length"
      >
        <mat-label>Carteirinha</mat-label>
        <input matInput type="text" formControlName="cardNumber" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="col-sm-2"
        *ngIf="plans.length"
      >
        <mat-label>Validade</mat-label>
        <input
          matInput
          type="text"
          formControlName="validity"
          [specialCharacters]="['/']"
          [mask]="'00/00/0000'"
        />
      </mat-form-field>

      <div class="col-sm-12">
        <button mat-button [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary' : 'btn-secondary'" (click)="isAdding = false">
          <fa-icon icon="times"></fa-icon>
          <span>
            Cancelar
          </span>
        </button>

        <button
          mat-button
          [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary p-margin-left-1' : 'btn-secondary p-margin-left-1'"
          (click)="addPlan()"
        >
          <fa-icon icon="check"></fa-icon>
          <span>
            Adicionar
          </span>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="row">
    <div class="col-sm-12">
      <button
        mat-button
        [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary' : 'btn-secondary'"
        (click)="isAdding = !isAdding"
        *ngIf="!isAdding"
      >
        <fa-icon icon="plus"></fa-icon>
        <span>
          Adicionar novo convênio
        </span>
      </button>
    </div>
  </div>
</form>

<div class="p-margin-top-2 list-convenios-message" *ngIf="healthPlanHasChanged">
  Seus convênios foram alterados, porém as alterações só irão ser salvas após
  clicar no botão salvar.
</div>

<form [formGroup]="formEdition">
  <table
    class="p-margin-vertical-3 list-convenios"
    cellspacing="0"
    cellpadding="0"
  >
    <thead class="list-convenios-header">
      <tr>
        <th>Convênio</th>
        <th>Plano</th>
        <th>Contratante</th>
        <th>Carteirinha</th>
        <th>Validade</th>
        <th *ngIf="userCan.add || userCan.delete">
          Ação
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let plan of conveniosQueue | keyvalue; index as index"
        class="list-convenios-body"
        [class.is-editing]="isEditing === plan.value.id"
      >
        <ng-container *ngIf="!plan.value.deleting">
          <td>
            <mat-form-field appearance="outline">
              <mat-select
                [disabled]="true"
                formControlName="corporateName"
                placeholder="Selecione o Convênio"
                (selectionChange)="
                  onEditItem(
                    $event.value,
                    plan.value.id,
                    'corporateName',
                    $event
                  )
                "
              >
                <mat-option disabled>
                  Selecione o convênio
                </mat-option>
                <mat-option
                  *ngFor="let convenio of convenios"
                  [value]="convenio.corporateName"
                >
                  {{ convenio.corporateName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <span>{{ plan.value.corporateName || ' - ' }}</span>
          </td>

          <td>
            <mat-form-field appearance="outline">
              <mat-select
                [disabled]="true"
                formControlName="planEdit"
                (click)="verifyPlans()"
                (selectionChange)="
                  onEditItem($event.value, plan.value.id, 'plan')
                "
              >
                <mat-option disabled>
                  Selecione o plano
                </mat-option>
                <mat-option *ngFor="let item of plans" [value]="item.plan">
                  {{ item.plan }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <span>{{ plan.value.plan || ' - ' }}</span>
          </td>

          <td>
            <input
              matInput
              autocomplete="off"
              type="text"
              formControlName="company"
              placeholder="Digite o contratante"
              (change)="
                onEditItem($event.target.value, plan.value.id, 'company')
              "
            />
            <span>{{ plan.value.company || ' - ' }}</span>
          </td>

          <td>
            <input
              matInput
              autocomplete="off"
              type="text"
              formControlName="cardNumber"
              placeholder="Número da carteirinha"
              (change)="
                onEditItem($event.target.value, plan.value.id, 'cardNumber')
              "
            />
            <span>{{ plan.value.cardNumber || ' - ' }}</span>
          </td>
          <td>
            <input
              matInput
              autocomplete="off"
              type="text"
              formControlName="validity"
              [specialCharacters]="['/']"
              placeholder="Digite a validade"
              [mask]="'00/00/0000'"
              (change)="
                onEditItem($event.target.value, plan.value.id, 'validity')
              "
            />
            <span *ngIf="plan.value.validity">{{
              plan.value.validity | date: 'DD/MM/YYYY':'DDMMYYYY'
            }}</span>
            <span *ngIf="!plan.value.validity"> - </span>
          </td>

          <td
            class="list-convenios-actions"
            *ngIf="
              isEditing !== plan.value.id && (userCan.add || userCan.delete)
            "
          >
            <fa-icon
              [ngClass]="isAmorSaude? 'amorsaude' : ''"
              icon="edit"
              size="lg"
              title="Click to Edit"
              (click)="editPlan(plan)"
              *ngIf="userCan.add"
            ></fa-icon>
            <fa-icon
              [ngClass]="isAmorSaude? 'amorsaude' : ''"
              [class.disabled]="!itemCanBeDeleted(plan.value.id)"
              icon="trash"
              size="lg"
              title="Click to Delete"
              (click)="removePlan(plan.value.id)"
            ></fa-icon>
          </td>

          <td
            *ngIf="isEditing === plan.value.id"
            class="list-convenios-actions-edit"
          >
            <button
              mat-button
              [ngClass]="isAmorSaude? 'btn-amorSaudeTertiary btn-small btn-light' : 'btn-tertiary btn-small btn-light'"
              (click)="cancelEdition()"
            >
              <fa-icon icon="times"></fa-icon>
              Cancelar
            </button>
            <button
              [disabled]="!canSaveEdition"
              mat-button
              [ngClass]="isAmorSaude? 'btn-amorSaudeTertiary btn-small' : 'btn-tertiary btn-small'"
              (click)="saveEdition(plan)"
            >
              <fa-icon icon="check"></fa-icon>
              Salvar
            </button>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</form>
