import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { CadastroService } from 'src/app/services/cadastro.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SchedulePaymentLinkValidation } from 'src/app/shared/components/modal-payment/amor-saude.interface';
import { ModalPaymentComponent } from 'src/app/shared/components/modal-payment/modal-payment.component';
import { AccountInfo, LocalStorageUtil } from 'src/app/util/local-storage-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pagarconsulta',
  templateUrl: './pagarconsulta.component.html',
  styleUrls: ['./pagarconsulta.component.scss']
})
export class PagarConsultaComponent implements OnInit {

  token: string;
  waitLocalstorage: any;
  loading = true;
  validToken = true;
  payed = false;
  msg = '';
  data: any;
  account: AccountInfo;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private socket: Socket,
    private paymentService: PaymentService,
    private cadastroService: CadastroService
  ) { 
    this.token = route.snapshot.params['token'];
    this.cadastroService
      .loadAccount(environment.subdomain)
      .subscribe((account: AccountInfo) => {
        this.account = account;
        LocalStorageUtil.setAccountInfo(account);
      }, (err: {
        status: number
      }) => {
        if(err.status == 404){
          this.snack.open('Agenda não encontrada, confira o endereço da página, por favor', 'OK')
          this.router.navigate(['/404'])
        } else {
          this.snack.open('Erro desconhecido ao procurar conta, atualize a página, por favor', 'OK')
        }
      });
  }

  ngOnInit(): void {
    this.paymentService.hasPaymentToken(this.token).subscribe({
      next:
        (res: SchedulePaymentLinkValidation)=>{
          if(!res.isValid){
            this.validToken = false;
            this.msg = 'Token inválido';
          }

          if(res.payed)
            this.msg = 'Pagamento já realizado com sucesso';

          if(res.search)
            this.data = {
              search: res.search,
              scheduleId: res.search.scheduleId,
              patientId: res.search.patientId,
            }

          this.loading = false;
          this.validToken = res.isValid;
          this.payed = res.payed;
        },
      error:
        err=>{
          this.snack.open('Erro ao validar link, por favor, recarrega a página', 'ok', {
            duration: 5000
          });
          this.msg = 'Link inválido ou já utilizado!';
          this.validToken = false;
          this.loading = false;
        }
    })

  }

  onClickPayment(){
    const dialogRef = this.dialog.open(ModalPaymentComponent,{
      disableClose: true,
      panelClass: 'paymentModal',
      data:{
        scheduleId: this.data.scheduleId,
        search: this.data.search,
        token: true,
        linkedPayment: true,
        accountId: this.account.accountId
      }
    });
    dialogRef.afterClosed().subscribe(
      res =>{
        if(res.payed){
          this.msg = 'Pagamento realizado com sucesso';
          this.payed = true;
          this.socket.emit('schedule-update', {
            professionalId: this.data.search.professionalId,
          })
        }else{
          this.loading = true;
          this.paymentService.verifyTransactionPayment(this.data.scheduleId).subscribe(
            res=>{
              this.loading = false;
              if( !res || res.status === 'pending' || res.status === 'failed' )
                return;
              else if(res?.status === 400){
                this.snack.open('Erro ao verificar confirmação de pagamento','ok', {duration: 3000});
                this.loading = false;
              }
            },
            err=>{
              this.snack.open('Erro ao verificar confirmação de pagamento','ok', {duration: 3000});
              this.loading = false;
            }
          )
        }
      }
    )
  }

}
