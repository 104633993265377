import { ConfirmarConsultaComponent } from './pages/confirmar-consulta/confirmar-consulta.component';
import { RedefinirSenhaComponent } from './pages/redefinir-senha/redefinir-senha.component';
import { AtivarEmailComponent } from './pages/ativar-email/ativar-email.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AgendaComponent } from './pages/agendamentos/agendamentos.component';
import { HistoryComponent } from './pages/history/history.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { LoginComponent } from './pages/login/login.component';
import { NovoagendamentoComponent } from './pages/novoagendamento/novoagendamento.component';
import { CadastroComponent } from './pages/cadastro/cadastro.component';
import { EsqueciMinhaSenhaComponent } from './pages/esqueci-minha-senha/esqueci-minha-senha.component';
import { MeusArquivosComponent } from './pages/meus-arquivos/meus-arquivos.component';
import { CampanhasComponent } from './pages/campanhas/campanhas.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PagarConsultaComponent } from './pages/pagarconsulta/pagarconsulta.component';
import { WaitingRoomComponent } from './shared/components/waiting-room/waiting-room.component';

const schedulers = 'agendadores'
//TODO: Impl. Lazy loading modules
const routes: Routes = [
  { path: '', redirectTo: 'agendamentos/adicionar', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: `${schedulers}/login`, component: LoginComponent },
  { path: `${schedulers}/login-sso/:sso/:patient/:reschedule`, component: LoginComponent },
  { path: 'agendamentos', component: AgendaComponent },
  { path: 'historico', component: HistoryComponent },
  { path: `${schedulers}/perfil`, component: PerfilComponent },
  { path: 'perfil', component: PerfilComponent },
  { path: 'perfil/:profile', component: NovoagendamentoComponent },
  { path: `${schedulers}/agendamentos/adicionar`, component: NovoagendamentoComponent },
  { path: 'agendamentos/adicionar', component: NovoagendamentoComponent },
  { path: 'cadastro', component: CadastroComponent },
  { path: `${schedulers}/esqueci-minha-senha`, component: EsqueciMinhaSenhaComponent },
  { path: 'esqueci-minha-senha', component: EsqueciMinhaSenhaComponent },
  { path: 'ativar-email/:token', component: AtivarEmailComponent },
  { path: `${schedulers}/redefinir-senha/:token`, component: RedefinirSenhaComponent },
  { path: 'redefinir-senha/:token', component: RedefinirSenhaComponent },
  { path: 'confirmar-consulta/:token', component: ConfirmarConsultaComponent },
  { path: 'pagar-consulta/:token', component: PagarConsultaComponent },
  { path: 'meus-arquivos', component: MeusArquivosComponent },
  { path: 'campanhas', component: CampanhasComponent},
  { path: 'sala-espera/:hash', component: WaitingRoomComponent},
  { path: '404', component: NotFoundComponent },
  { path: `${schedulers}/404`, component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
