import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IAgendamentoDeletedRequest } from 'src/app/pages/agendamentos/agendamentos.interfaces';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  constructor() {}

  @Input() title = '';
  @Input() isDeleting: boolean = false;
  @Input() content: any = [];
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();
  @Output() timesOver: EventEmitter<any> = new EventEmitter();

  public today = new Date();

  public onDeleteHandler(agenda: IAgendamentoDeletedRequest) {
    this.onDelete.emit(agenda);
  }

  public onRefreshHandler() {
    this.onRefresh.emit(true);
  }

  public onTimesOver(item:any) {
    this.timesOver.emit(item)
  }
}
