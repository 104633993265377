<div
  [class]="'lds-spinner lds-spinner-' + (type || 'dark')"
  [style]="'transform: scale(' + scale + ')'"
>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
