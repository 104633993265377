<mat-card class="a">
  <div class="purple" class="b">
    Parabéns!!
  </div>
  <p>Agendamento realizado com sucesso!</p>
  <p>
    <fa-icon [icon]="['far', 'check-circle']" size="9x" class="c"></fa-icon>
  </p>

  <div *ngIf="!isSchedulersView">
    <button 
      mat-button 
      [ngClass]="amorSaude? 'btn-amorSaudeSecondary schedules' : 'btn-secondary schedules'"  
      routerLink="/agendamentos"
      [disabled]="addingToCalendar"
    >
      Meus Agendamentos
    </button>
  
    <button
      mat-button
      id="addToCalendar"
      (click)="onAddToCalendar()"
      [disabled]="addingToCalendar"
    >
      <mat-spinner *ngIf="addingToCalendar" [diameter]="25" color="primary"></mat-spinner>
      <span *ngIf="!addingToCalendar" >
        Adicionar na agenda
      </span>
    </button>

  </div>

  <div *ngIf="isSchedulersView">
    <button 
      mat-button 
      class="btn-secondary schedules"
      (click)="newSchedule()"  
      routerLink="/agendadores/agendamentos/adicionar"
    >
      <fa-icon icon="plus"></fa-icon> Agendar
    </button>
    <button 
      *ngIf="accountInfo?.onlinePayment"
      mat-button 
      class="btn-secondary schedules"
      (click)="copyPaymentLink()"
    >
      <fa-icon icon="dollar-sign"></fa-icon> Link pagamento
    </button>
  </div>

</mat-card>
