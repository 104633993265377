import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  @ViewChild('field', { static: true }) field: Input;

  isAmorSaude = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loginService: LoginService
  ) {
    if (!data.cancel) {
      data.cancel = 'Cancelar';
    }

    if (!data.confirm) {
      data.confirm = 'Confirmar remoção';
    }

    this.isAmorSaude = this.loginService.isAmorSaude();
  }

  public ngOnInit() {}

  public onConfirm(): void {
    if (this.data.onConfirm) {
      if (this.data.required) {
      }
      this.data.onConfirm();
    }
  }
}
