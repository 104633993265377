import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MSG } from 'src/app/util/constants';
import * as _ from 'lodash';
import { CadastroService } from 'src/app/services/cadastro.service';
import { estados } from 'src/app/pages/perfil/perfil.interface';

@Component({
  selector: 'app-complete-address',
  templateUrl: './complete-address.component.html',
  styleUrls: ['./complete-address.component.scss']
})
export class CompleteAddressComponent implements OnInit {

  loading = true;

  estados = estados;

  documents = false

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    private dialogref: MatDialogRef<CompleteAddressComponent>,
    private snackBar: MatSnackBar,
    private cadastroService: CadastroService
  ) { }

  fields = {
    id: new FormControl('',Validators.required),
    zipcode: new FormControl('',Validators.required),
    address: new FormControl('', Validators.required),
    number: new FormControl('', Validators.required),
    complement: new FormControl(''),
    neighborhood: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    cpf: new FormControl('',Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
  }

  public form: FormGroup;


  ngOnInit(): void {
    this.documents = 'patientData' in this.data;
    if(this.documents){
      this.form = new FormGroup(this.fields);
    }

    this.applyData();
    
    this.loading = false;    
  }

  applyData(){
    this.form.patchValue({
      id:this.data.patientData.address.id,
      zipcode:this.data.patientData.address.zipcode,
      address:this.data.patientData.address.address,
      number:this.data.patientData.address.number,
      complement:this.data.patientData.address.complement,
      neighborhood:this.data.patientData.address.neighborhood,
      city:this.data.patientData.address.city,
      state:this.data.patientData.address.state,
      cpf:this.data.patientData.cpf,
      email:this.data.patientData.email,
    });

    if(this.data.patientData.cpf)
        this.form.controls.cpf.disable();

    if(this.data.patientData.email)
        this.form.controls.email.disable();
  }

  onChangeCEP(): void {
    if (this.form.controls.zipcode.value.length === 8) {
      this.cadastroService.consultarCEP(this.form.controls.zipcode.value).subscribe(
        (result) => {
          this.form.enable();
          this.form.controls.address.setValue(result.logradouro);
          this.form.controls.neighborhood.setValue(result.bairro);
          this.form.controls.city.setValue(result.localidade);
          this.form.controls.state.setValue(result.uf);
        },
        (error) => {
          this.form.enable();
          this.snackBar.open('Erro ao se comunicar com Correios, por favor tente novamente', 'ok', { duration: 3000 });
        }
      );
    }
  }

  save(){

    _.each(_.keys(this.form.controls), (field) => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    if (this.form.invalid) {
      this.snackBar.open(MSG.PROFILE.INCORRECT_FIELDS, '', { duration: 5000 });
      return;
    }

    this.loading = true;

    const params = {
      scheduleId: this.data.scheduleId
    } as any


    params['documents'] = {
      cpf: this.form.getRawValue().cpf,
      email: this.form.getRawValue().email
    };
  
    params['address'] = {
      id:this.form.getRawValue().id,
      zipcode:this.form.getRawValue().zipcode,
      address:this.form.getRawValue().address,
      number:this.form.getRawValue().number,
      complement:this.form.getRawValue().complement,
      neighborhood:this.form.getRawValue().neighborhood,
      city:this.form.getRawValue().city,
      state:this.form.getRawValue().state,
    }

    this.cadastroService.setMissingInfo(params).subscribe({
      next: res =>{
        this.dialogref.close({ success: true });
      },
      error: err =>{
        this.snackBar.open(MSG.PROFILE.UPDATED_ERROR, 'ok',{ duration: 5000 });
        this.loading = false;
      }
    })

  }

  cancel(){
    this.dialogref.close({ success: false });
  }

}
