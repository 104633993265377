import { Component } from '@angular/core';

@Component({
  selector: 'app-offline-bar',
  templateUrl: './offline-bar.component.html',
  styleUrls: ['./offline-bar.component.scss'],
})
export class OfflineBarComponent {
  constructor() {}
}
