<div class="loginCard" align="center">
  <mat-card>
    <div class="row">
      <div class="col-12">
        <img [src]="account?.logo" class="imgLogo" />

        <div align="center" [ngClass]="isAmorSaude? 'title amorsaudeColor' : 'title purple'">
          Esqueceu sua senha?
        </div>

        <div align="center">
          Informe seu cpf abaixo para redefinir sua senha.
        </div>
        <br />
      </div>

      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>CPF</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="cpf"
            [specialCharacters]="['/', '.', '-']"
            [mask]="'000.000.000-00'"
          />
        </mat-form-field>
      </div>
      <br />

      <div class="col-12">
        <button
          mat-button
          [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary btn-large' : 'btn-secondary btn-large'"
          (click)="sendEmail()"
        >
          <span>Redefinir senha</span>
        </button>
      </div>

      <div align="left" class="col-12 top20">
        <a (click)="goBack()" [ngClass]="isAmorSaude? 'amorsaudeColor' : 'purple'" style="cursor: pointer;">
          <span>Voltar</span>
        </a>
      </div>
    </div>
  </mat-card>

  <app-footer></app-footer>
</div>
