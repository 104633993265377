import { Injectable } from '@angular/core';
import { IProcedure } from '../shared/partials/agendamento/inputs/inputs.component.interface';
import { ApiServiceService } from './api-service.service';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(
    private apiService: ApiServiceService
  ) { }

  verifyAndApplyCode(code: string, procedure: IProcedure){
    return this.apiService.post(
      `/vouchers`, {code, procedure}
    )
  }

  checkIfPatientCanByCampaign(patientId: number, campaignId: number){
    return this.apiService.post(`/vouchers/campaign/check-campaign-health-plans`, 
    { patientId, campaignId }
    )
  }

  getVoucherCode(voucherId: number){
    return this.apiService.get(`/vouchers/${voucherId}`);
  }

  getVoucherQueryParams(voucherId: number){
    return this.apiService.get(`/vouchers/schedule-params/${voucherId}`);
  }

  getCampaignVouchersByAccount(){
    return this.apiService.get(`/vouchers/campaign`);
  }

  getPatientVouchersByCampaign(campaignId: number){
    return this.apiService.get(`/vouchers/campaign/${campaignId}`);
  }

  checkIfAccountHasActiveVouchers(type: 'voucher' | 'campaign'){
    return this.apiService.get(`/vouchers/check/${type}`)
  }

}
