import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

moment.locale('pt-br');

@Pipe({
  name: 'date',
})
export class DatePipe implements PipeTransform {
  transform(
    date: moment.Moment | string | number,
    formatOut: string = 'DD/MM/YYYY',
    formatIn?: string,
    utc?: boolean,
  ): string {
    if (formatIn && formatIn != '') {
      return moment(date, formatIn).format(formatOut);
    } else {
      if (utc) {
        const href = window.location.href;
        const keepLocalTime = href.includes('localhost');
        return moment(date).utc(keepLocalTime).format(formatOut);
      } else {
        return moment(date).format(formatOut);
      }
    }
  }
}
