import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { Observable, throwError } from 'rxjs';
import {
  IAgendamentoResponse,
  IAgendamento,
  IConfirmAgenda,
} from '../pages/agendamentos/agendamentos.interfaces';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AgendamentosService {
  constructor(private apiService: ApiServiceService) {}

  public getAgendamentos(
    isHistory: string,
    limit: number = 10,
    day?: number,
    filterDate?: number,
  ): Observable<IAgendamentoResponse[]> {
    let params: any = {
      isHistory,
      limit,
    };

    if (filterDate) {
      delete params.limit;
      params = { ...params, filterDate };
    }
    if (!filterDate && day) params = { ...params, day };

    params.timezone = moment().utcOffset().toString();

    return this.apiService.get('/schedules', params);
  }

  public getAgendamentoById(agendaId: number) {
    return this.apiService.get(`/schedules/details/${agendaId}`);
  }

  public insertAgenda(agenda: IAgendamento) {
    return this.apiService.postPromise(`/schedules`, agenda);
  }

  public insertAgendaLogout(agenda: IAgendamento) {
    return this.apiService.postPromise(`/schedules/no-login`, agenda);
  }

  public updateLogoutScheduled(agendaId: number, expertiseId: number) {
    return this.apiService.put(`/schedules`, { id: agendaId, expertiseId});
  }

  public deleteAgenda(agendaId: number, reason: string): Promise<any> {
    return this.apiService.deleteWithBody(`/schedules/${agendaId}`, {
      reason: reason,
    });
  }

  public getTiposAgendamentos(): Observable<string[]> {
    return this.apiService.get('/tipos');
  }

  public confirmAgenda(agenda: IConfirmAgenda) {
    return this.apiService.patch(`/schedules/confirm-agenda`, agenda);
  }

  public checkAvailability(agenda: IAgendamento) {
    return this.apiService.post(`/schedules/check-availability`, agenda);
  }

  public checkHourConfigurationPermission(agenda: IAgendamento) {
    return this.apiService.postPromise(`/schedules/check-hour`, agenda);
  }

  public deletePreSchedule(agendaId: number) {
    return this.apiService.delete(`/schedules/pre-scheduled/${agendaId}`);
  }

  public updatePreSchedulePayment(preScheduleId: number){
    return this.apiService.put(`/schedules/pre-scheduled/${preScheduleId}`);
  }

  public findPatientsByInfo(data: { patientId?: number, name?: string }){
    let url = '/schedules/patients';
    let params = []

    for(const key in data)
      params.push(`${key}=${data[key]}`)

    return this.apiService.get(url + (params.length > 0? `?${params.join('&')}`: ''));
  }

  public findPatientsHealthPlans(patientId: number){
    return this.apiService.get(`/schedules/patients/${patientId}/health-plans`);
  }

  public getTeleconsultationLink(scheduleId: number){
    return this.apiService.get(`/schedules/tele-link/${scheduleId}`).toPromise()
    .then(res => res)
    .catch((err) => {
      return throwError(err)
    });
  }
}
