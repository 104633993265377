import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadFile } from '../pages/meus-arquivos/meus-arquivos.interface';
import { IPatientResponse } from '../pages/perfil/perfil.interface';
import { COMMUNICARE_API_MESSAGE } from '../util/constants';
import { LocalStorageUtil } from '../util/local-storage-util';
import { ApiServiceService } from './api-service.service';

@Injectable({
  providedIn: 'root',
})
export class PacienteService {
  constructor(
    private apiService: ApiServiceService,
    private http: HttpClient
    ) {}

  public current(): Observable<IPatientResponse> {
    return this.apiService.get('/registration');
  }

  public getUploadedFiles():Observable<UploadFile[]>{
    const patient = LocalStorageUtil.getPatientInfo();
    return this.http.get<any>(`${COMMUNICARE_API_MESSAGE}/patient-files/${patient.id}`);
  }

  public uploadFile(file: FormData, patientInfo?: {id: number}): Observable<any> {
    const uploadCodeType = 'patient-attachment';

    const randomNumber = Math.floor(100000 + Math.random() * 900000);

    const patient = patientInfo || LocalStorageUtil.getPatientInfo();

    return this.http.post<any>(`${COMMUNICARE_API_MESSAGE}/upload/${uploadCodeType}/${patient.id}/${randomNumber}`, file);
  }

  public saveScheduleObservations(preScheduledId: number, observation: string): Observable<any> {
    return this.apiService.post('/schedules/save-observation', {preScheduledId, observation});
  }
}
