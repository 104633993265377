<div class="snackbar-template">
  <p class="snackbar-template-info">
    Você não possui convênio cadastrado.
    <strong (click)="snackBarRef.closeWithAction()" class="link">
      Clique aqui
    </strong>
    para adicionar.
  </p>

  <span
    (click)="snackBarRef.dismissWithAction()"
    class="snackbar-template-close-button"
  >
    x
  </span>
</div>
