import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AmorSaudeService } from 'src/app/services/amor-saude.service';
import { LoginService } from 'src/app/services/login.service';
import { VoucherService } from 'src/app/services/voucher.service';
import { ModalPaymentComponent } from 'src/app/shared/components/modal-payment/modal-payment.component';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { HitoricoDetalhadoComponent } from '../hitorico-detalhado/hitorico-detalhado.component';
import { IPatientResponse } from '../perfil/perfil.interface';
import { ICampaign, IHistoryCampaign, IVoucherHistory } from './campanhas.interfaces';
import { DetalhesCampanhaComponent } from './detalhes-campanha/detalhes-campanha.component';

@Component({
  selector: 'app-campanhas',
  templateUrl: './campanhas.component.html',
  styleUrls: ['./campanhas.component.scss'],
})
export class CampanhasComponent implements OnInit {

  displayedColumns: string[] = [
    'code',
    'dateOfPurchase',
    'expirationDate',
    'quantity',
    'remaining',
    'actions'
  ];

  groupCampaign: ICampaign[] = []
  
  loading: boolean = false;
  loadingTable: boolean = false;
  isAmorSaude: boolean = false;
  checkingPayment: boolean = false;
  patientInfo: IPatientResponse;

  className: string = '';

  constructor(
    private loginService: LoginService,
    private voucherService: VoucherService,
    private amorSaudeService: AmorSaudeService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) { 
    this.patientInfo = LocalStorageUtil.getPatientInfo();
    this.isAmorSaude = this.loginService.isAmorSaude();
    this.className = this.isAmorSaude? 'amorsaude': '';
  }

  ngOnInit(): void {
    this.load();
  }

  load(){
    this.loading = true
    this.voucherService.getCampaignVouchersByAccount().subscribe(
      res=>{
        this.groupCampaign = res;
        this.loading = false;
      },  
      err=>{
        this.snack.open('Erro ao carregar campanhas', 'ok', { duration: 3000, panelClass: this.className})
        this.loading = false;
      }
    )
  }

  public onPayment(campaign: ICampaign, idx: number){

    if( campaign.inactive){
      this.snack.open(`Voucher inativo, novas compras não liberadas`, 'ok', { duration: 5000 });
      return;
    }
    
    if( campaign.CPFSalesLimit && (campaign.CPFSalesLimit <= campaign.vouchers.length) ){
      this.snack.open(`Limite permitido de compra da campanha '${campaign.title}' atingida`, 'ok', { duration: 5000 });
      return;
    }
    
    this.checkingPayment = true;
    this.voucherService.checkIfPatientCanByCampaign(this.patientInfo.id, campaign.id).subscribe(
      res=>{
        this.checkingPayment = false;
        if(!!res){
          const dialogRef = this.dialog.open(ModalPaymentComponent,{
            disableClose: true,
            panelClass: 'paymentModal',
            data:{
              campaignId: campaign.id,
              amount: campaign.salesValue,
              linkedPayment: false
            }
          });

          dialogRef.afterClosed().subscribe(
            res =>{
              if(res?.amorSaudeId){
                this.checkingPayment = true;
                  this.amorSaudeService.checkIfWasPayed(res.amorSaudeId).subscribe(
                    res=>{
                      this.checkingPayment = false;
                      if(!!res)
                        this.loadPatientVouchersByCampaign(campaign.id, idx);
                    },
                    err=>{
                      this.snack.open('Erro ao verificar confirmação de pagamento','ok', {duration: 3000, panelClass: this.className});
                      this.checkingPayment = false;
                    }
                  )
              }else{
                if(res.payed)
                  this.load();
              }
            }
          );

        }else
          this.snack.open('Você não tem permissão para comprar esse tipo campanha', 'ok', { duration: 3000, panelClass: this.className });
      },
      err=>{
        this.snack.open('Erro ao verificar possibilidade de comprar a campanha', 'ok', { duration: 3000, panelClass: this.className})
        this.checkingPayment = false;
      }
    )
  }

  loadPatientVouchersByCampaign(campaignId: number, idx:number){
    this.loadingTable = true
    this.voucherService.getPatientVouchersByCampaign(campaignId).subscribe(
      res=>{
        this.groupCampaign[idx].vouchers = res;
        this.loadingTable = false;
      },
      err=>{
        this.snack.open('Erro ao carregar vouchers da campanha', 'ok', { duration: 3000, panelClass: this.className})
        this.loadingTable = false;
      }
    )
  }

  showInfo(voucherHistory: IVoucherHistory){
    const dialogRef = this.dialog.open(HitoricoDetalhadoComponent, {
      data: {
        voucherHistory
      },
    });

    dialogRef.afterClosed().subscribe(
      (result: any) => {},
      (error: any) => {
        this.snack.open('Erro ao fechar histórico, recarregue a página')
      });
  }

  showCampaignInfo(campaign: ICampaign){
    const dialogRef = this.dialog.open(DetalhesCampanhaComponent, {
      data: {
        campaign
      },
    });

    dialogRef.afterClosed().subscribe(
      (result: any) => {},
      (error: any) => {
        this.snack.open('Erro ao fechar procedimentos da campanha, recarregue a página')
      });
  }

  formatDate(date:string): string{
    return moment(date).format('DD/MM/YYYY');
  }

  agendar(voucher: any){
    this.voucherService.getVoucherQueryParams(voucher.id).subscribe(
      res=>{
        const queryParams ={
          modalidade: res.modalidadeId,
          procedure: res.accountProceduresId,
          voucher: voucher.id,
        }
        this.router.navigate(['/agendamentos/adicionar'], {queryParams: queryParams})
      },
      err=>{
        this.snack.open('Erro carregar dados do voucher para agendar', 'ok', { duration: 3000 })
      }
    );

  }

  copyCodeToClipboard(code: string){
    return new Promise(res =>{
      const textarea = document.createElement('textarea');
      textarea.style.position = 'fixed';
      textarea.style.left = '0';
      textarea.style.top = '0';
      textarea.style.opacity = '0';
      textarea.value = code;
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.snack.open(
        'Código copiado', 
        'ok', 
        { 
          duration: 2000 
        }
      );
      res(true);
    });
  }

}
