import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-generate-payment-link',
  templateUrl: './generate-payment-link.component.html',
  styleUrls: ['./generate-payment-link.component.scss']
})
export class GeneratePaymentLinkComponent {
  @Input() uuid: string = '';
  @Input() scheduleId: number = null;

  constructor(
    private snack: MatSnackBar,
    private paymentService: PaymentService
  ) { }

  loadingShare = false

  generatePaymentLink() {

    this.loadingShare = true;

    const partialURL = window.location.origin + `/#/pagar-consulta`;

    if (this.uuid === '')
      this.paymentService.generatePaymentLink(this.scheduleId).subscribe({
        next: (res: { uuid: string }) => {

          const url = `${partialURL}/${res.uuid}`;

          this.onShareLink(url);
          this.uuid = res.uuid;
        },
        error: (err) => {
          this.snack.open('Erro ao gerar link de pagamento', 'ok', { duration: 4000 })
          this.loadingShare = false;
        }
      });
    else {
      const url = `${partialURL}/${this.uuid}`;
      this.onShareLink(url);
    }
  }

  async onShareLink(url: string) {

      await this.paymentService.copyCodeToClipboard(url).then(()=>{
        this.snack.open('O link para o pagamento foi copiado', 'ok', {
          duration: 4000
        });
        this.loadingShare = false;

      }).catch(
        ()=>{
          this.snack.open('Erro ao copiar link de pagamento', 'ok', {
            duration: 4000
          })
          this.loadingShare = false;
        }
      );
  }

}
