import { IMenu } from '../shared/components/navigation-bar/navigation-bar.interface';
import { IGender } from '../pages/cadastro/cadastro.interfaces';
import { environment } from '../../environments/environment';

export const menuOptions: IMenu[] = [
  {
    name: 'Agenda',
    visible: true,
    route: '/agendamentos',
    icon: {
      name: 'calendar-alt',
      type: 'fas',
    },
  },
  {
    name: 'Histórico',
    visible: true,
    route: '/historico',
    icon: {
      name: 'history',
      type: 'fas',
    },
  },
  {
    name: 'Meus arquivos',
    visible: true,
    route: '/meus-arquivos',
    icon: {
      name: 'paperclip',
      type: 'fas',
    },
  },
  {
    name: 'Campanhas',
    visible: true,
    route: '/campanhas',
    icon: {
      name: 'money-check-alt',
      type: 'fas',
    },
  },
  {
    name: 'Perfil',
    visible: false,
    route: '/perfil',
    icon: {
      name: 'user',
      type: 'far',
    },
  },
  {
    name: 'Novo agendamento',
    visible: false,
    route: '/agendamentos/adicionar',
    icon: {
      name: 'plus-circle',
      type: 'fas',
    },
  },
  {
    name: 'Novo agendamento',
    visible: false,
    route: '/perfil/:profile',
    icon: {
      name: 'plus-circle',
      type: 'fas',
    },
  },
];

export const PROFESSIONAL_GENDERS = new Map([
  ['Biólogo', { name: 'Bióloga' }],
  ['Dr.', { name: 'Dra.' }],
]);

export const MSG = {
  LOGIN: {
    LOGIN_EXPIRED: 'Faça o login novamente para continuar',
  },
  REGISTER_EMAIL_SENT_SUCCESSFULLY:
    'E-mail de ativação de cadastro reenviado com sucesso!',
  REGISTER_EMAIL_SENT_ERROR: 'Erro ao enviar e-mail de ativação de cadastro!',
  PROFILE: {
    INCORRECT_FIELDS: 'Por favor preencha todos os campos corretamente.',
    UPDATED_SUCESSS: 'Informações do paciente atualizadas com sucesso!',
    UPDATED_ERROR: 'Erro ao atualizar informações do paciente.',
    INCORRECT_HEALTHPLAN_FIELDS: 'Verifique os campos informados.',
    EMPTY_HEALTHPLAN_SELECT: 'Preencha o convênio para visualizar os planos.',
    SCHEDULER_UPDATED: 'Informações do agendador atualizadas com sucesso!',
    SCHEDULER_UPDATED_ERROR: 'Erro ao atualizar informações do agendador',
  },
  ADD_SCHEDULE: {
    CANT_ADD_SCHEDULE:
      'Contate o administrador da conta para adicionar um convênio!',
  },
};

export const GENDERS: IGender[] = [
  { value: 'M', label: 'Masculino' },
  { value: 'F', label: 'Feminino' },
  { value: 'I', label: 'Indeterminado' },
];

export const COMMUNICARE_API_MESSAGE = environment.api_message;
