import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initialLetters',
})
export class InitialLettersPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || value.length < 2) return value;

    const words = value
      .split(' ')
      .filter((w) => !/(de|do|da|dos|das)/i.test(w));

    if (!words.length || words.length === 1) {
      return (value.charAt(0) + value.charAt(1)).toUpperCase();
    }
    return (
      words[0].charAt(0) + words[words.length - 1].charAt(0)
    ).toUpperCase();
  }
}
