import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IAgendamentoHorarioResponse } from 'src/app/pages/agendamentos/agendamentos.interfaces';

@Component({
  selector: 'app-card-horario',
  templateUrl: './card-horario.component.html',
  styleUrls: ['./card-horario.component.scss'],
})
export class CardHorarioComponent {
  @Input() horario: IAgendamentoHorarioResponse;
  @Input() isAmorSaude: boolean = false;
  @Output() selectHorario: EventEmitter<any> = new EventEmitter();
  partialBio = true;
  showBioTexts: string[] = ['Ver mais', 'Ver menos'];
  expandBioTitle: string= this.showBioTexts[0];

  public onSelectHorario(horario, hora) {
    this.selectHorario.emit({ horario, hora });
  }

  public photoError(item: any) {
    item.photo = null;
  }

  public bioView(){
    this.partialBio = !this.partialBio;
    this.expandBioTitle = this.partialBio? this.showBioTexts[0] : this.showBioTexts[1]; 
  }
}
