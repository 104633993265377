import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concat',
})
export class ConcatPipe implements PipeTransform {
  transform(
    str: any = '',
    toConcatStr: any = '',
    append?: any,
    blockEmpty?: any,
  ): string {
    if (!str && blockEmpty) return '';
    return (append ? [toConcatStr, str] : [str, toConcatStr]).join('');
  }
}
