<div class="checkPayment" *ngIf="checkingPayment">
  <mat-spinner [diameter]="50" color="secondary">
  </mat-spinner>
</div>
<main-nav>
    <app-loading
      *ngIf="loading"
      class="p-margin-top-1 p-display-block"
    ></app-loading>
   <div class="title" *ngIf="!loading">
      <mat-card-title class="campanhas">Compra de voucher</mat-card-title>
   </div>

   <mat-card-content *ngIf="!loading">
      <div>
         <div [ngClass]="isAmorSaude ? 'noResult noResult-customColor' : 'noResult noResult-defaultColor'"
            *ngIf="groupCampaign.length === 0"
         >
            <fa-icon icon="database"></fa-icon>
            <h1>Sem campanhas ativas</h1>
         </div>
         <mat-tab-group
            *ngIf="groupCampaign.length > 0"
         >
            <div
               *ngFor="
                 let g of groupCampaign;
                 let i = index
               "
            >
               <mat-tab label="{{g.title}}">
                  <div class="card-description">
                     <span class="title">
                      <b>Regras de utilização</b>
                      <button
                        mat-mini-fab
                        type="button"
                        matTooltip="Procedimentos da campanha"
                        matTooltipPosition="above"
                        class="btn-icon info"
                        (click)="showCampaignInfo(g)"
                      >
                      <fa-icon [icon]="['fas', 'info']" size="md" class="icon"></fa-icon>
                      </button>
                    </span>
                    <span
                      class="ck-content"
                      [innerHTML]="g.description | safeHtml"
                    >
                    </span>
                  </div>
                  <div class="row-content">
                    <span class="salesValue">R$ {{g.salesValue.replace('.', ',')}}</span>
                     <button 
                     mat-button 
                     [ngClass]="g.inactive ? 'btnPayment disabled' : 'btn-secondary btnPayment' "
                     (click)="onPayment(g, i)"
                     >
                       Comprar voucher
                     </button>
                  </div>

                  <app-loading
                    *ngIf="loadingTable"
                    class="p-margin-top-1 p-display-block loadTable"
                  >
                  </app-loading>

                  <div
                    *ngIf="!loadingTable" 
                     class="history"
                  >
                    <div *ngIf="g.vouchers.length === 0" [ngClass]="isAmorSaude ? 'noResult noResult-customColor' : 'noResult noResult-defaultColor'">
                      <fa-icon icon="database"></fa-icon>
                      <h1>Nenhum voucher adquirido</h1>
                    </div>
                     <table mat-table *ngIf="g.vouchers.length > 0" [dataSource]="g.vouchers" class="box-table">
                       <ng-container matColumnDef="code">
                         <th mat-header-cell *matHeaderCellDef>
                           <b>Código</b> 
                         </th>
                         <tr>
                           <td mat-cell *matCellDef="let element">
                             {{ element.code }}
                             <button
                             mat-button
                             [ngClass]="isAmorSaude ? 'copyCodeBtn copyCodeBtn-customColor' : 'copyCodeBtn copyCodeBtn-defaultColor'"
                             matTooltip="Copiar código"
                             matTooltipPosition="right"
                             (click)="copyCodeToClipboard(element.code)"
                             >
                             <fa-icon icon="copy"></fa-icon>
                           </button>
                           </td>
                         </tr>
                       </ng-container>
     
                       <ng-container matColumnDef="dateOfPurchase">
                         <th mat-header-cell *matHeaderCellDef>
                           <b>Data da compra</b>
                         </th>
                         <tr>
                           <td mat-cell *matCellDef="let element">
                             {{ formatDate(element.salesDate) }}
                           </td>
                         </tr>
                       </ng-container>
     
                       <ng-container matColumnDef="expirationDate">
                         <th mat-header-cell *matHeaderCellDef class="text-center">
                           <b>Data de expiração</b>
                         </th>
                         <tr>
                           <td mat-cell *matCellDef="let element">
                             {{ 
                                element.expirationDate?
                                  formatDate(element.expirationDate)
                                  : 'sem validade'
                             }}
                           </td>
                         </tr>
                       </ng-container>
     
                       <ng-container matColumnDef="quantity">
                         <th mat-header-cell *matHeaderCellDef>
                           <b>Quantidade adquirida</b>
                         </th>
                         <tr>
                           <td mat-cell *matCellDef="let element">
                             {{ element.quantity}}
                           </td>
                         </tr>
                       </ng-container>

                       <ng-container matColumnDef="remaining">
                         <th mat-header-cell *matHeaderCellDef>
                           <b>Quantidade restante</b>
                         </th>
                         <tr>
                           <td mat-cell *matCellDef="let element">
                             {{ element.remainingQuantity}}
                           </td>
                         </tr>
                       </ng-container>
     
                       <ng-container matColumnDef="actions">
                         <th mat-header-cell *matHeaderCellDef>
                           <b>Acões</b>
                         </th>
                         <tr>
                           <td mat-cell *matCellDef="let element" class="acoes-tab">
                             <span class="actionBtns">
                               <button
                               mat-mini-fab
                               type="button"
                               [ngClass]="isAmorSaude ? 'agendar agendar-customColor' : 'agendar agendar-defaultColor'"
                               matTooltipPosition="above"
                               matTooltip="Agendar"
                               (click)="agendar(element)"
                               >
                                 <fa-icon [icon]="['fas', 'calendar-alt']" class="icon"></fa-icon>
                               </button>
                              <button
                                mat-mini-fab
                                type="button"
                                matTooltip="Histórico de agendamentos"
                                matTooltipPosition="above"
                                class="btn-icon"
                                (click)="showInfo(element.voucherHistory)"
                              >
                              <fa-icon [icon]="['fas', 'info']" size="md" class="icon"></fa-icon>
                              </button>

                             </span>
                           </td>
                         </tr>
                       </ng-container>
                       <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                       <tr
                         mat-row
                         *matRowDef="let row; columns: displayedColumns"
                       ></tr>
                     </table>
                   </div>
               </mat-tab>
               </div>
         </mat-tab-group>
      </div>
   </mat-card-content>
   
</main-nav>