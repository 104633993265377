import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PacienteService } from 'src/app/services/paciente.service';
import { AgendamentosService } from '../../services/agendamentos.service';
import {
  IAgendamentoDeletedRequest,
  IAgendamentoResponse,
} from './agendamentos.interfaces';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { LoginService } from 'src/app/services/login.service';
import { Socket } from 'ngx-socket-io';
import { AmorSaudeService } from 'src/app/services/amor-saude.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-agenda',
  templateUrl: './agendamentos.component.html',
  styleUrls: ['./agendamentos.component.scss'],
})
export class AgendaComponent implements OnInit, OnDestroy {
  public content: IAgendamentoResponse[] = [];
  public isGettingApiResult: boolean = true;
  public title = 'Agendamentos';
  public isNotFound: boolean = false;
  private unsubscribe$: Subject<void> = new Subject();
  private lastDay: number;
  private resultsLimit: number = 20;
  private feedback: string;
  public isDeleting: boolean = false;
  public isAmorSaude: boolean = false;
  public isSchedulersView: boolean = false;
  public refreshing: boolean = false;
  public checkingPayment: boolean = false;
  private className: string = '';

  constructor(
    private snack: MatSnackBar,
    private agendamentosService: AgendamentosService,
    private patientService: PacienteService,
    private loginService: LoginService,
    private amorSaudeService: AmorSaudeService,
    private socket: Socket,
    private dialog: MatDialog,
  ) {
    this.isAmorSaude = this.loginService.isAmorSaude();
    this.isSchedulersView = this.loginService.isSchedulersView();
    this.className = this.isAmorSaude? 'amorsaude': '';

  }

  public ngOnInit() {
    window.scrollTo(0, 0);
    
    if(!this.isSchedulersView){
      this.loadCurrentPatient();
      this.getSchedules();
    }

  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getSchedules(refresh = false) {
    let lastDay = this.lastDay
    this.isGettingApiResult = true;

    if(refresh){
      lastDay = null;
      this.content = [];
      this.refreshing = true;
    }

    this.agendamentosService
      .getAgendamentos('false', this.resultsLimit, lastDay)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: IAgendamentoResponse[]) =>{
          this.setSchedules(res)
        },
        err =>{ 
          if(err?.status == 404){
            this.snack.open('Paciente não encontrado', 'ok', { duration: 3000 })
            this.loginService.logout();
          }else
            this.snack.open('Erro ao carregar agendamentos', 'ok', {duration: 3000, panelClass: this.className})
        }
      );
  }

  public setSchedules(schedules) {
    this.isGettingApiResult = false;
    this.isNotFound = !schedules.length;

    if (this.isNotFound) return;

    this.content = [...this.content, ...schedules];
    this.updatePagination(schedules);
    this.refreshing = false;
  }

  public onScroll() {
    if (!this.isGettingApiResult) this.getSchedules();
  }

  private updatePagination(agendamentos: IAgendamentoResponse[]) {
    this.lastDay = _.last(agendamentos).fullDate;
  }

  public onDeleteHandler(agenda: IAgendamentoDeletedRequest) {
    this.isDeleting = true;
    this.agendamentosService
      .deleteAgenda(agenda.id, agenda.reason)
      .then((response: any) => {
        if (response.status === 200) {
          this.content.find((item: any) => item.agendaId === agenda.id).status =
            'CANCELADO';
        }

        this.isNotFound = !this.content.length;
        this.feedback = 'Agendamento cancelado com sucesso!';
        this.socket.emit('schedule-update', {
          professionalId: agenda.professionalId,
        })
      })
      .catch((err) => {
        this.feedback =
          'Não foi possível cancelar o agendamento. Tente novamente mais tarde';
      })
      .finally(() => {
        this.isDeleting = false;
        this.snack.open(this.feedback, '', { duration: 3000, panelClass: this.className });
      });
  }

  private loadCurrentPatient() {
    this.patientService
      .current()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (_patient) => {
          LocalStorageUtil.setPatientInfo(_patient);
        },
        error=>{
          if(error?.status == 404){
            this.snack.open('Paciente não encontrado', 'ok', { duration: 3000 })
            this.loginService.logout();
          }
        }
      );
  }

  public async onTimesOver(item:any) {

    if(item.amorSaudeId)
      this.amorSaudeService.checkIfWasPayed(item.amorSaudeId).subscribe(
        res=>{
          if(!!res){
            // localStorage.removeItem('preScheduledId');
            this.getSchedules(true);
          }else{
            const itemIndex = this.content.indexOf(item);
            if(itemIndex !== -1 )
              this.content.splice(itemIndex, 1)
          }

        },
        err=>{
          this.snack.open('Erro ao verificar confirmação de pagamento','ok', {duration: 3000, panelClass: this.className});
        }
      )
    else{
      const itemIndex = this.content.indexOf(item);
      if(itemIndex !== -1 )
        this.content.splice(itemIndex, 1)
    }
  }
}
