import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company.service';
import { AccountInfo, LocalStorageUtil } from 'src/app/util/local-storage-util';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {
  @Input() isAmorSaude: boolean;
  @Input() professionalId: any = null;
  @Input() completeBioOnly = false;

  professional = null;
  partialBio = true;
  showBioTexts: string[] = ['Mostrar descrição completa', 'Ocultar descrição parcialmente'];
  expandBioTitle: string= this.showBioTexts[0];
  charactersToShow = 100;
  icons=['chevron-up','chevron-down'];
  iconDescription=this.icons[1];

  account: AccountInfo;

  constructor(
    private companyService: CompanyService,
    private router: Router
  ) {
    this.account = LocalStorageUtil.getAccountInfo();
   }

  async ngOnInit() {
    const data = await this.companyService.getProfessionalInfo(this.professionalId, this.account.accountId).toPromise().then(
      res=> res
    ).catch(
      error=>{
        this.router.navigate(['/404'])
      }
    );
    this.professional = data;
  }

  public bioView(){
    this.partialBio = !this.partialBio;
    if(this.partialBio){
      this.expandBioTitle = this.showBioTexts[0]; 
      this.iconDescription = this.icons[1];
    }else{
      this.expandBioTitle = this.showBioTexts[1]; 
      this.iconDescription = this.icons[0];
    }
  }

  public photoError(item: any) {
    item.photo = null;
  }

}
